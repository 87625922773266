// ====================================================
// Autor: Davain Pablo Edwards
// 
// ====================================================

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Gender } from '../models/gender.model';
import { Country } from '../models/country.model';
import { VatId } from '../models/vat-id.model';
import { BranchNumber } from '../models/branch-number.model';
import { ActiveObligo } from '../models/active-obligo.model';
import { AuthService } from './auth.service';
import { Rechtsform } from '../models/rechtsform';

@Injectable()
export class ComboBoxService {
  private readonly _baseUrl1: string;
  private readonly _baseUrl2: string;
  private readonly _baseUrl3: string;
  private readonly _baseUrl4: string;
  private readonly _baseUrl5: string;
  private readonly _baseUrl6: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl1 = '/api/ComboBox/gender';
    this._baseUrl2 = '/api/ComboBox/country';
    this._baseUrl3 = '/api/ComboBox/vatid';
    this._baseUrl4 = '/api/ComboBox/branchnumber';
    this._baseUrl5 = '/api/ComboBox/active/obligonumber';
    this._baseUrl6 = '/api/ComboBox/rechtsform';
  }

  getRechtsform() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<Rechtsform[]>(this._baseUrl6, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getGender() {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.get<Gender[]>(this._baseUrl1, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getCountry() {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.get<Country[]>(this._baseUrl2, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getVatId() {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<VatId[]>(this._baseUrl3, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getBranchNumber() {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.get<BranchNumber[]>(this._baseUrl4, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getActiveObligoNumberByUserName(userName) {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.get<ActiveObligo[]>(this._baseUrl5 + '?' + 'userName=' + userName, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }
}
