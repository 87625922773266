// ====================================================
// Davain Pablo Edwards
// 
// ====================================================

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Observable, Subscription, of, fromEvent, merge } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
require('chart.js');

@Component({
    selector: 'statistics-demo',
    templateUrl: './statistics-demo.component.html',
    styleUrls: ['./statistics-demo.component.css']
})
export class StatisticsDemoComponent implements OnInit, OnDestroy {

    chartData: Array<any> = [
        { data: [65, 59, 80, 81, 56, 55], label: 'Sonstiges' },
        { data: [28, 48, 40, 19, 86, 27], label: 'Tagesabrechnungen' },
        { data: [18, 48, 77, 9, 100, 27], label: 'Mahnungen' },
        { data: [1, 2, 3, 4, 5, 6], label: 'Monatsabrechnungen' },
        { data: [23, 56, 86, 12, 443, 12], label: 'Einreichungen' } 
    ];
    chartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June'];
    chartOptions: any = {
        responsive: true,
        title: {
            display: false,
            fontSize: 16,
            text: 'Important Stuff'
        }
    };
    chartColors: Array<any> = [
        { // grey
            backgroundColor: 'rgba(148,159,177,0.2)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // dark grey
            backgroundColor: 'rgba(77,83,96,0.2)',
            borderColor: 'rgba(77,83,96,1)',
            pointBackgroundColor: 'rgba(77,83,96,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(77,83,96,1)'
        },
        { // something else
            backgroundColor: 'rgba(128,128,128,0.2)',
            borderColor: 'rgba(128,128,128,1)',
            pointBackgroundColor: 'rgba(128,128,128,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(128,128,128,0.8)'
        }
    ];
    chartLegend = true;
    chartType = 'line';

  timerReference: any;
  windowWidth$: Observable<number>;
  windowWidthSub: Subscription;



  constructor(private alertService: AlertService, private authService: AuthService, private router: Router) {
    }


  ngOnInit() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      this.timerReference = setInterval(() => this.randomize(), 5000);

      const initialWidth$ = of(window.innerWidth);
      const resizedWidth$ = fromEvent(window, 'resize').pipe(map((event: any) => event.target.innerWidth as number));
      this.windowWidth$ = merge(initialWidth$, resizedWidth$).pipe(distinctUntilChanged());

      this.windowWidthSub = this.windowWidth$.subscribe(width => this.chartLegend = width < 600 ? false : true);

    } else {
      this.authService.logout();
    }
    }

    ngOnDestroy() {
      clearInterval(this.timerReference);
      this.windowWidthSub.unsubscribe();
    }



    randomize(): void {
        const _chartData: Array<any> = new Array(this.chartData.length);
        for (let i = 0; i < this.chartData.length; i++) {
            _chartData[i] = { data: new Array(this.chartData[i].data.length), label: this.chartData[i].label };

            for (let j = 0; j < this.chartData[i].data.length; j++) {
                _chartData[i].data[j] = Math.floor((Math.random() * 100) + 1);
            }
        }

        this.chartData = _chartData;
    }


    changeChartType(type: string) {
        this.chartType = type;
    }

    showMessage(msg: string): void {
        this.alertService.showMessage("Demo", msg, MessageSeverity.info);
    }

    showDialog(msg: string): void {
        this.alertService.showDialog(msg, DialogType.prompt, (val) => this.configure(true, val), () => this.configure(false));
    }

    configure(response: boolean, value?: string) {

        if (response) {

            this.alertService.showStickyMessage("Simulating...", "", MessageSeverity.wait);

            setTimeout(() => {

                this.alertService.resetStickyMessage();
                this.alertService.showMessage("Demo", `Your settings was successfully configured to \"${value}\"`, MessageSeverity.success);
            }, 2000);
        }
        else {
            this.alertService.showMessage("Demo", "Operation vom Benutzer abgebrochen", MessageSeverity.default);
        }
    }



    // events
    chartClicked(e): void {
        console.log(e);
    }

    chartHovered(e): void {
        console.log(e);
    }
}
