

export type PermissionNames =
    "View Users" | "Manage Users" |
    "View Roles" | "Manage Roles" | "Assign Roles" |
    //"View Master Data" |
    "View Obligo Info" |
    "View Risk Info" |
    "View Data"|
    "View Recommendation Management" |
    "View Smart Sign-Up" |
    "View Upload" |
    "View eCrefo";

export type PermissionValues =
    "users.view" | "users.manage" |
    "roles.view" | "roles.manage" | "roles.assign" |

  //"masterdata.view" |

    "obligoinfo.view" |
  "riskinfo.view" |
    "data.view" |
    "recommendationmanagement.view" |
    "smartsignup.view" |
    "upload.view" |
    "ecrefo.view";

export class Permission {

    public static readonly viewUsersPermission: PermissionValues = "users.view";
    public static readonly manageUsersPermission: PermissionValues = "users.manage";
    public static readonly viewRolesPermission: PermissionValues = "roles.view";
    public static readonly manageRolesPermission: PermissionValues = "roles.manage";
    public static readonly assignRolesPermission: PermissionValues = "roles.assign";

  //public static readonly viewMasterDataPermission: PermissionValues = "masterdata.view";  //

    public static readonly viewObligoInfoPermission: PermissionValues = "obligoinfo.view";
    public static readonly viewRiskInfoPermission: PermissionValues = "riskinfo.view";
    public static readonly viewDataPermission: PermissionValues = "data.view";
    public static readonly viewRecommendationManagementPermission: PermissionValues = "recommendationmanagement.view";
    public static readonly viewSmartSignUpPermission: PermissionValues = "smartsignup.view";
    public static readonly viewUploadPermission: PermissionValues = "upload.view";
    public static readonly viewECrefoPermission: PermissionValues = "ecrefo.view";


    constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }

    public name: PermissionNames;
    public value: PermissionValues;
    public groupName: string;
    public description: string;
}
