import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contact } from '../models/contact.model';
import { Email } from '../models/email.model';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Imprint } from '../models/imprint';
import { Dsgvo } from '../models/dsgvo';
import { Userdsgvo } from '../models/userdsgvo';
import { CheckAdmin } from '../models/check-admin.model';
import { UserObligo } from '../models/user-obligo.model';
import { OpenLimitRequest } from '../models/open-limit-request.model';
import { FactorOpenLimitRequest } from '../models/factor-open-limit-request.model';
import { OpenLimitRequestCsv } from '../models/open-limit-request-csv.model';

@Injectable()
export class ContactService {

  private readonly _baseUrl: string;
  private readonly _baseUrl2: string;
  private readonly _baseUrl3: string;
  private readonly _baseUrl4: string;
  private readonly _baseUrl5: string;
  private readonly _baseUrl6: string;
  /*checkadmin*/
  private readonly _baseUrl7: string;
  private readonly _baseUrl8: string;

  //user
  private readonly _baseUrlUser: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl = 'api/factor/contact/data';
    this._baseUrl2 = 'api/factor/send/contact/mail';
    this._baseUrl3 = 'api/factor/imprint/data';
    this._baseUrl4 = 'api/factor/dsgvo';
    this._baseUrl5 = 'api/factor/dsgvo/save'; 
    this._baseUrl6 = 'api/factor/userdsgvo';
    this._baseUrl7 = 'api/factor/admincheck';
    this._baseUrl8 = 'api/factor/open/limit/requests';

  // factor user
    this._baseUrlUser = 'api/factor/userFactor';
  }

  getUserFactor() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<UserObligo[]>(this._baseUrlUser, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getLimitRequests(nmndid, nfkdkdnr, nfkdid, wunerl) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<OpenLimitRequestCsv[]>(this._baseUrl8 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'nfkdid=' + nfkdid + '&' + 'wunerl=' + wunerl, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getCheckAdmin(): Observable<CheckAdmin[]> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<CheckAdmin[]>(this._baseUrl7, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getUserDsgvo(id, nmndid): Observable<Userdsgvo[]> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<Userdsgvo[]>(this._baseUrl6 + '?' + 'id=' + id + '&' + 'nmndid=' + nmndid, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getDsgvo(nmndid): Observable<Dsgvo[]> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      
      return this.httpClient.get<Dsgvo[]>(this._baseUrl4 + '?' + 'nmndid=' + nmndid, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getImprintData(nmndid): Observable<Imprint[]> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<Imprint[]>(this._baseUrl3 + '?' + 'nmndid=' + nmndid, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getFactorContactData(nmndid, nfkdkdnr) : Observable<Contact[]>{
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
 
       return  this.httpClient.get<Contact[]>(this._baseUrl + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {headers: httpHeaders});  
    } catch (error) {
      console.log(error);
    }
  }

  createFactorContactEmail(email: Email) {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.post(this._baseUrl2, email, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }
}
