import { Component } from '@angular/core';

@Component({
  selector: 'documents',
  templateUrl: './documents.component.html'
  //styleUrls: ['./documents.component.css'],
  //animations: [fadeInOut]
})
export class DocumentsComponent {
}
