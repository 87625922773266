import { Component, OnInit, Input, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { InvoiceListService } from '../../services/invoice-list.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Permission } from '../../models/permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Filter } from '../../models/filter.model';

// Datepicker date converter
import * as moment from 'moment';
moment.locale('de');

import * as _ from 'underscore';

// jQuery
import "jquery";
declare let $: JQueryStatic;
declare let jQuery: JQueryStatic;

//
import { Angular2Csv } from 'angular2-csv';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DebtorAccountService } from '../../services/debtor-account.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');
import { PageEvent } from '../../../../node_modules/@angular/material/typings/esm5/paginator/index';
import { map } from '../../../../node_modules/rxjs/src/operator/map';
import { HttpClient, HttpParams } from '../../../../node_modules/@angular/common/http';
import { CSVService } from '../../services/csv.service';
import { MatTableDataSource } from '../../../../node_modules/@angular/material/table';
import { DebtorInvoiceListCsv } from '../../models/debtor-invoice-list-csv.model';
import { MatSort } from '../../../../node_modules/@angular/material/sort';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-invoice-list-component',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css'],
  animations: [fadeInOut],
 
})

export class InvoiceListComponent implements AfterViewInit, OnInit  {  
  @ViewChild('someInput') someInput: ElementRef;

  printerIcon = require("../../assets/images/other/printer.png");
  excelIcon = require("../../assets/images/other/excel-csv.png");
  colorGreenIcon = require("../../assets/images/color/green.gif");
  colorGreyIcon = require("../../assets/images/color/grey.gif");
  colorLightblueIcon = require("../../assets/images/color/lightblue.gif");
  colorOrangeIcon = require("../../assets/images/color/orange.gif");
  colorPinkIcon = require("../../assets/images/color/pink.gif");

  debtorNdktid: number;
  debtorNdktkdnr: number;
  debtorNmndid: number;
  debtorNrekid: number;
  debtorCdktname1: string;
  debtorNdktdebitnr: number;

  private user: User = new User();
  debtorInvoiceListMainDataRows = [];
  debtorInvoiceListCsv = [];

  //Begriffserklärung
  debtorBUA = [];
  debtorPN = [];
  selectedValueBua:string = "";
  selectedStatusBegriffs: string = "S / Status gekauft";
  selectedPnBegriffs:string = "";

  //table && Pagination
  debtorInvoiceListsRows = [];
  pageSilce;
  debtorPaymentDataRows = [];
  debtorInvoiceMainDataRows = [];
  weekNum: number;
  fuenfProcent: number;
  filter: FormGroup;
  invoiceListParameter = ['nopoga','crekbelegnr','drekbelegdat','drekfaelligdat','csspkbez','nrekbelegsum','zahlungen','nrekskont01','nrekskont02','nbuabuchart','nrekmahnstufe','tage_faelligdat','tage_belegdat'];
  pageSizeUser;
  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  //Time
  public now: Date = new Date();
  date: any;

  // table sorting
  key: string = "drekbelegdat";
  reverse = true;

  //Datepicker
  fromDate = new FormControl(new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000), Validators.required);
  untilDate = new FormControl(new Date(this.now), Validators.required);
  maxDate = new Date(this.now);

  //CSV Download
  status: any[];

  // Radio button 
  selectedOption: string;
  selection: any = [
    'fuenfProcent',
    'weeks',
    'fromUntilDate',
    'onlyOpenInvoices'
  ];

  httpCode: any;
  debUser = [];
  allInvoiceList: number;
  pagAllInvList: number;

  /**
   * Load user session data
   */
  @Input()
  isGeneralEditor = false;

    //Load data before view
    ngAfterViewInit(): void {
      this.onClickLoadData(1);
    } 

  @Output() searchcriteria = new EventEmitter<any>();
  @Input() searchword:any;
  sucheCheck: boolean = false;

  searchThis() {

  if (this.searchword.length < 1 ) {
      this.sucheCheck = false;
      this.ngOnInit();
  }

  if (this.searchword.length !== 0 || this.searchword.length !== null) {
      this.sucheCheck = true;
      this.pageSilce = this.debtorInvoiceListsRows;
      this.searchcriteria.emit(this.searchword);
  } else {
      this.sucheCheck = false;
      this.ngOnInit();
    }
  }

  // table sorting
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  public onPageChange(event: PageEvent ) {

   if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
    if (this.sucheCheck) {
        this.searchThis();
    }

    this.pageSizeUser = sessionStorage.setItem('seiteDetailsInvoiceListMatPag', JSON.stringify(event.pageSize));
    this.pageSizeUser = JSON.parse(sessionStorage.getItem("seiteDetailsInvoiceListMatPag"));
    event.pageSize = this.pageSizeUser;
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.debtorInvoiceListsRows.length) { endIndex = this.debtorInvoiceListsRows.length; }
    this.pageSilce = this.debtorInvoiceListsRows.slice(startIndex, endIndex);
 } else {
      sessionStorage.removeItem('seiteDetailsInvoiceListMatPag');
      sessionStorage.clear();
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  /**
   * 
   * @param accountService
   * @param alertService
   * @param invoiceListService
   */
  constructor(private accountService: AccountService, private alertService: AlertService, private invoiceListService: InvoiceListService,
    private spinner: NgxSpinnerService, private authService: AuthService, private router: Router, private debtorService: DebtorAccountService, private csvService: CSVService, private meta: Meta) {
        this.meta.addTags([
          { name: 'robots', content: 'noindex,follow' }
        ]);

      this.weekNum = 1;
      this.fuenfProcent = 1;
      this.selectedOption = '';

      if (JSON.parse(sessionStorage.getItem("seiteDetailsInvoiceListMatPag")) > 0) {
          this.pageSizeUser = JSON.parse(sessionStorage.getItem("seiteDetailsInvoiceListMatPag"));
      } else {
        this.pageSizeUser = 10;
      }
    }

    ngOnInit() {

      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }

        this.debtorInvoiceListMainDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

        //Datepicker
        this.date = new Date();

        //Page rendering event if data is available 
        if (this.debtorInvoiceListMainDataRows.length > 0) {
          this.isDataAvailable = true;
        } else if (this.debtorInvoiceListMainDataRows.length === 0) {
            this.alertService.showMessage("Bitte wählenn Sie einen Debitor im Fenster Debitorenkonten aus!", "", MessageSeverity.warn);

            //Load spinner
            this.spinner.show();
        }

        this.filter = new FormGroup({
          fuenfProcent: new FormControl(''),
          weekNum: new FormControl(''),
          fromDate: new FormControl(''),
          untilDate: new FormControl('')
        });

      } else {
          this.authService.logout();
          this.router.navigateByUrl('/login');
      }
    }

  onClickLoadData(paginationPoistion) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        for (let i = 0; i < this.debtorInvoiceListMainDataRows.length; i++) {
          this.debtorNdktid = this.debtorInvoiceListMainDataRows[paginationPoistion - 1].ndktid;
          this.debtorNdktkdnr = this.debtorInvoiceListMainDataRows[paginationPoistion - 1].ndktkdnr;
          this.debtorNmndid = this.debtorInvoiceListMainDataRows[paginationPoistion - 1].nmndid;
          this.debtorCdktname1 = this.debtorInvoiceListMainDataRows[paginationPoistion - 1].cdktnamE1;
          this.debtorNdktdebitnr = this.debtorInvoiceListMainDataRows[paginationPoistion - 1].ndktdebitnr;
        }

        // Webservice calls
        this.invoiceListService.getDebtorInvoiceLists(this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorInvoiceListsRows = data;
                this.allInvoiceList = this.debtorInvoiceListsRows.length;
                this.pagAllInvList = this.allInvoiceList;
                this.pageSilce = this.debtorInvoiceListsRows.slice(0, this.pageSizeUser);
              }
            }, 500);
        });

        this.csvService.getInvoiceListCsv(this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
            setTimeout(() => {
              this.debtorInvoiceListCsv = data;
            }, 500);
          });

        this.invoiceListService.getDebtorInvoiceMainData(this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorInvoiceMainDataRows = data;
              }
            }, 500);
          });

        this.invoiceListService.getDebtorInvoiceBua(this.debtorNmndid, this.debtorNdktid).subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorBUA = data;
               
                for (var i = 0; i < this.debtorBUA.length; i++) {
                  if (i == 1) {                 
                    this.selectedValueBua =  this.debtorBUA[0].cbuaartbeZ1;
                  }
                }
              }
          }, 500);
          });

        this.invoiceListService.getDebtorInvoicePn(this.debtorNmndid, this.debtorNdktkdnr, this.debtorNdktdebitnr).subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorPN = data;

                for (var i = 0; i < this.debtorPN.length; i++) {
                  if (i == 1) {            
                      this.selectedPnBegriffs = this.debtorPN[i].csspkeybeZ1;
                  }
                  this.selectedPnBegriffs = this.debtorPN[0].csspkeybeZ1;
                }
              }
            }, 500);
        });
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

    //Spinner
    showSpinner() {
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
        }, 2000);
    }

    /**
     * Gets the nrekid from debtorInvoiceLists
     * in invoice-list.component.html
     * @param nrekid
     */
    sendNrekid(nrekid) {        
      this.debtorNrekid = nrekid;
      this.getNmndid(this.user);
    }

    /**
     * 
     * @param user
     */
  getNmndid(user: User) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.user = user;
        this.invoiceListService.getDebtorPaymentDatas(this.debtorNrekid, this.debtorNmndid)
          .subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;
              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorPaymentDataRows = data;
              }
            }, 200);
          });
      }
    } catch (error) {
      console.log(error);
    }
    }

    /**
     * Load user session data
     */
    private loadCurrentUserData() {
        this.alertService.startLoadingMessage();

        if (this.canViewAllRoles) {
            this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
        }
        else {
            this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
        }
    }

    /**
     * 
     * @param user
     * @param roles
     */
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.alertService.stopLoadingMessage();
        this.user = user;

        this.debtorService.getUserDeb().subscribe(data => {
            this.debUser = data;  
        });

        // Webservice calls
        this.invoiceListService.getDebtorInvoiceLists(this.user.nmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
            setTimeout(() => {
              this.debtorInvoiceListsRows = data;
              this.allInvoiceList = this.debtorInvoiceListsRows.length;
              this.pageSilce = this.debtorInvoiceListsRows.slice(0, this.pageSizeUser);
             }, 200);
          });

        this.csvService.getInvoiceListCsv(this.user.nmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
            setTimeout(() => {
            this.debtorInvoiceListCsv = data;
            }, 500);
          });

        //
        this.invoiceListService.getDebtorInvoiceMainData(this.user.nmndid, this.debtorNdktid, this.debtorNdktkdnr).subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorInvoiceMainDataRows = data;
              }
            }, 200);
        });

        this.invoiceListService.getDebtorInvoiceBua(this.user.nmndid, this.debtorNdktid)
          .subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorBUA = data;
              }
            }, 200);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  /**
   * Code for download CSV function
   *
   */
  downloadCSV() {
    this.status = ["approved", "rejected", "pending"];

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: false,
      useBom: true,
      removeNewLines: true,
      headers: ['GA', 'Rch.Nr.', 'Datum', 'Faell.Dat.', 'S', 'Betrag', 'Zahl./Guts.', 'Skto.1', 'Skto.Dat.1', 'Skto.2', 'Skto.Dat.2', 'BUA', 'MS', 'Lz.Z.Tg', 'Lz.Tg']
    };
    new Angular2Csv(this.debtorInvoiceListCsv, "Rechnungen - Warengutschriften - Deb. Nr. " + this.debtorNdktdebitnr, options);
  }

  radioChangeHandler(event) {
     this.selectedOption = event.target.value;
  }

  onSubmit({ value }: { value: Filter }) {

    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      this.weekNum = value.weekNum;
      this.fuenfProcent = value.fuenfProcent;

      if (this.selectedOption !== '') {
        switch (this.selectedOption) {
          case "fuenfProcent": {
            this.fuenfProcent = value.fuenfProcent;
            this.procentFilter();
            break;
          }
          case "weeks": {
            this.weekNum = value.weekNum;
            this.weekFilter();
            break;
          }
          case "fromUntilDate": {
            this.fromUntilDateFilter();
            break;
          }
          case "onlyOpenInvoices": {
            this.onlyOpenInvoicesFilter();
            break;
          }
          default: {
            break;
          }
        }
      } else {
        this.alertService.showMessage("Bitte wählen Sie eine Option aus.", "", MessageSeverity.warn);
      }
    } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
    }
  }

  clearFilter() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      this.invoiceListService.getDebtorInvoiceLists(this.user.nmndid, this.debtorNdktid, this.debtorNdktkdnr)
        .subscribe(data => {
          setTimeout(() => {
            this.debtorInvoiceListsRows = data;
            this.allInvoiceList = this.debtorInvoiceListsRows.length;
            this.pageSilce = this.debtorInvoiceListsRows.slice(0, this.pageSizeUser);
          }, 200);
        });
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  procentFilter() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      if (this.fuenfProcent === null || this.fuenfProcent <= 0 ) {
        this.alertService.showMessage("Tragen Sie einen Betrag ein!", "", MessageSeverity.error);
      } else {
        this.invoiceListService.getDebtorPlusMunusFuenfFilter(this.user.nmndid, this.debtorNdktid, this.fuenfProcent)
          .subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorInvoiceListsRows = data;
                this.allInvoiceList = this.debtorInvoiceListsRows.length;
                this.pageSilce = this.debtorInvoiceListsRows.slice(0, this.pageSizeUser);
              }
            }, 200);
          });         
        this.alertService.showMessage("Filter für Debitor " + this.debtorNdktid + " mit 5% von" + this.fuenfProcent + " Betrag.", "", MessageSeverity.default);      
      }
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  weekFilter() {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        if (this.weekNum === null || this.weekNum <= 0) {
          this.alertService.showMessage("Tragen Sie eine Wochenanzahl [1 - n] ein!", "", MessageSeverity.error);
        } else {         
          this.invoiceListService.getDebtorWeekFilter(this.user.nmndid, this.debtorNdktid, this.weekNum)
            .subscribe(data => {
              setTimeout(() => {
                this.httpCode = data;

                if (this.httpCode === null) {
                  this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                } else {
                  this.debtorInvoiceListsRows = data;
                  this.allInvoiceList = this.debtorInvoiceListsRows.length;
                  this.pageSilce = this.debtorInvoiceListsRows.slice(0, this.pageSizeUser);
                }
              }, 500);
            });            
          this.alertService.showMessage("Filter für Debitor " + this.debtorNdktid + " der letzten " + this.weekNum + " Woche(n).", "", MessageSeverity.default);
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    }
 
  fromUntilDateFilter() {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        if ( this.fromDate === null) {
          this.alertService.showMessage("Bitte 'VON' Datum auswählen!", "", MessageSeverity.warn);
          return;
        }

        if ( this.untilDate === null) {
          this.alertService.showMessage("Bitte 'BIS' Datum auswählen!", "", MessageSeverity.warn);
          return;
        }

        this.invoiceListService.getDebtorFromUntilDateFilter(this.user.nmndid,
          this.debtorNdktid,
          moment(this.fromDate.value).format("YYYY-MM-DD"),
          moment(this.untilDate.value).format("YYYY-MM-DD")).subscribe(
          data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorInvoiceListsRows = data;
                this.allInvoiceList = this.debtorInvoiceListsRows.length;
                this.pageSilce = this.debtorInvoiceListsRows.slice(0, this.pageSizeUser);
              }
            }, 200);
          });
        this.alertService.showMessage("Filter für Debitor " + this.debtorNdktid + " von " + moment(this.fromDate.value).format("YYYY-MM-DD") + " bis " + moment(this.untilDate.value).format("YYYY-MM-DD"), "", MessageSeverity.default);
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    }

  onlyOpenInvoicesFilter() {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.invoiceListService.getDebtorOnlyOpenInvoicesFilter(this.user.nmndid, this.debtorNdktid)
          .subscribe(data => {
            setTimeout(() => {
              this.httpCode = data;

              if (this.httpCode === null) {
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
              } else {
                this.debtorInvoiceListsRows = data;
                this.allInvoiceList = this.debtorInvoiceListsRows.length;
                this.pageSilce = this.debtorInvoiceListsRows.slice(0, this.pageSizeUser);             
              }
            }, 200);
          });

        this.alertService.showMessage("Filter für Debitor " + this.debtorNdktid + " nur der offenen Rechnungen", "", MessageSeverity.default);
       
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
  }

  unCheck() {
    $("#radio").prop("checked", false);
    $("#radio2").prop("checked", false);
    $("#radio3").prop("checked", false);
    $("#radio4").prop("checked", false);
  }

  //PDF print
  print() {
    window.print();
  }

  private handleError(err: any) {
    const technicalError = err.indexOf("500"); // error code from ecrefo webservice response if technical error!

    if (technicalError !== -1) {
      this.alertService.showMessage("technicalError", "", MessageSeverity.warn);
    }
  }
}
