import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fadeInOut } from '../../services/animations';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Utilities } from "../../services/utilities";
import { ContactService } from '../../services/contact.service';
import { PageEvent } from '../../../../node_modules/@angular/material/typings/esm5/paginator/index';
import * as moment from 'moment';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-check',
  templateUrl: './admin-check.component.html',
  styleUrls: ['./admin-check.component.css'],
  animations: [fadeInOut]
})

export class AdminCheckComponent implements OnInit {

  // load user data
  @Input()
  isGeneralEditor = false;

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  // Pagination
  p: number = 1;
  public pageSilce;

  //Session
  private user: User = new User();
  private allRoles: Role[] = [];

  chackAdmin = [];

  adminRolleFactor: boolean = false;


  constructor(private httpClient: HttpClient, private alertService: AlertService, private accountService: AccountService, private authService: AuthService,
    private router: Router, private contactServicce: ContactService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }


  ngOnInit() { 
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
    }
  }

  //Load user data
  private loadCurrentUserData() {

    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  /**
   * @param user
   * @param roles
   */
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {

    /*  console.log("onCurrentUserDataLoadSuccessful");*/
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;   
    this.adminRolleFactor = true;
    this.contactServicce.getCheckAdmin().subscribe(data => {
            this.chackAdmin = data;
            console.log(this.chackAdmin);
            this.allFileList = this.chackAdmin.length;
            this.pageSilce = this.chackAdmin.slice(0, 10);
          });    
  }

  //Pagination
  allFileList: number;
  public onPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.chackAdmin.length) { endIndex = this.chackAdmin.length; }
    this.pageSilce = this.chackAdmin.slice(startIndex, endIndex);
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, MessageSeverity.error, error);
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

}
