
import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { Utilities } from '../../services/utilities';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'navi-app',
  templateUrl: './navi.component.html',
  styleUrls: ['./navi.component.css'],
  animations: [fadeInOut]
})

export class NaviComponent implements OnInit {

  //ausgewählte debitors
  testDataRows = [];

  // load user data
  private user: User = new User();
  validColor = false;
  userrisk: boolean = true;

  @Input()
  isGeneralEditor = false;

  constructor(private router: Router, private alertService: AlertService, private authService: AuthService, private accountService: AccountService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  ngOnInit() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      // load user data
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
      }

      if (this.accountService.currentUser.roles[0] === 'user3') {
        this.userrisk = false;
      }

      if (this.accountService.currentUser.roles[1] === 'user3') {
        this.userrisk = false;
      }
    }
  }

  onNavigateToOpenItems() {
    this.testDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

    if (this.testDataRows.length > 0) {
      this.validColor = true;
      setTimeout(() => {
        this.router.navigateByUrl('/open-items');
      }, 500);
    } else if (this.testDataRows.length === 0) {
      this.alertService.showMessage("Bitte w&auml;hlen Sie einen Debitor aus!", "", MessageSeverity.warn);
    }
  }

  onNavigateToInvoiceList() {
    this.testDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

    if (this.testDataRows.length > 0) {
      setTimeout(() => {
        this.router.navigateByUrl('/invoice-list');
      }, 500);
    } else if (this.testDataRows.length === 0) {
      this.alertService.showMessage("Bitte w&auml;hlen Sie einen Debitor aus!", "", MessageSeverity.warn);
    }
  }

  onNavigateToRisk() {
    if (this.accountService.currentUser.roles[0] === 'user3') {
      this.userrisk = false;
    }

    if (this.accountService.currentUser.roles[1] === 'user3') {
      this.userrisk = false;
    }  

    if (this.userrisk) {
      this.testDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

      if (this.testDataRows.length > 0) {
        setTimeout(() => {
          this.router.navigateByUrl('/risk');
        }, 500);
      } else if (this.testDataRows.length === 0) {
        this.alertService.showMessage("Bitte w&auml;hlen Sie einen Debitor aus!", "", MessageSeverity.warn);
      }
    } else {
      this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
    }
  }

  onNavigateToRecommendation() {
      this.testDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

      if (this.testDataRows.length > 0) {
        setTimeout(() => {
          this.router.navigateByUrl('/recommendation');
        }, 500);
      } else if (this.testDataRows.length === 0) {
        this.alertService.showMessage("Bitte w&auml;hlen Sie einen Debitor aus!", "", MessageSeverity.warn);
      }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;

    if (this.user.roles[0] === 'user3') {
      this.userrisk = false;
    }

    if (this.user.roles[1] === 'user3') {
      this.userrisk = false;
    }
  }

  get canViewRiskInfo() {
    return this.accountService.userHasPermission(Permission.viewRiskInfoPermission);
  }

  get canViewRecommendationManagement() {
    return this.accountService.userHasPermission(Permission.viewRecommendationManagementPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }
}
