import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, Inject, ViewChild, ElementRef } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ObligoService } from '../../services/obligo.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';
import { Utilities } from '../../services/utilities';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComboBoxService } from '../../services/combobox-service';
import { FormGroup, FormControl } from '@angular/forms';
import { ActiveObligo } from '../../models/active-obligo.model';
import { Router } from '@angular/router';
import { values } from 'underscore';
import { ObligoInformation } from '../../models/obligo-information.model';
import { DebtorAccountService } from '../../services/debtor-account.service';
import { DebtorAccount } from '../../models/debtor-account.model';
import { forEach } from '../../../../node_modules/@angular/router/src/utils/collection';
import { forEachChild } from 'typescript';
import { DownloadService } from '../../services/download.service';
import { registerLocaleData, DOCUMENT } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Subscription } from 'rxjs';
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { Meta } from '@angular/platform-browser';
registerLocaleData(localeDe, 'de');

@Component({
    selector: 'app-obligo-component',
    templateUrl: './obligo.component.html',
    styleUrls: ['./obligo.component.css'],
    animations: [fadeInOut]
})
export class ObligoComponent implements OnInit {

    printerIcon = require("../../assets/images/other/printer.png");

    private user: User = new User();

    obligoInfoDataRows = [];
    obligoInformationRows = [];
    obligoOpenBalancesRows = [];
    obligoBillingInformationRows = [];
    obligoAccountBalancesRows = [];
    obligoNumberOfAccountsRows = [];
    obligoKontosaldenKtoDataRows = [];
    obligoContact = [];
    debitorAccountObligoRow = [];
    openLimitRequestRows = [];
    obligoUser = [];
    activeObligo: FormGroup;
    columns = []
    selected = [];
    selectedStatusBegriffs = "VJ = Vorjahr";
    konzernndktkdnr: number[] = [];

    //Attribute for page rendering event if data is available 
    isDataAvailable = false;

    //ComboBox list
    activeObligoDataRows = [];

    //Datepicker
    element;
    checkedList: any;
    cbchecked: boolean = false;
    showNavigation: any;
    aktObligo: number;
    showStorage: any;

    rollenUser: boolean = true;

    rollen = [];
    nfkdid;
    verrg: string;
    dl: string;
    sperr: string;

    //Load user data
    @Input()
    isGeneralEditor = false;
    firstNameCtrl: any;

    obligoIngormationWechsel: boolean = false;
    wechselWert;

    constructor( private obligoService: ObligoService, private accountService: AccountService, private authService: AuthService,
      private alertService: AlertService, private spinner: NgxSpinnerService, private comboBoxService: ComboBoxService,
      private router: Router, private meta: Meta) {
      this.meta.addTags([
        { name: 'robots', content: 'noindex,follow' }
      ]);
    }  

    ngOnInit() {
        try {
          if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
          /*  console.log("ngOnInit");*/

            // load user data
            if (!this.isGeneralEditor) {
              this.loadCurrentUserData();
            }
          } else {
            localStorage.removeItem("obl");
            localStorage.removeItem("fkdidFirst");
            localStorage.clear();
            this.authService.logout();
            this.router.navigateByUrl('/login');
          }

          this.activeObligo = new FormGroup({
            NFKDKDNR: new FormControl('')
          });
        } catch (error) {
          console.log(error);
        }
    }  

  onSelect({ value }: { value: ActiveObligo }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        if (value.NFKDKDNR !== null) {
          localStorage.setItem("obl", JSON.stringify(value.NFKDKDNR));
          this.obligoService.setNFKDKDNR(value.NFKDKDNR);
          this.user.nfkdkdnr = value.NFKDKDNR;
          this.cbchecked = true;

          //Webservice calls
          // obligo info data
          this.obligoService.getObligoInfoData(this.user.nmndid, value.NFKDKDNR, this.user.nfkdkonzern)
            .subscribe(data => {
              this.obligoInfoDataRows = data;
              for (var i = 0; i < this.obligoInfoDataRows.length; i++) {
                this.nfkdid = this.obligoInfoDataRows[i].nfkdid;
                this.obligoService.setNFKDID(this.nfkdid);         
              /*         console.log("NFKDID____",this.obligoService.getNFKDID());*/              

              // obligo information 
              this.obligoService.getObligoInformation(this.user.nmndid, value.NFKDKDNR)
                .subscribe(data => {
                  this.obligoInformationRows = data;
                  for (var i = 0; i < this.obligoInformationRows.length; i++) {
                    this.wechselWert = this.obligoInformationRows[i].nfoiwechsel;
                    if (this.wechselWert > 0) {
                      this.obligoIngormationWechsel = true;
                    }
                  }
                });

              // obligo open balances
              this.obligoService.getObligoOpenBalances(this.user.nmndid, value.NFKDKDNR)
                .subscribe(data => {
                  this.obligoOpenBalancesRows = data;
                });

              // obligo billing information
              this.obligoService.getObligoBillingInformation(this.user.nmndid, value.NFKDKDNR)
                .subscribe(data => {
                  this.obligoBillingInformationRows = data;
                  /* console.log("RG----",this.obligoBillingInformationRows);*/
                });

              // obligo account balances
              this.obligoService.getObligoAccountBalances(this.user.nmndid, value.NFKDKDNR)
                .subscribe(data => {
                  this.obligoAccountBalancesRows = data;
                });

              // obligo number of accounts
              this.obligoService.getObligoNumberOfAccounts(this.user.nmndid, value.NFKDKDNR)
                .subscribe(data => {
                  this.obligoNumberOfAccountsRows = data;
                });

              this.obligoService.getOpenLimitRequests(this.user.nmndid, value.NFKDKDNR, this.nfkdid)
                .subscribe(data => {
                  this.openLimitRequestRows = data;
                });              

              //console.log(value.NFKDKDNR);
              //console.log(this.nfkdid);

              this.obligoService.getUserObligo()
                .subscribe(data => {
                  this.obligoUser = data;

                  for (var i = 0; i < this.obligoUser.length; i++) {
                    this.obligoUser[i].nfkdkdnr = value.NFKDKDNR;
                    this.obligoUser[i].nfkdid = this.nfkdid;
                  }
                });
              }
            });
          this.alertService.showMessage("Aktives Obligo " + value.NFKDKDNR + " ausgew&auml;hlt.", "", MessageSeverity.warn);
        }
      } else {
        this.authService.logout();        
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewUsers) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  rolleUserAdminFactory: boolean = false;

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {        
        this.user = user;
        this.rollen = roles;        

        //user
        this.obligoService.getUserObligo().subscribe(data => {
          this.obligoUser = data;
        });   

        for (var i = 0; i < user.roles.length; i++) {
          if (user.roles[i] === 'user2' || user.roles[i] === 'user3' || user.roles[i] === 'user4') {
            this.rollenUser = false;
          }

          if (user.roles[i] == 'adminFactor') {
            this.rolleUserAdminFactory = true;
          }
        }

        if (this.obligoService.getNFKDKDNR() > 0) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        }

        //Webservice calls
        // obligo info data
        this.obligoService.getObligoInfoData(this.user.nmndid, this.user.nfkdkdnr, this.user.nfkdkonzern)
          .subscribe(data => {
            this.obligoInfoDataRows = data;

            for (var i = 0; i < this.obligoInfoDataRows.length; i++) {
              this.aktObligo = this.obligoInfoDataRows[i].nfkdkdnr;
              this.nfkdid = this.obligoInfoDataRows[i].nfkdid;
              this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();

              if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
                this.user.nfkdkdnr = this.aktObligo;
                /*   console.log(this.user.nfkdkdnr);*/
              }
              localStorage.setItem("obl", JSON.stringify(this.user.nfkdkdnr));
              this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));

              if (this.user.nfkdkdnr == this.obligoInfoDataRows[i].nfkdkdnr) {
                localStorage.setItem("fkdidFirst", JSON.stringify(this.nfkdid));
              }
              this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));

              // obligo information
              this.obligoService.getObligoInformation(this.user.nmndid, this.user.nfkdkdnr)
                .subscribe(data => {
                  this.obligoInformationRows = data;

                  for (var i = 0; i < this.obligoInformationRows.length; i++) {
                    this.wechselWert = this.obligoInformationRows[i].nfoiwechsel;
                    if (this.wechselWert > 0) {
                      this.obligoIngormationWechsel = true;
                    }
                  }
                });

              // obligo open balances - RG
              this.obligoService.getObligoOpenBalances(this.user.nmndid, this.user.nfkdkdnr)
                .subscribe(data => {
                  this.obligoOpenBalancesRows = data;
                });

              // obligo billing information
              this.obligoService.getObligoBillingInformation(this.user.nmndid, this.user.nfkdkdnr)
                .subscribe(data => {
                  this.obligoBillingInformationRows = data;
                });

              // obligo account balances
              this.obligoService.getObligoAccountBalances(this.user.nmndid, this.user.nfkdkdnr)
                .subscribe(data => {
                  this.obligoAccountBalancesRows = data;
                });

              //obligo accout balances KTO label
              this.obligoService.getKontosaldenKtoData(this.user.nmndid)
                .subscribe(data => {
                  this.obligoKontosaldenKtoDataRows = data;
                  for (var i = 0; i < this.obligoKontosaldenKtoDataRows.length; i++) {
                    this.verrg = this.obligoKontosaldenKtoDataRows[2].cktobez1;
                    this.dl = this.obligoKontosaldenKtoDataRows[0].cktobez1;
                    this.sperr = this.obligoKontosaldenKtoDataRows[1].cktobez1;
                  }
                });

              // obligo number of accounts
              this.obligoService.getObligoNumberOfAccounts(this.user.nmndid, this.user.nfkdkdnr)
                .subscribe(data => {
                  this.obligoNumberOfAccountsRows = data;
                });              

              // active obligo
              this.comboBoxService.getActiveObligoNumberByUserName(this.user.userName)
                .subscribe(data => {
                  this.activeObligoDataRows = data;

                  this.activeObligoDataRows.forEach(activeObligoDataRow => {
                    this.konzernndktkdnr.push(activeObligoDataRow['nfkdkdnr']);                    
                  });                  
                  //localStorage.setItem("konzernObligoNumbers", JSON.stringify(this.konzernndktkdnr));
                });

              //openLimitRequest
              this.obligoService.getOpenLimitRequests(this.user.nmndid, this.user.nfkdkdnr, this.user.nfkdid)
                .subscribe(data => {
                  this.openLimitRequestRows = data;
                });
            }
          });
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Ladefehler", `Benutzerdaten können nicht vom Server abgerufen werden.\r\nFehler: "${Utilities.getHttpResponseMessage(error)}"`,MessageSeverity.error, error);
    console.log("Ladefehler: Benutzerdaten können nicht vom Server abgerufen werden.\r\nFehler: " + error);
    this.user = new User();
  }  

  get canViewObligoInfo() {
    return this.accountService.userHasPermission(Permission.viewObligoInfoPermission);
  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }


  // Print page 
  print() {
    window.print();
  } 

}
