
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { OpenItemsService } from '../../services/open-items.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { Angular2Csv } from 'angular2-csv';
// Datepicker date converter
import * as moment from 'moment';
import { CSVService } from '../../services/csv.service';
//Pagination
import * as _ from 'underscore';
import { FormGroup} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
//Nachkommastellen
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');
import { PageEvent } from '../../../../node_modules/@angular/material/typings/esm5/paginator/index';
import { map } from '../../../../node_modules/rxjs/src/operator/map';
import { HttpClient, HttpParams } from '../../../../node_modules/@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '../../../../node_modules/@angular/common/common';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-open-items-component',
    templateUrl: './open-items.component.html',
    styleUrls: ['./open-items.component.css'],
    animations: [fadeInOut]
})

export class OpenItemsComponent implements AfterViewInit, OnInit {

    //Load data before view
  ngAfterViewInit(): void {
    this.onClickLoadData(1);
  }

  @ViewChild('someInput') someInput: ElementRef;
  
  printerIcon = require("../../assets/images/other/printer.png");
  excelIcon = require("../../assets/images/other/excel-csv.png");
  prevIcon = require("../../assets/images/icons/arrow_left_16.png");
  nextIcon = require("../../assets/images/icons/arrow_right_16.png");
  appLogoBottom = require("../../assets/images/logo/11_logo-crefofactoring-rgb-cyan.png");

  debtorNdktid: number;
  debtorNdktkdnr: number;
  debtorNmndid: number;
  debtorCdktname1: string;
  debtorNdktdebitnr: number;

  private user: User = new User();
  debtorOpenItemMainDataRows: any[];
  debtorOpenItemCsv = [];
  debtorOpenItemMainData2Rows: any[];

  //table && pagination
  debtorOpenItemsDataRows: any[];
  pageSilce;
  allOpenitem: number;
  pageSizeUser;
  testDebtorAccountsRows: any[];  

  //CSV Download
  status: any[];

// table sorting
  key: string ="doporechdat";
  reverse = true;
  pgnallOpenitem: number;

  //Time
  public now: Date = new Date();

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;
  selectedStatusBegriffs: string = "S / Status gekauft";
  paginationElement: FormGroup;

/**
  * 
  * @param accountService
  * @param alertService
  * @param openItemService
  * @param spinner
  */
  constructor(private accountService: AccountService, private alertService: AlertService,
    private openItemService: OpenItemsService, private spinner: NgxSpinnerService,
    private csvService: CSVService, private authService: AuthService, private router: Router, private meta: Meta) {
      if (JSON.parse(sessionStorage.getItem("seiteDetailsOPMatPag")) > 0) {
        this.pageSizeUser = JSON.parse(sessionStorage.getItem("seiteDetailsOPMatPag"));
      } else {
        this.pageSizeUser = 10;
    }
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }
  
  //Load user data
  @Input()
  isGeneralEditor = false;

  checkSort:boolean = false;;
  // table sorting
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
    this.checkSort = true;   
    this.pageSilce = this.debtorOpenItemsDataRows.slice(0, this.pageSizeUser);
  }

  ngOnInit() {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }
        
      this.debtorOpenItemMainDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

        //Page rendering event if data is available 
        if (this.debtorOpenItemMainDataRows.length !== 0) {
          this.isDataAvailable = true;
        } else if (this.debtorOpenItemMainDataRows.length === 0) {
          this.alertService.showMessage("Bitte w&auml;hlen Sie einen Debitor im Fenster Debitorenkonten aus!", "", MessageSeverity.warn);

          //Load spinner
          this.spinner.show();
        }
        
        } else {
          this.authService.logout();
          this.router.navigateByUrl('/login');
        }
  }

  onClickLoadData(paginationPoistion) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        for (let i = 0; i < this.debtorOpenItemMainDataRows.length; i++) {
          this.debtorNdktid = this.debtorOpenItemMainDataRows[paginationPoistion-1].ndktid;
          this.debtorNdktkdnr = this.debtorOpenItemMainDataRows[paginationPoistion-1].ndktkdnr;
          this.debtorNmndid = this.debtorOpenItemMainDataRows[paginationPoistion-1].nmndid;
          this.debtorCdktname1 = this.debtorOpenItemMainDataRows[paginationPoistion-1].cdktnamE1;
          this.debtorNdktdebitnr = this.debtorOpenItemMainDataRows[paginationPoistion-1].ndktdebitnr;
        }     

        // Webservice calls
        this.openItemService.getOpenPositionMainData(this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr)
          .subscribe(data => {
            setTimeout(() => {
              this.debtorOpenItemMainData2Rows = data;
            }, 200);
          });

        this.openItemService.getOpenItems(this.debtorNmndid, this.debtorNdktkdnr, this.debtorNdktid)
          .subscribe(data => {
            setTimeout(() => {
            this.debtorOpenItemsDataRows = data;
              this.allOpenitem = this.debtorOpenItemsDataRows.length;
              this.pgnallOpenitem = this.allOpenitem;
              this.pageSilce = this.debtorOpenItemsDataRows.slice(0, this.pageSizeUser);
            }, 200);
          });

         this.csvService.getOpenItemCsv(this.user.nmndid, this.debtorNdktkdnr, this.debtorNdktid)
              .subscribe(data => {
                setTimeout(() => {
                  this.debtorOpenItemCsv = data;
                }, 200);
              });
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Spinner
  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

    //Load user data
  private loadCurrentUserData() {
        this.alertService.startLoadingMessage();

        if (this.canViewAllRoles) {
            this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
        } else {
            this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
        }
    }

    /**
     * @param user
     * @param roles
     */
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {
        this.alertService.stopLoadingMessage();
        this.user = user;

        // Webservice calls
        this.openItemService.getOpenPositionMainData(this.user.nmndid, this.debtorNdktid, this.debtorNdktkdnr)
          .subscribe(data => {
            setTimeout(() => {
              this.debtorOpenItemMainData2Rows = data;
            }, 200);
          });
  
        this.openItemService.getOpenItems(this.user.nmndid, this.debtorNdktkdnr, this.debtorNdktid)
          .subscribe(data => {
            setTimeout(() => {
              this.debtorOpenItemsDataRows = data;       
              this.allOpenitem = this.debtorOpenItemsDataRows.length;
              this.pageSilce = this.debtorOpenItemsDataRows.slice(0, this.pageSizeUser);             
            }, 200);
          });

      this.csvService.getOpenItemCsv(this.user.nmndid, this.debtorNdktkdnr, this.debtorNdktid)
        .subscribe(data => {
          setTimeout(() => {
            this.debtorOpenItemCsv = data;           
          }, 200);
        });
    } catch (error) {
      console.log(error);
    }
  }
 
  public onPageChange(event:PageEvent) {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      this.pageSizeUser = sessionStorage.setItem('seiteDetailsOPMatPag', JSON.stringify(event.pageSize));
      this.pageSizeUser = JSON.parse(sessionStorage.getItem("seiteDetailsOPMatPag"));
      event.pageSize = this.pageSizeUser;
      let startIndex = event.pageIndex * event.pageSize;
      let endIndex = startIndex + event.pageSize;

      if (endIndex > this.debtorOpenItemsDataRows.length) {
        endIndex = this.debtorOpenItemsDataRows.length;
      }
      this.allOpenitem = this.debtorOpenItemsDataRows.length;
      this.pageSilce = this.debtorOpenItemsDataRows.slice(startIndex, endIndex);
    } else {
      sessionStorage.removeItem('seiteDetailsOPMatPag');
      sessionStorage.clear();
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
   }

  private onCurrentUserDataLoadFailed(error) {
      this.alertService.stopLoadingMessage();      
      console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
      this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  // Code for download CSV function
  downloadCSV() {
    this.status = ['approved', 'rejected', 'pending'];

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: false, //true       
      useBom: true,
      removeNewLines: true,
      headers: ['OP.Nr', 'OP.Dat', 'Faell.Dat', 'S', 'Rch.Bet', 'offen.Bet', 'Zahlungen', 'Gutschrift', 'Skto', 's.Abz', 'Z', 'rekl.Bet', 'G', 'VZ', 'Lz.Netto.Z', 'MS']
    };     
    new Angular2Csv(this.debtorOpenItemCsv, 'Offene Posten - Deb. Nr.' + this.debtorNdktdebitnr, options);
  }

  //PDF print
  print() {
    window.print();
  }
}
