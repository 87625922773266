
export class DKI {

        NDKIIDENT: number;

        TransactionId: string;

        CrefoNumber: number;

        NMNDID: number;

        NFKDID: number;

        NDKIDEBITNR: number;

        NDKILIMITWUN: string; // war number

        NDKILIMIT: string; //number

        CDKINAME1: string;

        CDKINAME2: string;

        CDKISTR: string;

        houseNumber: string;

        CLNDKZ: string;

        CDKIPLZ: string;

        CDKIORT: string;

        CDKIFREMDNR: string;

        CVRBNR: string;

        CVRBBEZ1: string;

        NDKIZIEL1: string;

        NDKISKONTO1: number;

        NDKIZIEL2: string;

        NDKISKONTO2: number;

        NDKIZIEL3: string;

        CDKIBKTONR1: string;

        CDKIBLZ1: string;

        CDKIBANK1: string;

        CDKINAMEA: string;

        CDKITELEFONA: string;

        CDKIFAXA: string;

        CDKIEMAILA: string;

        DDKIAUFDAT: Date;

       /* CDKILIMKZ: CharacterData;*/

        LDKIKZLAST: number;

        LDKIKZABBUCHUNG: number;

        LDKIKZLASTZHL: number;

        CDKIUSTIDNR: string;

        CDKIAUSLAND1: string;

        CDKIAUSLAND2: string;

        DDKIGEBURT: Date;

        NDKIGESCHLECHT: string;

        CDKITELEFON: string;

        CDKIFAX: string;

        CDKIEMAIL: string;

        LDKIKZBASISLASTSCHRIFT: number;

        LDKIKZFIRMENLASTSCHRIFT: number;

        CDKIBIC1: string;

        CDKIIBAN1: string;

        NDKIHNR: number;

        NDKIUNR: number;

        NSRFKEY: number;
        NSMWKEY: number;

        NDKIVORGANG: number;
}
