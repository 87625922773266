
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Recommendation } from '../models/recommendation.model';
import { Recommendation2 } from '../models/recommendation2.model';
import { AuthService } from './auth.service';

@Injectable()
export class RecommendationService {

  private readonly _baseUrl: string;
  private readonly _baseUrl2: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl = 'api/debtor/recommendation/data/1';
    this._baseUrl2 = 'api/debtor/recommendation/data/2';
  }

  getRecommendationDebtor(nrsiga, nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<Recommendation[]>(this._baseUrl + '?' + 'nrsiga=' + nrsiga + '&' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }

  getRecommendation2Debtor(recht, nrsiga, nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<Recommendation2[]>(this._baseUrl2 + '?' + 'nrsiga=' + nrsiga + '&' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
}
