
import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { Utilities } from '../../services/utilities';
// alert
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { fadeInOut } from '../../services/animations';
import { ContactService } from '../../services/contact.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css'],
  animations: [fadeInOut]
})

export class ImprintComponent implements OnInit{

  // load user data
  @Input()
  isGeneralEditor = false;

  //Session
  private user: User = new User();

  imprintDataRows = [];

  constructor(private httpClient: HttpClient, private alertService: AlertService, private accountService: AccountService, private authService: AuthService,
    private router: Router, private contactService: ContactService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  ngOnInit() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      // load user data
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
      }
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.user.nmndid != 0 && this.user.nmndid != null) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;

    //Webservice calls
    this.contactService.getImprintData(this.user.nmndid).
      subscribe(data => {
        setTimeout(() => {
          this.imprintDataRows = data;
        }, 500);
    });
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, MessageSeverity.error, error);
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }
}
