import { User } from './user.model';


export class UserEdit extends User {
  constructor(currentPassword?: string, newPassword?: string, confirmPassword?: string, jobTitle?: string, accessFailedCount?: number, changePasswordRequest?: any, dsgvoAccepted?:Date) {
        super();

        this.currentPassword = currentPassword;
        this.newPassword = newPassword;
        this.confirmPassword = confirmPassword;
        this.jobTitle = jobTitle;
    this.accessFailedCount = accessFailedCount;
    this.changePasswordRequest = changePasswordRequest;
    this.dsgvoAccepted = dsgvoAccepted;
  }
    public accessFailedCount: number;
    public jobTitle: string;
    public currentPassword: string;
    public newPassword: string;
  public confirmPassword: string;
  public changePasswordRequest: any;
  public dsgvoAccepted: Date;

}
