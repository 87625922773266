
export class Dsgvo {

  cfacdsgvotext: string;

  dfacdsgvoaendat: Date;

  lfaclimstreich: number;

  lfachandbuch: number;

  nmndid: number;

  id: string;

  dsgvoAccepted: Date;

  userName: string;
  
}
