import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { OpenLimitRequestCsv } from '../../models/open-limit-request-csv.model'
import { ObligoService } from '../../services/obligo.service';
import { ContactService } from '../../services/contact.service';
import { Permission } from '../../models/permission.model';
import { CSVService } from '../../services/csv.service';
import { Meta } from '@angular/platform-browser';
import { LimitService } from '../../services/limit.service';
import { DKI } from '../../models/dki.model';
import { Dsgvo } from '../../models/dsgvo';
import { AccountChangeAndLimitChange } from '../../models/account-change-and-limit-change.model';
import * as moment from 'moment';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { DatePipe } from '@angular/common';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-last-limit-decisions',
  templateUrl: './last-limit-decisions.component.html',
  providers:[PageEvent],
  styleUrls: ['./last-limit-decisions.component.css']
})

export class LastLimitDecisionsComponent implements OnInit {
  printerIcon = require("../../assets/images/other/printer.png");
  excelIcon = require("../../assets/images/other/excel-csv.png");

  private user: User = new User();
  private dki: DKI;
  private dsgvo: Dsgvo;
  private accountChangeAndLimitChange: AccountChangeAndLimitChange;
  lastLimitDecisionsRows = [];
  checkedLimitDecisions = [];
  dsgvoRows: Dsgvo[] = [];
  limitAdminRechte: boolean = false;
  accountChangeAndLimitChangeDataRows = [];
  httpCode: any;
  lastLimitDecisionsParameters = ['ndktdebitnr', 'cname', 'cdktplz', 'cdktort', 'ndktlimit', 'climkz', 'ddktlimitab', 'ddktlimitbis'];
  config: any;
  limitCancelling: boolean = false;
  isDataAvailable: boolean = true;

  // table sorting
  key: string = "ddktlimitab";
  reverse = true;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //CSV Download
  status: any[]; 

  public setItemsPerPage(event) {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      this.config.itemsPerPage = event;
      sessionStorage.setItem('seiteDetails', JSON.stringify(this.config.itemsPerPage));
      /* console.log(JSON.parse(sessionStorage.getItem("seiteDetails")));*/
    } else {
      sessionStorage.removeItem('seiteDetails');
      sessionStorage.clear();
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  pageChange(event) {
    this.config.currentPage = event;
  }

  limitDecisionsItemsSeite: any = [10, 20, 50, 100];
  dynamItemPage;
  pageValue;

  constructor(private accountService: AccountService, private alertService: AlertService, private authService: AuthService, private csvService: CSVService, private factorService: ContactService, private obligoService: ObligoService, private router: Router, private meta: Meta, private limitService: LimitService, private pageEvent: PageEvent) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
    this.dki = new DKI();
    this.accountChangeAndLimitChange = new AccountChangeAndLimitChange();
    this.dsgvo = new Dsgvo();

    if (JSON.parse(sessionStorage.getItem("seiteDetails")) > 0) {
      this.dynamItemPage = JSON.parse(sessionStorage.getItem("seiteDetails"));
    } else {
      this.dynamItemPage = 10;
    }

    this.config = {
      currentPage: 1,
      itemsPerPage: this.dynamItemPage,
      totalItems: 0
    };

    for (var i = 0; i < this.limitDecisionsItemsSeite.length; i++) {
      if (this.limitDecisionsItemsSeite[i] == this.dynamItemPage) {
        this.setItemsPerPage(this.dynamItemPage);
      }
    }

    this.config.currentPage = 1;
  }

  // load user data
  @Input()
  isGeneralEditor = false;

  ngOnInit(): void {
    for (var i = 0; i < this.accountService.currentUser.roles.length; i++) {
      if (this.accountService.currentUser.roles[i] === 'user3') {
        this.isDataAvailable = false;
      }
    }
    if (this.isDataAvailable) {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } else {
      this.alertService.showMessage("Die Sicht auf dieses Modul ist nicht freigeschaltet.", "", MessageSeverity.info);
    }
  }

  // load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  dataFactUser = [];
  dataCSVUser = [];

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {
      this.alertService.stopLoadingMessage();
      this.user = user;

      if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }
      }

      if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
        this.user.nfkdid = this.obligoService.getNFKDID();
        if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
          this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
        }
      }
      
      // Webservice calls
      this.factorService.getLimitRequests(this.user.nmndid, this.user.nfkdkdnr, this.user.nfkdid, 1)
        .subscribe(data => {
          this.lastLimitDecisionsRows = data;          
        });

      this.factorService.getDsgvo(this.user.nmndid).subscribe((data) => {
        this.dsgvoRows = data;

        if (this.dsgvoRows[0].lfaclimstreich === 1) {
          this.limitCancelling = true;
        }
      });

      this.csvService.getCsvUser().subscribe(data => {
        this.dataCSVUser = data;

        for (var i = 0; i < this.dataCSVUser.length; i++) {
          this.dataCSVUser[i].nfkdid = this.user.nfkdid;
          this.dataCSVUser[i].nfkdkdnr = this.user.nfkdkdnr;
        }
      });

      this.factorService.getUserFactor().subscribe(data => {
        this.dataFactUser = data;

        for (var i = 0; i < this.dataFactUser.length; i++) {
          this.dataFactUser[i].nfkdid = this.user.nfkdid;
          this.dataFactUser[i].nfkdkdnr = this.user.nfkdkdnr;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  //Pagination
  public onPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.lastLimitDecisionsRows.length) { endIndex = this.lastLimitDecisionsRows.length; }
    this.lastLimitDecisionsRows = this.lastLimitDecisionsRows.slice(startIndex, endIndex);
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  onSubmit() {
    this.checkedLimitDecisions = this.lastLimitDecisionsRows.filter(lastLimit => lastLimit.checked === true);
    this.lastLimitDecisionsRows = this.lastLimitDecisionsRows.filter(lastLimit => lastLimit.checked !== true);

    this.checkedLimitDecisions.forEach(checkedLimitDecision => {
      this.limitService.getAccountChangeAndLimitChangeData(this.user.nmndid, this.user.nfkdkdnr, checkedLimitDecision.ndktdebitnr).subscribe(data => {
        this.accountChangeAndLimitChangeDataRows = data;
        this.accountChangeAndLimitChangeDataRows.forEach(accountChangeAndLimitChange => {
          this.setLimitCancellingInDatabase(accountChangeAndLimitChange, checkedLimitDecision.ndktdebitnr, this.dki);
          console.debug(accountChangeAndLimitChange);
        });
      });
    });    
  }

  // Code for download CSV function
  downloadCSV() {
    this.status = ["approved", "rejected", "pending"];

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: false,
      useBom: true,
      removeNewLines: true,
      headers: ['DebNr.', 'Name', 'Plz', 'Ort', 'akt.Limit Tsd', 'LiKz', 'Lim.ab', 'Lim.bis']
    };
    new Angular2Csv(this.lastLimitDecisionsRows, "Letzte Limitentscheidungen", options);
  }

  setLimitCancellingInDatabase(accountChangeAndLimitChange: AccountChangeAndLimitChange, ndktdebitnr: String,  value: DKI) {    
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.limitService.getUserLimit().subscribe(data => data);
        if (this.user.roles.find(element => element == 'adminFactor') == "adminFactor") {
          this.limitAdminRechte = true;
          this.alertService.showMessage("Sie verfügen nicht über genügend Rechte!", "", MessageSeverity.warn);
        } else {
          console.debug(accountChangeAndLimitChange);
          
          this.limitAdminRechte = false;

          value.NDKILIMITWUN = "0";
          value.NMNDID = this.user.nmndid;
          //this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidL"));
          value.NFKDID = this.user.nfkdid;
          value.NDKIDEBITNR = Number(ndktdebitnr);
          console.debug(JSON.stringify(value));
          value.CDKINAME1 = accountChangeAndLimitChange['cdktnamE1'];
          value.CDKINAME2 = accountChangeAndLimitChange['cdktnamE2'];
          value.CDKISTR = accountChangeAndLimitChange['cdktstr'];
          value.CLNDKZ = accountChangeAndLimitChange['clndkz'];
          value.CDKIPLZ = accountChangeAndLimitChange['cdktplz'];
          value.CDKIORT = accountChangeAndLimitChange['cdktort'];

          if (accountChangeAndLimitChange['nsrfkey'] === undefined || accountChangeAndLimitChange['nsrfkey'] === null) {
            accountChangeAndLimitChange['nsrfkey'] = 0;
          }
          value.NSRFKEY = accountChangeAndLimitChange['nsrfkey'];

          if (accountChangeAndLimitChange['nsmwkey'] === undefined || accountChangeAndLimitChange['nsmwkey'] === null) {
            accountChangeAndLimitChange['nsmwkey'] = 0;
          }
          value.NSMWKEY = accountChangeAndLimitChange['nsmwkey'];

          if (accountChangeAndLimitChange['ndktskontO1'] === undefined || accountChangeAndLimitChange['ndktskontO1'] === null) {
            accountChangeAndLimitChange['ndktskontO1'] = 0;
          }
          value.NDKISKONTO1 = accountChangeAndLimitChange['ndktskontO1'];
          
          if (accountChangeAndLimitChange['ndktskontO2'] === undefined || accountChangeAndLimitChange['ndktskontO2'] === null) {
            accountChangeAndLimitChange['ndktskontO2'] = 0;
          }
          value.NDKISKONTO2 = accountChangeAndLimitChange['ndktskontO2'];
          
          if (accountChangeAndLimitChange['ndktzieL1'] === undefined) {
            accountChangeAndLimitChange['ndktzieL1'] = 0;
          }
          if (accountChangeAndLimitChange['ndktzieL2'] === undefined) {
            accountChangeAndLimitChange['ndktzieL2'] = 0;
          }
          if (accountChangeAndLimitChange['ndktzieL3'] === undefined) {
            accountChangeAndLimitChange['ndktzieL3'] = 0;
          }
          value.NDKIZIEL1 = accountChangeAndLimitChange['ndktzieL1'];
          value.NDKIZIEL2 = accountChangeAndLimitChange['ndktzieL2'];
          value.CDKIFREMDNR = accountChangeAndLimitChange['cdktfremdnr'];
          value.CVRBNR = accountChangeAndLimitChange['cvrbnr'];
          value.NDKIZIEL3 = accountChangeAndLimitChange['ndktzieL3'];
          value.CDKINAMEA = accountChangeAndLimitChange['cdkaname'];
          value.CDKITELEFONA = accountChangeAndLimitChange['cdkatelefon'];
          value.CDKIFAXA = accountChangeAndLimitChange['cdkafax'];
          value.CDKIEMAILA = accountChangeAndLimitChange['cdkaemail'];
          value.NDKILIMIT = null;
          value.CDKIUSTIDNR = accountChangeAndLimitChange['cdktustidnr'];
          if (accountChangeAndLimitChange['ndktgeschlecht'] === undefined || accountChangeAndLimitChange['ndktgeschlecht'] === null) {
            accountChangeAndLimitChange['ndktgeschlecht'] = 0;
          }
          value.NDKIGESCHLECHT = accountChangeAndLimitChange['ndktgeschlecht'].toString();
          value.CDKITELEFON = accountChangeAndLimitChange['cdkttelefon'];
          value.CDKIFAX = accountChangeAndLimitChange['cdktfax'];
          value.CDKIEMAIL = accountChangeAndLimitChange['cdktemail'];
          value.NDKIVORGANG = 3;
          value.CVRBNR = accountChangeAndLimitChange['cvrbnr'];
          
          value.DDKIGEBURT = new Date();
          value.DDKIAUFDAT = new Date();
          console.debug(JSON.stringify(value));

          this.limitService.postLimitChangeData(value).subscribe(res => {
            this.httpCode = res;

            if (this.httpCode === 500) {
              this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
            } else {
              this.accountChangeAndLimitChangeDataRows.push(res);              
              setTimeout(() => {
                this.router.navigateByUrl('/limit-change-success');
              }, 200);
            }
          }), err => {
            console.log("Error Occured " + err);
          }
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  //PDF print
  print() {
    var printContents = document.getElementById("printable").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    window.print();
    window.close();

    document.body.innerHTML = originalContents;
  }
}
