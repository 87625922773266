

export class Datei {

 /*  IDENT: number;*/
   NMNDID: number;
   NFKDID: number;
   CDATEI: string;
  DAUFDAT: Date;
  cname: string;


}
