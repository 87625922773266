
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DebtorRiskInformation } from '../models/debtor-risk-information.model';
import { DebtorRiskDebtors } from '../models/debtor-risk-debtor.model';
import { DebtorRiskConditions } from '../models/debtor-risk-conditions.model';
import { DebtorRiskBankDetails } from '../models/debtor-risk-bank-details.model';
import { AuthService } from './auth.service';
import { DebtorRiskLim } from '../models/debtor-risk-lim';


@Injectable()
export class RiskService {

  private readonly _baseUrl1: string;
  private readonly _baseUrl2: string;
  private readonly _baseUrl3: string;
  private readonly _baseUrl4: string;
  private readonly _baseUrl5: string;
  private readonly _baseUrl6: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl1 = 'api/debtor/risk/debtors';
    this._baseUrl2 = 'api/debtor/risk/information';
    this._baseUrl3 = 'api/debtor/risk/conditions';
    this._baseUrl4 = 'api/debtor/risk/bank/details';
    this._baseUrl5 = 'api/debtor/risk/lim';
    this._baseUrl6 = 'api/debtor/risk/debinfo';
  }

  getDebtorRiskLim() {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );
      return this.httpClient.get<DebtorRiskLim[]>(this._baseUrl5 , {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }

  getDebtorRiskDebtors(nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
        );
        console.log(ndktkdnr);
        return this.httpClient.get<DebtorRiskDebtors[]>(this._baseUrl1 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }

  getDebtorRiskInformation(nrsiga, nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
      );
      
        return this.httpClient.get<DebtorRiskInformation[]>(this._baseUrl2 + '?' + 'nrsiga=' + nrsiga + '&' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }


  getDebtorRiskDebInfo(nrsiga, nmndid, ndktkdnr, ndebid) {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
      );
      return this.httpClient.get<DebtorRiskInformation[]>(this._baseUrl6 + '?' + 'nrsiga=' + nrsiga + '&' + 'nmndid=' + nmndid + '&' + 'ndktkdnr=' + ndktkdnr + '&' + 'ndebid=' + ndebid, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }

  getDebtorRiskCondition(nrsiga, nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<DebtorRiskConditions[]>(this._baseUrl3 + '?' + 'nrsiga=' + nrsiga + '&' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }

  getDebtorRiskBankDetails(nrsiga, nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
      return this.httpClient.get<DebtorRiskBankDetails[]>(this._baseUrl4 + '?' + 'nrsiga=' + nrsiga + '&' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
}
