export class User {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(id?: string, userName?: string, fullName?: string, email?: string, department?: string, phoneNumber?: string, roles?: string[],
    nmndid?: number, nfkdkdnr?: number, nfkdid?: number, nfkdkonzern?: number, changePasswordRequest?: boolean
  
     ) {

        this.id = id;
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.department = department;
        this.phoneNumber = phoneNumber;
        this.roles = roles;

        // Obligo
        this.nmndid = nmndid;
        this.nfkdkdnr = nfkdkdnr;
    this.nfkdid = nfkdid;
    this.nfkdkonzern = nfkdkonzern;

    //new Pass
    this.changePasswordRequest = changePasswordRequest;

    
    }

    get friendlyName(): string {
        let name = this.fullName || this.userName;

        if (this.department)
            name = this.department + " " + name;
        return name;
    }

    public id: string;
    public userName: string;
    public fullName: string;
    public email: string;
    public department: string;
    public phoneNumber: string;
    public isEnabled: boolean;
    public isLockedOut: boolean;
    public roles: string[];

    public nmndid: number;
    public nfkdkdnr: number;
  public nfkdid: number;
  public nfkdkonzern: number;
  public changePasswordRequest: boolean;
 
 
 
}
