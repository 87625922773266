import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Limit } from '../models/limit.model';
import { AccountChangeAndLimitChange } from '../models/account-change-and-limit-change.model';
import { DKI } from '../models/dki.model';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AccountCreationMgPlz } from '../models/account-creation-mg-plz';
import { UserObligo } from '../models/user-obligo.model';
import { LastLimitCount } from '../models/last-limit-count.model';

@Injectable()
export class LimitService {
  dkis: Observable<DKI[]>;

  private readonly _baseUrl: string;
  private readonly _baseUrl2: string;
  private readonly _baseUrl3: string;
  private readonly _baseUrl4: string;
  private readonly _baseUrl5: string;

  //user
  private readonly _baseUrlUserLimit: string;

  _nfkdkdnr: number;
  _nfkdid: number;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl = '/api/debtor/check/if/debtornumber/exists';
    this._baseUrl2 = '/api/limit/accountChange/and/limitChange';
    this._baseUrl3 = '/api/limit/accountCreation/and/accountChange/and/limitChange';
    this._baseUrl4 = '/api/limit/accountCreation/mgplz';
    this._baseUrl5 = '/api/limit/LastLimitCount';
    this._baseUrlUserLimit = '/api/limit/userLimit';
  }

  getNFKDKDNR() {
    return this._nfkdkdnr;
  }

  setNFKDKDNR(val) {
    this._nfkdkdnr = val;
  }

  getNFKDID() {
    return this._nfkdid;
  }

  setNFKDID(val) {
    this._nfkdid = val;
  }

  getUserLimit() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<UserObligo[]>(this._baseUrlUserLimit, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getAccountCreationMgPlz(nmndid, nfkdkdnr) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      this.setNFKDKDNR(nfkdkdnr);
      
      return this.httpClient.get<AccountCreationMgPlz[]>(this._baseUrl4 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getCheckIfDebtorNumberExists(nmndid, ndktkdnr, ndktdebitnr) {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.get<Limit[]>(this._baseUrl + '?' + 'nmndid=' + nmndid + '&' + 'ndktkdnr=' + ndktkdnr + '&' + 'ndktdebitnr=' + ndktdebitnr, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getAccountChangeAndLimitChangeData(nmndid, ndktkdnr, ndktdebitnr) {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.get<AccountChangeAndLimitChange[]>(this._baseUrl2 + '?' + 'nmndid=' + nmndid + '&' + 'ndktkdnr=' + ndktkdnr + '&' + 'ndktdebitnr=' + ndktdebitnr, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getLimitCount(nmndid) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<LastLimitCount[]>(this._baseUrl5 + '?' + 'nmndid=' + nmndid, { headers: httpHeaders });
    } catch (error) {
      console.log(error);
    }
  }

  postAccountCreationData(dki: DKI) {
    try {
        const body = {
            NMNDID: dki.NMNDID,
            NFKDID: dki.NFKDID,
            NDKIDEBITNR: dki.NDKIDEBITNR,
            NDKILIMITWUN: dki.NDKILIMITWUN,
            CrefoNumber: dki.CrefoNumber,
            CDKINAME1: dki.CDKINAME1,
            CDKINAME2: dki.CDKINAME2,
            CDKISTR: dki.CDKISTR,
            CLNDKZ: dki.CLNDKZ,
            CDKIPLZ: dki.CDKIPLZ,
            CDKIORT: dki.CDKIORT,
            NSMWKEY: Number(dki.NSMWKEY),
            NSRFKEY: Number(dki.NSRFKEY),
            CDKIFREMDNR: dki.CDKIFREMDNR,
            CVRBNR: dki.CVRBNR,
            NDKIZIEL1: Number(dki.NDKIZIEL1),
            NDKISKONTO1: Number(dki.NDKISKONTO1),
            NDKIZIEL2: Number(dki.NDKIZIEL2),
            NDKISKONTO2: Number(dki.NDKISKONTO2),
            NDKIZIEL3: Number(dki.NDKIZIEL3),
            CDKIBKTONR1: dki.CDKIBKTONR1,
            CDKIBLZ1: dki.CDKIBLZ1,
            CDKIBANK1: dki.CDKIBANK1,
            CDKINAMEA: dki.CDKINAMEA,
            CDKITELEFONA: dki.CDKITELEFONA,
            CDKIFAXA: dki.CDKIFAXA,
            CDKIEMAILA: dki.CDKIEMAILA,
            CDKIUSTIDNR: dki.CDKIUSTIDNR,
            CDKIAUSLAND1: dki.CDKIAUSLAND1,
            CDKIAUSLAND2: dki.CDKIAUSLAND2,
            DDKIGEBURT: dki.DDKIGEBURT,
            DDKIAUFDAT: dki.DDKIAUFDAT,
            NDKIGESCHLECHT: dki.NDKIGESCHLECHT,
            CDKITELEFON: dki.CDKITELEFON,
            CDKIFAX: dki.CDKIFAX,
            CDKIEMAIL: dki.CDKIEMAIL,
            CDKIBIC1: dki.CDKIBIC1,
            CDKIIBAN1: dki.CDKIIBAN1,
            NDKIVORGANG: dki.NDKIVORGANG
        }
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.post<DKI>(this._baseUrl3, body, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  postAccountChangeData(dki: DKI) {
    try {
        const body = {
            NMNDID: dki.NMNDID,
            NFKDID: dki.NFKDID,
            NDKIDEBITNR: dki.NDKIDEBITNR,
            CDKINAME1: dki.CDKINAME1,
            CDKINAME2: dki.CDKINAME2,
            CDKISTR: dki.CDKISTR,
            CLNDKZ: dki.CLNDKZ,
            CDKIPLZ: dki.CDKIPLZ,
            CDKIORT: dki.CDKIORT,
            NSMWKEY: Number(dki.NSMWKEY),
            NSRFKEY:  Number(dki.NSRFKEY),
            CDKIFREMDNR: dki.CDKIFREMDNR,
            CVRBNR: dki.CVRBNR,
            NDKIZIEL1: Number(dki.NDKIZIEL1),
            NDKISKONTO1: Number(dki.NDKISKONTO1),
            NDKIZIEL2: Number(dki.NDKIZIEL2),
            NDKISKONTO2: Number(dki.NDKISKONTO2),
            NDKIZIEL3: Number(dki.NDKIZIEL3),
            CDKIBANK1: dki.CDKIBANK1,
            CDKINAMEA: dki.CDKINAMEA,
            CDKITELEFONA: dki.CDKITELEFONA,
            CDKIFAXA: dki.CDKIFAXA,
            CDKIEMAILA: dki.CDKIEMAILA,           
            CDKIUSTIDNR: dki.CDKIUSTIDNR,            
            DDKIGEBURT: dki.DDKIGEBURT,
            NDKIGESCHLECHT: dki.NDKIGESCHLECHT,
            CDKITELEFON: dki.CDKITELEFON,
            CDKIFAX: dki.CDKIFAX,
            CDKIEMAIL: dki.CDKIEMAIL,            
            CDKIBIC1: dki.CDKIBIC1,
            CDKIIBAN1: dki.CDKIIBAN1,
            NDKIVORGANG: dki.NDKIVORGANG,
            DDKIAUFDAT: dki.DDKIAUFDAT
        }
        if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
          const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
          return this.httpClient.post<DKI>(this._baseUrl3, body, {headers: httpHeaders});
        }
    } catch (error) {
      console.log(error);
    }
  }

  postLimitChangeData(dki: DKI) {
    console.debug(dki);
    try {
        const body = {
            NMNDID: dki.NMNDID,
            NFKDID: dki.NFKDID,
            NDKIDEBITNR: dki.NDKIDEBITNR,
            NDKILIMITWUN: dki.NDKILIMITWUN,
            CDKINAME1: dki.CDKINAME1,
            CDKINAME2: dki.CDKINAME2,
            CDKISTR: dki.CDKISTR,
            CLNDKZ: dki.CLNDKZ,
            CDKIPLZ: dki.CDKIPLZ,
            CDKIORT: dki.CDKIORT,
            NSMWKEY: Number(dki.NSMWKEY),
            NSRFKEY: Number(dki.NSRFKEY),
            CDKIFREMDNR: dki.CDKIFREMDNR,
            CVRBNR: dki.CVRBNR,
            NDKIZIEL1: Number(dki.NDKIZIEL1),
            NDKISKONTO1: Number(dki.NDKISKONTO1),
            NDKIZIEL2: Number(dki.NDKIZIEL2),
            NDKISKONTO2: Number(dki.NDKISKONTO2),
            NDKIZIEL3: Number(dki.NDKIZIEL3),
            CDKIBKTONR1: dki.CDKIBKTONR1,
            CDKIBLZ1: dki.CDKIBLZ1,
            CDKIBANK1: dki.CDKIBANK1,
            CDKINAMEA: dki.CDKINAMEA,
            CDKITELEFONA: dki.CDKITELEFONA,
            CDKIFAXA: dki.CDKIFAXA,
            CDKIEMAILA: dki.CDKIEMAILA,
            NDKILIMIT: dki.NDKILIMIT,           
            CDKIUSTIDNR: dki.CDKIUSTIDNR,          
            DDKIGEBURT: dki.DDKIGEBURT,
            DDKIAUFDAT: dki.DDKIAUFDAT,
            NDKIGESCHLECHT: dki.NDKIGESCHLECHT,
            CDKITELEFON: dki.CDKITELEFON,
            CDKIFAX: dki.CDKIFAX,
            CDKIEMAIL: dki.CDKIEMAIL,            
            CDKIBIC1: dki.CDKIBIC1,
            CDKIIBAN1: dki.CDKIIBAN1,
            NDKIVORGANG: dki.NDKIVORGANG
        }
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
        return this.httpClient.post<DKI>(this._baseUrl3, body, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }
}
