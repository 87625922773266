// ====================================================
// Davain Pablo Edwards
// 
// ====================================================

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SmartSignUp } from '../models/smart-sign-up.model';
import { AuthService } from './auth.service';

@Injectable()
export class SmartSignUpService {

  private readonly _baseUrl: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl = 'api/smartsignup/company';
    //this._baseUrl = 'https://www.firmenwissen.de/search/suggest/companywithaddress/';
  }

  getSmartSignUp(search, crefoNumber) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<SmartSignUp[]>(this._baseUrl + '?' + 'search=' + search + '&' + 'crefoNumber=' + crefoNumber, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
}
