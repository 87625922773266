import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';
import { ActiveObligo } from '../../models/active-obligo.model';
import { Router } from '@angular/router';
import { EndpointFactory } from '../../services/endpoint-factory.service';
import { ContactService } from '../../services/contact.service';
import { UserInfoComponent } from '../controls/user-info.component';
import { UserEdit } from '../../models/user-edit.model';
import { Utilities } from "../../services/utilities";
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
// Datepicker date converter
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Meta } from '@angular/platform-browser';
moment.locale('de');
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-login-pass-change',
  templateUrl: './login-pass-change.component.html',
  styleUrls: ['./login-pass-change.component.css'],
  animations: [fadeInOut]
})

export class LoginPassChangeComponent implements OnInit {

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  @Input()
  isModal = false;

  @Input()
  isViewOnly: boolean;

  // load user data
  @Input()
  isGeneralEditor = false;

  @ViewChild('f')
  private form;

  //ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild('userName')
  private userName;

  @ViewChild('email')
  private email;

  @ViewChild('userPassword')
  private userPassword;

  @ViewChild('currentPassword')
  private currentPassword;

  @ViewChild('newPassword')
  private newPassword;

  @ViewChild('confirmPassword')
  private confirmPassword;

  @ViewChild('roles')
  private roles;

  @ViewChild('rolesSelector')
  private rolesSelector;

  //Session
  private user: User = new User();
  private userEdit: UserEdit;
  private allRoles: Role[] = [];
  dsgvoData = [];

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  //FactoryPassChange akt. User
  factoryPassChange: boolean = false;  
  public formResetToggle = true;
  private isEditingSelf = false;
  private showValidationErrors = false;
  private isChangePassword = false;
  private editingUserName: string;
  private isEditMode = false;
  private uniqueId: string = Utilities.uniqueId();
  private isNewUser = false;
  private isSaving = false;

  //Time
  public now: Date = new Date();
  public datumDsgvo;
  validationNewPassAndCurrentPass: boolean = false;

  constructor(private alertService: AlertService, private authService: AuthService, private accountService: AccountService,
    private router: Router, private dsgvoServise: ContactService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }
   
  ngOnInit() { 
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        // load user data
        if (!this.isGeneralEditor) {       
          this.loadCurrentUserData();
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {}
  }

  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  /**
    * @param user
    * @param roles
    */
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;
    this.factoryPassChange = true;

    if (this.user.changePasswordRequest === true) {
      this.editUser(user, roles);
      this.changePassword();
    } else {
      this.resetForm();
      this.user = user;    
    }
  }

  private onCurrentUserDataLoadFailed(error) {  
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, MessageSeverity.error, error);
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  hinweisPass: boolean;

  private save() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        if (this.userEdit.newPassword === this.userEdit.currentPassword) {
          this.form.invalid = true;
          this.alertService.showMessage("Bitte beachten Sie!!!","Das neue Passwort sollte sich von dem alten Passwort unterscheiden!", MessageSeverity.warn);
          this.showValidationErrors = true;
          this.validationNewPassAndCurrentPass = true;
          return;
        } else {          
          this.isSaving = true;
          this.userEdit.jobTitle = this.userEdit.newPassword; //neues Passwort des Users
          this.userEdit.changePasswordRequest = false;     // wird im AspNetUsers -> ChangePasswordRequest 0 gespeichert (das bedeutet User hat Factoringspassword geändert) Wenn 1 steht muss noch geändert werden!!!      
          this.dsgvoServise.getUserDsgvo(this.user.id, this.user.nmndid).subscribe(data => {
            this.dsgvoData = data;

            if (this.dsgvoData.length > 0) {
              for (var i = 0; i < this.dsgvoData.length; i++) {
                this.datumDsgvo = this.dsgvoData[i].dsgvoAccepted;
                this.userEdit.dsgvoAccepted = this.datumDsgvo;   // DSGVO Datum überschrieben

                if (!this.isModal) {
                  this.alertService.startLoadingMessage("Änderungen speichern...");
                }

                if (this.isNewUser) {
                  this.accountService.newUser(this.userEdit).subscribe(user => this.saveSuccessHelper(user), error => this.saveFailedHelper(error));
                } else {
                  this.accountService.updateUser(this.userEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
                }
              }
            } else {
              this.alertService.showMessage("Keine Verbindung zur Datenbank vorhanden!", "Die Daten von der Tabelle AspNetUsers könne nicht gelesen werden", MessageSeverity.warn);
            }
          });
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  private saveSuccessHelper(user?: User) {
    if (user)
      Object.assign(this.userEdit, user);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.isChangePassword = false;
    this.showValidationErrors = false;
    Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();
    this.resetForm();

    if (this.isGeneralEditor) {
      if (this.isNewUser)
        this.alertService.showMessage("Success", `User \"${this.user.userName}\" was created successfully`, MessageSeverity.success);
      else if (!this.isEditingSelf)
        this.alertService.showMessage("Success", `Changes to user \"${this.user.userName}\" was saved successfully`, MessageSeverity.success);
    }

    if (this.isEditingSelf) {
      this.alertService.showMessage("Sie haben Ihr Passwort erfolgreich geändert","", MessageSeverity.success);
    }

    this.router.navigateByUrl('/obligo');
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }     
  }

  private saveFailedHelper(error) { 
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "The below errors occured whilst saving your changes:", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback)
      this.changesFailedCallback();
  }

  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser().subscribe(user => {
        this.loadCurrentUserData();
    }, error => {
      this.alertService.resetStickyMessage();
      this.alertService.showStickyMessage("Refresh failed", "An error occured whilst refreshing logged in user information from the server", MessageSeverity.error, error);
    });
  }

  private changePassword() {
    this.isChangePassword = true;
  }

  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = true;

      setTimeout(() => {
        this.formResetToggle = true;
      },500);
    }
  }

  newUser(allRoles: Role[]) {
    this.isGeneralEditor = true;
    this.isNewUser = true;
    this.allRoles = [...allRoles];
    this.editingUserName = null;
    this.user = this.userEdit = new UserEdit();
    this.userEdit.isEnabled = true;
    this.edit();
    return this.userEdit;
  }

  private edit() {
    if (!this.isGeneralEditor) {
      this.isEditingSelf = true;
      this.userEdit = new UserEdit();
      Object.assign(this.userEdit, this.user);
    } else {
      if (!this.userEdit)
        this.userEdit = new UserEdit();
      this.isEditingSelf = this.accountService.currentUser ? this.userEdit.id === this.accountService.currentUser.id : false;
    }
    this.isEditMode = true;
    this.showValidationErrors = true;
    this.isChangePassword = false;  
  }

  editUser(user: User, allRoles: Role[]) {  
    if (user) {
      this.isGeneralEditor = true;
      this.isNewUser = false;
      this.setRoles(user, allRoles);
      this.editingUserName = user.userName;
      this.user = new User();
      this.userEdit = new UserEdit();
      Object.assign(this.user, user);
      Object.assign(this.userEdit, user);
      this.edit();
      return this.userEdit;
    }
    else {
      return this.newUser(allRoles);
    }   
  }

  displayUser(user: User, allRoles?: Role[]) {
    this.user = new User();
    Object.assign(this.user, user);
    this.setRoles(user, allRoles);
    this.isEditMode = false;
  }

  private setRoles(user: User, allRoles?: Role[]) {
    this.allRoles = allRoles ? [...allRoles] : [];

    if (user.roles) {
      for (const ur of user.roles) {
        if (!this.allRoles.some(r => r.name === ur))
          this.allRoles.unshift(new Role(ur));
      }
    }

    if (allRoles === null || this.allRoles.length !== allRoles.length)
      setTimeout(() => this.rolesSelector.refresh());
  }

  get canAssignRoles() { 
    return this.accountService.userHasPermission(Permission.assignRolesPermission);
  }

  get canViewAllRoles() {  
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }
}
