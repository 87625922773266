// ====================================================
// Davain Pablo Edwards
// 
// ====================================================

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DebtorAccountCSV } from '../models/debtor-account-csv.model';
import { AuthService } from './auth.service';
import { UserObligo } from '../models/user-obligo.model';
import { OpenItemCsv } from '../models/debtor-open-item-csv';
import { DebtorInvoiceListCsv } from '../models/debtor-invoice-list-csv.model';
import { OpenLimitRequestCsv } from '../models/open-limit-request-csv.model';

@Injectable()
export class CSVService {
  private readonly _baseUrl: string;
  private readonly _baseUrlUserCsv: string;
  private readonly _baseUrlOpenItemCsv: string;
  private readonly _baseUrlInvoiceListCsv: string;
  private readonly _baseUrlOpenLimitRequestCsv: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl = 'api/csv/debtor/accounts';
    this._baseUrlUserCsv = 'api/csv/userCSV';
    this._baseUrlOpenItemCsv = 'api/csv/open/items';
    this._baseUrlInvoiceListCsv = 'api/csv/invoice/list/data';
    this._baseUrlOpenLimitRequestCsv = 'api/csv/open/debtor/limit/requests';
  }

  getLimitRequestCsv(nmndid, nfkdkdnr, nfkdid, wunerl) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<OpenLimitRequestCsv[]>(this._baseUrlOpenLimitRequestCsv + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'nfkdid=' + nfkdid + '&' + 'wunerl=' + wunerl, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getInvoiceListCsv(nmndid, ndktid, nrekkdnr) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<DebtorInvoiceListCsv[]>(this._baseUrlInvoiceListCsv + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'nrekkdnr=' + nrekkdnr, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getOpenItemCsv(nmndid, nrekkdnr, ndktid) {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );
      return this.httpClient.get<OpenItemCsv[]>(this._baseUrlOpenItemCsv + '?' + 'nmndid=' + nmndid + '&' + 'nrekkdnr=' + nrekkdnr + '&' + 'ndktid=' + ndktid, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }


  getDebtorAccounts(nmndid, nfkdkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<DebtorAccountCSV[]>(this._baseUrl + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }


  getCsvUser() {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );
      return this.httpClient.get<UserObligo[]>(this._baseUrlUserCsv, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }

}
