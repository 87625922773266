import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-limit-change-success',
  templateUrl: './limit-change-success.component.html',
  styleUrls: ['./limit-change-success.component.css']
})
export class LimitChangeSuccessComponent implements OnInit {

  constructor(private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  ngOnInit() {
  }

}
