import { Component, Input, OnInit, AfterViewInit} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { fadeInOut } from '../../services/animations';

// alert
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { DownloadService } from '../../services/download.service';
import { ProgressStatus, ProgressStatusEnum } from '../../models/progress-status.model';

//
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

// Datepicker date converter
import * as moment from 'moment';
import { AuthService } from '../../services/auth.service';
import { ObligoService } from '../../services/obligo.service';
import { Router } from '@angular/router';
import { DateiService } from '../../services/datei.service';
import { and } from '@angular/router/src/utils/collection';
import { FileUploader } from 'ng2-file-upload';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ActiveObligo } from '../../models/active-obligo.model';
import { Eni } from '../../models/eni';
import { isNumeric } from 'jquery';
import { LimitService } from '../../services/limit.service';
import { Meta } from '@angular/platform-browser';
registerLocaleData(localeDe, 'de');

@Component({
    selector: 'app-submissions-component',
    templateUrl: './submissions.component.html',
    styleUrls: ['./submissions.component.css'],
    //animations: [fadeInOut]
})

//File upload source: https://www.devglan.com/angular/angular-multiple-file-upload
export class SubmissionsComponent implements AfterViewInit, OnInit {

  //Download
  public files: string[];
  public zipFiles: string[];
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showUploadError: boolean;
  private expectedFolder: string = "SubmissionFolder";

  httpCode: any;
  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  //Session
  private user: User = new User();

  //Time
  public now: Date = new Date();
  date;

  //submission all daten
  submissionData = [];
  fileRows = [];

  // Pagination
  p: number = 1;
  collection: any[] = this.fileRows;
  dataRefresher: any;

  //File upload new
  uploadForm: FormGroup;

  public uploader: FileUploader = new FileUploader({
    isHTML5: true
  });

  //Datepicker
  firstFileByCreateDate;
  fromDate = new FormControl(new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000), Validators.required);
  untilDate = new FormControl(new Date(), Validators.required);
  minDate = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
  maxDate = this.now;

  id: any;
  dateiFileSize;
  dateiFileName: string="";
  DENIAUFDAT;
  dateiSize;
  cenistatus: string = "NEW-FTP";
  DENIERSTELLDAT; 
  DENISTATUSDAT;
  CENIPFADID: string = "F";
  DENIDRUCKDAT;
  NENITYP: number = 0;
  NENIART: number;
  rechnung: boolean = false;
  gutschrift: boolean = false;

  NENIKONTROLLSUM :any;
  kommaformatieren: string;

  //NFKDID für ENI
  obligoSubmission = [];
  obligoSub;

  //Rollen
  userUpload: boolean = false;
  userUploadAndCanViewUpload: boolean = false;
  userCanUpload: boolean = false;

  //Load data before view
  ngAfterViewInit() {
    if (this.authService.isSessionExpired === false) {}
  }

  constructor(private fb: FormBuilder, private httpClient: HttpClient, private alertService: AlertService, private accountService: AccountService,
    private downloadService: DownloadService, private limitService: LimitService, private authService: AuthService, private router: Router,
    private obligoService: ObligoService, private dateiService: DateiService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  /*
  * GET all debtor accounts from web service 
  *
  */
  // load user data
  @Input()
  isGeneralEditor = false;

  ngOnInit() {    
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {       
      // load user data
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
      }

      //Datepicker
      this.date = new Date();

      this.uploadForm = this.fb.group({
        doku: [null, null],
        type: [null, Validators.compose([Validators.required])],
        grosse: ['']
      });

      if (this.user.nmndid == 0 || this.user.nmndid == null) {
        this.user.nmndid = this.obligoService.getNMNDID();
      }
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.uploadForm.controls[controlName].hasError(errorName);
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();
         
    if (this.canViewDateiDoku) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }    
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;

    if ((this.user.roles.find((r) => r === "upload")) === "upload") {
      this.userUpload = true;
    } else {
      this.userUpload = false;
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    ////this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,MessageSeverity.error, error);
    //console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();    
  }

  get canViewDateiDoku() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }
 
  rg(){
    this.rechnung = true;
  }

  gs(){
    this.gutschrift = true;
  }


  pruefeUserData =[];
  getUploadData = [];

  uploadSubmit({ value }: { value: Eni }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        ////user
        this.dateiService.getUserUpload()
          .subscribe(data => {
            this.pruefeUserData = data;
            //console.log("getUserUpload",this.pruefeUserData );
        });

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }
        }

        if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
          this.user.nfkdid = this.obligoService.getNFKDID();
          if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
            this.user.nfkdid = this.user.nfkdid;
          }
          this.user.nfkdid = this.user.nfkdid;
        }

        for (let i = 0; i < this.uploader.queue.length; i++) {
          const fileItem = this.uploader.queue[i]._file;
          this.dateiFileSize = fileItem.size;

          if (fileItem.size > 10000000) {
            this.alertService.showMessage("Die Datei überschreitet die Maximalgröße von 10 MB!", "", MessageSeverity.warn);
          } else {
            for (let j = 0; j < this.uploader.queue.length; j++) {
              const data = new FormData();
              const fileItem = this.uploader.queue[j]._file;

              this.dateiFileName = fileItem.name;

              data.append('file', fileItem);
              data.append('fileSeq', 'seq' + j);
              data.append('dataType', this.uploadForm.controls.type.value);

              this.dateiService.postUploadFile(this.user.nmndid, this.user.nfkdkdnr, data).subscribe(data=>data);                                       

              var anzahl = this.user.nmndid;
              var mndid = anzahl;
              var mn = anzahl.toString().length;

              this.DENIAUFDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.DENIERSTELLDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.DENIDRUCKDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.DENISTATUSDAT = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.dateiFileSize = this.dateiFileSize;                                     
              this.dateiService.getUserDateis().subscribe(data => data);
              this.dateiService.getNfkdidDatei(this.user.nmndid, this.user.nfkdkdnr).subscribe(data => {
                this.obligoSubmission = data;

                for (var i = 0; i < this.obligoSubmission.length; i++) {
                  this.obligoSub = this.obligoSubmission[i].nfkdid;                                 

                  if (this.rechnung) {
                    this.NENIART = 1;
                  } else {
                    this.NENIART = 2;
                  }

                  if (this.user.nmndid === null || this.user.nmndid === 0) {
                    this.alertService.showMessage("Mandant ist nicht vorhanden!", "", MessageSeverity.error);                       
                  }

                  if (this.user.nfkdkdnr === null || this.user.nfkdkdnr === 0) {
                    this.alertService.showMessage("Obligo ist nicht vorhanden!", "", MessageSeverity.error);
                  }

                  if (this.dateiFileName.endsWith(".pdf") || this.dateiFileName.endsWith(".csv") || this.dateiFileName.endsWith(".txt") ||
                      this.dateiFileName.endsWith(".xls") || this.dateiFileName.endsWith(".xlsx") || this.dateiFileName.endsWith(".zip") ||
                      this.dateiFileName.endsWith(".doc") || this.dateiFileName.endsWith(".docx") || this.dateiFileName.endsWith(".tif") ||
                      this.dateiFileName.endsWith(".jpg") || this.dateiFileName.endsWith(".png")) {
                 
                    if (anzahl == 1) {
                      this.dateiFileName = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFileName;
                    } else {
                      this.dateiFileName = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Einreichungen/" + this.dateiFileName;
                    }

                    this.NENIKONTROLLSUM = this.uploadForm.value.grosse;
                    this.kommaformatieren = this.NENIKONTROLLSUM.toString();
                    this.kommaformatieren = this.kommaformatieren.trim();
                    this.NENIKONTROLLSUM = this.kommaformatieren.replace(/,/g, '.');
        
                    value.NMNDID = this.user.nmndid;
                    value.NFKDID = this.user.nfkdid;                                          
                    value.NFKDID = this.user.nfkdid;
                    value.CENIDATEI = this.dateiFileName;
                    value.DENIAUFDAT = this.DENIAUFDAT;
                    value.CENISTATUS = this.cenistatus;
                    value.NENIFILESIZE = this.dateiFileSize;
                    value.DENIERSTELLDAT = this.DENIERSTELLDAT;
                    value.DENISTATUSDAT = this.DENISTATUSDAT;
                    value.CENIPFADID = this.CENIPFADID;
                    value.DENIDRUCKDAT = this.DENIDRUCKDAT;
                    value.NENITYP = this.NENITYP;
                    value.NENIART = this.NENIART;
                    value.NENIKONTROLLSUM = this.NENIKONTROLLSUM;

                    if (!isNumeric(value.NENIKONTROLLSUM) || value.NENIKONTROLLSUM === null || value.NENIKONTROLLSUM <= 0) {
                      this.alertService.showMessage("Die Gesamtsumme in EUR [0-9] muss angegeben werden!", "", MessageSeverity.error);
                    } else {                                            
                      this.dateiService.postCreationEniFile(value).subscribe(res => {
                        setTimeout(() => {
                          this.httpCode = res;

                          if (this.httpCode === 500) {
                            this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                          } else {
                            this.submissionData.push(res);
                            this.alertService.showMessage("Upload erfolgreich!", "", MessageSeverity.success);
                            this.ngOnInit();                                                    
                          }
                        }, 100);
                      });
                    }
                  } else {
                    this.alertService.showMessage("Dateien vom Typ (z.B. .exe, .bat, .php ...) dürfen nicht hochgeladen werden!", "", MessageSeverity.error);
                    return;
                  }
                }
              });                   
            }
          }
        }
        this.uploader.clearQueue();
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (err) {
      console.log(err);
    }
  }

  clear() {
    this.uploadForm.get('grosse').reset();
  }
}
