import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';

// alert
import { AlertService, MessageSeverity} from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { DownloadService } from '../../services/download.service';
import { ProgressStatus, ProgressStatusEnum } from '../../models/progress-status.model';
import { FormControl, Validators } from '@angular/forms';
// Datepicker date converter
import * as moment from 'moment';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ObligoService } from '../../services/obligo.service';
import { DateiService } from '../../services/datei.service';
import { PageEvent } from '../../../../node_modules/@angular/material/typings/esm5/paginator/index';
import { Datei } from '../../models/datei';
import { LimitService } from '../../services/limit.service';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-aob-component',
    templateUrl: './aob.component.html',
    styleUrls: ['./aob.component.css'],
    animations: [fadeInOut]
})

export class AobComponent implements AfterViewInit, OnInit {

    //Download
    public files;
    public fileInDownload: string;
    public percentage: number;
    public showProgress: boolean;
    public showDownloadError: boolean;
    public showUploadError: boolean;
    private expectedFolder: string = "AobFolder";

    fileRows = [];

  //Begrifflichkeit
  selectedStatusBegriffs = "Anlage Limitprüfungsgebühren";

  // Pagination
  p: number = 1;
  collection: any[] = this.fileRows;
  dataRefresher: any;
  public pageSilce;


  // table sorting
  key: string = "created";
  reverse = true;
  //search
  filter;

  // Multiselect
  itemList = [];
  selectedItems = [];
  settings = {};
  showNavigation: boolean;
  config: any;
  //
  httpCode: any;

  //Session
  private user: User = new User();

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  //Time
  public now: Date = new Date();
  date: any;

  //Datepicker
  firstFileByCreateDate;
  fromDate = new FormControl(new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000), Validators.required);
  untilDate = new FormControl(new Date(), Validators.required);
  minDate = new Date(1990, 1, 1);
  maxDate = new Date(this.now);
  id: any;

  // table sorting
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //Load data before view
  ngAfterViewInit() {
  }

  constructor(private alertService: AlertService, private accountService: AccountService, private downloadService: DownloadService,
    private authService: AuthService, private limitService: LimitService, private router: Router, private obligoService: ObligoService, private dateiService: DateiService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

   
  // load user data
  @Input()
  isGeneralEditor: boolean = false;


    ngOnInit() {
        //Datepicker
        this.date = new Date();
       
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  dateiFile;
  dateiFileName;
  dateFile;
  downloadData = [];
  datei: Datei = new Datei();
  geleseneFile: boolean = false;

  public fileDownload(fileName) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

       //user
        this.downloadService.getUserDownload().subscribe(data => data);
        this.dateiService.getUserDateis().subscribe(data => data);

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
            if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
              this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
            }
         }

        this.downloadService.downloadFiles(fileName, this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder)
          .subscribe((data) => {
            setTimeout(() => {
              const downloadURL = window.URL.createObjectURL(data);
              const link = document.createElement('a');
              link.href = downloadURL;
              link.download = fileName;
              link.click();
              this.user.nfkdid = this.user.nfkdid;

              if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
                this.user.nfkdid = this.obligoService.getNFKDID();
                if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
                  this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
                  this.user.nfkdid = this.user.nfkdid;
                }
                this.user.nfkdid = this.user.nfkdid;
              }

              this.user.nfkdid = this.user.nfkdid;
              this.dateFile = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
              this.dateiFile = link.download;
              this.dateiFileName = this.dateiFile;
              var anzahl = this.user.nmndid;         //1
              var mndid = anzahl.toString().length;  // "1" -->length => 1              

              if (mndid == 1) {
                this.dateiFile = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr  + "/Sonstiges/" + this.dateiFile;
              } else {
                this.dateiFile = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr  + "/Sonstiges/" + this.dateiFile;
              }

              this.datei.NMNDID = this.user.nmndid;
              this.datei.NFKDID = this.user.nfkdid;
              this.datei.CDATEI = this.dateiFile;
              this.datei.DAUFDAT = this.dateFile;
              this.datei.cname = this.dateiFileName;            

              this.dateiService.postDatei(this.datei).subscribe((res) => {
                setTimeout(() => {
                  this.httpCode = res;
                  this.geleseneFile = true;

                  if (this.httpCode === 500) {
                    this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                  } else {
                    this.downloadData.push(res);
                    this.alertService.showMessage("Herunterladen erfolgreich!", "", MessageSeverity.success);
                    this.ngOnInit();
                  }
                }, 200);
              });

            }, 500);
          });
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }

  }

    //Download
    public downloadStatus(event: ProgressStatus) {
        switch (event.status) {
            case ProgressStatusEnum.START:
                this.showDownloadError = false;
                break;
            case ProgressStatusEnum.IN_PROGRESS:
                this.showProgress = true;
                this.percentage = event.percentage;
                break;
            case ProgressStatusEnum.COMPLETE:
                this.showProgress = false;
                break;
            case ProgressStatusEnum.ERROR:
                this.showProgress = false;
                this.showDownloadError = true;
                break;
        }
    }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.getData();
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();    
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  pruefegeleseneDatum: boolean = false;

  getAllDateiFiles = [];
  nameTages = [];
  pruefeRead: boolean;
  dataFiles;

 //gibt alle Dateien zurück
  getData() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        //user
        this.downloadService.getUserDownload()
          .subscribe(data => data);
        
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }       

        if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
          this.user.nfkdid = this.obligoService.getNFKDID();
          if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
            this.user.nfkdid = this.user.nfkdid;
          }
          this.user.nfkdid = this.user.nfkdid;
        }

        //alle Dateien in Tagesabrechnungen
        this.downloadService.getFiles(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder).subscribe(data => {
              this.files = data;
      
              //alle gespeicherten Dateien
              this.dateiService.getSaveDateis(this.user.nmndid, this.user.nfkdid).subscribe(data => {
                      this.getAllDateiFiles = data;

                      // descending
                      this.getAllDateiFiles = this.getAllDateiFiles.sort((a, b) => b.daufdat - a.daufdat);

                      for (var i = 0; i < this.files.length; i++) {
                        for (var j = 0; j < this.getAllDateiFiles.length; j++) {
                          if (this.files[i].name == this.getAllDateiFiles[j].cname && this.user.nfkdid == this.getAllDateiFiles[j].nfkdid && this.user.nmndid == this.getAllDateiFiles[j].nmndid) {
                            this.files[i].read = this.getAllDateiFiles[j].daufdat;
                            break;
                          }
                        }
                      }
              });
        });

      }
    } catch (error) {
      console.log(error);
    } 
  }


  //Pagination
  allFileList: number;

  public onPageChange(event: PageEvent) {

    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;

    if (endIndex > this.files.length) { endIndex = this.files.length; }

    this.pageSilce = this.files.slice(startIndex, endIndex);
  }


  getFirstFileCreateDate() {
    try {
    
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        //user
        this.downloadService.getUserDownload()
          .subscribe(data => data);

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
            if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
              this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
            }
         }

        //Show first file by create date
        this.downloadService.getFirstFileByCreateDate(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder)
          .subscribe(data => {
            setTimeout(() => {
              this.firstFileByCreateDate = data;
            }, 100);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }



  downloadZip() {
    try {

      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        //user
        this.downloadService.getUserDownload().subscribe(data => data);
        this.dateiService.getUserDateis().subscribe(data => data);

        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
          if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
            this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
          }
        }

        this.downloadService.createZipFile(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder, moment(this.fromDate.value).format('YYYY-MM-DD'), moment(this.untilDate.value).format('YYYY-MM-DD'))
          .subscribe(data => {

            setTimeout(() => {

              data;

              if (this.user.nmndid === null || this.user.nmndid <= 0) {
                if (this.user.nfkdkdnr === null || this.user.nfkdkdnr <= 0) {
                  this.alertService.showMessage("Service zurzeit nicht verfügbar!!", "", MessageSeverity.warn);
                }

              } else {

                if (data.size !== 0 || data.size > 0) {
                  //
                  //console.log('download reminders files in zip from: ' + moment(this.fromDate.value).format('YYYY-MM-DD') + "\n" +
                  //  'download reminders files in zip until: ' + moment(this.untilDate.value).format('YYYY-MM-DD'));


                  return this.downloadService.createZipFile(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder, moment(this.fromDate.value).format('YYYY-MM-DD'), moment(this.untilDate.value).format('YYYY-MM-DD')).subscribe(
                    res => {
                      const url = window.URL.createObjectURL(res);
                      const a = document.createElement('a');
                      document.body.appendChild(a);
                      a.setAttribute('style', 'display: none');
                      a.href = url;
                      a.download = "Sonstiges_" + moment(this.fromDate.value).format('YYYY-MM-DD') + "_" + moment(this.untilDate.value).format('YYYY-MM-DD') + "_" + this.user.nfkdkdnr;
                      a.click();
                    }, error => {
                      /* console.log('download error:', JSON.stringify(error));*/
                    }, () => {
                      /* console.log('Completed file download.')*/
                      this.alertService.showMessage("Herunterladen Zip erfolgreich!", "", MessageSeverity.success);

                      this.downloadService.getFiles(this.user.nmndid, this.user.nfkdkdnr, this.expectedFolder)
                        .subscribe(result => {
                          this.files = result;

                          //VON

                          var from = Number(moment(this.fromDate.value).format('YYYYMMDD'));

                          //BIS

                          var until = Number(moment(this.untilDate.value).format('YYYYMMDD'));                          

                          for (var i = 0; i < this.files.length; i++) {

                            //DateiName
                            var nameDatei = this.files[i].name;                            

                            nameDatei = nameDatei.slice(0, 8);

                            if (!isNaN(Number(nameDatei))) {

                              nameDatei = Number(nameDatei);

                              if (from <= nameDatei && nameDatei <= until) {

                                this.user.nfkdid = this.user.nfkdid;

                                if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
                                  this.user.nfkdid = this.obligoService.getNFKDID();
                                  if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
                                    this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
                                    this.user.nfkdid = this.user.nfkdid;
                                  }
                                  this.user.nfkdid = this.user.nfkdid;
                                }

                                this.user.nfkdid = this.user.nfkdid;
                                this.dateFile = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
                                this.dateiFile = this.files[i].name;
                                this.dateiFileName = this.files[i].name;
                                var anzahl = this.user.nmndid;         //1
                                var mndid = anzahl.toString().length;  // "1" -->length => 1

                                if (mndid == 1) {
                                  this.dateiFile = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Sonstiges/" + this.dateiFile;
                                } else {
                                  this.dateiFile = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Sonstiges/" + this.dateiFile;
                                }

                                this.datei.NMNDID = this.user.nmndid;
                                this.datei.NFKDID = this.user.nfkdid;
                                this.datei.CDATEI = this.dateiFile;
                                this.datei.DAUFDAT = this.dateFile;
                                this.datei.cname = this.dateiFileName;

                                this.dateiService.postDatei(this.datei).subscribe((res) => {
                                  setTimeout(() => {
                                    this.httpCode = res;

                                    if (this.httpCode === 500) {
                                      this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                                    } else {
                                      this.downloadData.push(res);
                                    }
                                  }, 100);
                                });
                              }

                            } else {

                              //ErstellDatum
                              var erstellDatum = Number(moment(this.files[i].created).format('YYYYMMDD'));
                              
                              if (from <= erstellDatum && erstellDatum <= until) {

                                this.user.nfkdid = this.user.nfkdid;

                                if (this.user.nfkdid === 0 || this.user.nfkdid === null) {
                                  this.user.nfkdid = this.obligoService.getNFKDID();
                                  if (this.user.nfkdid == 0 || this.user.nfkdid == null) {
                                    this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidFirst"));
                                    this.user.nfkdid = this.user.nfkdid;
                                  }
                                  this.user.nfkdid = this.user.nfkdid;
                                }

                                this.user.nfkdid = this.user.nfkdid;
                                this.dateFile = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
                                this.dateiFile = this.files[i].name;
                                this.dateiFileName = this.files[i].name;
                                var anzahl = this.user.nmndid;         //1
                                var mndid = anzahl.toString().length;  // "1" -->length => 1

                                if (mndid == 1) {
                                  this.dateiFile = "./0" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Sonstiges/" + this.dateiFile;
                                } else {
                                  this.dateiFile = "./" + this.user.nmndid + "/" + this.user.nfkdkdnr + "/Sonstiges/" + this.dateiFile;
                                }

                                this.datei.NMNDID = this.user.nmndid;
                                this.datei.NFKDID = this.user.nfkdid;
                                this.datei.CDATEI = this.dateiFile;
                                this.datei.DAUFDAT = this.dateFile;
                                this.datei.cname = this.dateiFileName;

                                this.dateiService.postDatei(this.datei).subscribe((res) => {
                                  setTimeout(() => {
                                    this.httpCode = res;

                                    if (this.httpCode === 500) {
                                      this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                                    } else {
                                      this.downloadData.push(res);
                                    }
                                  }, 100);
                                });
                              }
                            }
                          }
                        });
                      this.ngOnInit();
                    });
                } else if (data.size === 0) {
                  this.alertService.showMessage("Keine Dateien vohanden um als Zip-Archiv heruntergeladen zu werden!", "", MessageSeverity.warn);
                }
              }
            }, 100);
          });

      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }
}
