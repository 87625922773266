

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DebtorOpenItem } from '../models/debtor-open-item.model';
import { DebtorOpenItemMainData } from '../models/debtor-open-item-main-data.model';
import { AuthService } from './auth.service';

import { throwError } from '../../../node_modules/rxjs/index';
import { catchError } from '../../../node_modules/rxjs/operators/index';
import { map } from '../../../node_modules/rxjs/src/operator/map';

@Injectable()
export class OpenItemsService {

  private readonly _baseUrl: string;
  private readonly _baseUrl2: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    this._baseUrl = 'api/debtor/open/items';
    this._baseUrl2 = 'api/debtor/open/item/main/data';
  }

  getOpenItems(nmndid, nrekkdnr, ndktid) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.http.get<DebtorOpenItem[]>(this._baseUrl + '?' + 'nmndid=' + nmndid + '&' + 'nrekkdnr=' + nrekkdnr + '&' + 'ndktid=' + ndktid, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }

  getOpenPositionMainData(nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.http.get<DebtorOpenItemMainData[]>(this._baseUrl2 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
}
