// Web Source: https://www.devglan.com/angular/angular-6-example

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NdktidAndNdktkdnr } from '../models/ndktid-and-ndktkdnr.model';
import { AuthService } from './auth.service';
import { ObligoService } from './obligo.service';
import { Obligo } from '../models/obligo.model';
import { debug } from 'console';
import { UserObligo } from '../models/user-obligo.model';
import { DebtorAccount } from '../models/debtor-account.model';

@Injectable()
export class DebtorAccountService {
  private readonly _baseUrl: string;
  private readonly _baseUrl2: string;
  private readonly _baseUrl3: string;
  private _nfkdkdnr: number;

  //user
  private readonly _baseUrlUser: string;

  constructor(private httpClient: HttpClient, private authService: AuthService, private oblogo: ObligoService) {
    this._baseUrl = 'api/debtor/accounts';
    this._baseUrl2 = 'api/debtor/ndktid/and/ndktkdnr';

    // obligo user
    this._baseUrlUser = 'api/debtor/userDeb';
  }

  getUserDeb() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<UserObligo[]>(this._baseUrlUser, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getDebtorAccounts(nmndid, nfkdkdnr) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      
      return this.httpClient.get<[DebtorAccount]>(this._baseUrl + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {headers: httpHeaders});      
    } catch (error) {
      console.log(error);
    }
  }

  getNdktidAndNdktkdnr(nmndid, nfkdkdnr) {
    try {
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
   
        return this.httpClient.get<[NdktidAndNdktkdnr]>(this._baseUrl2 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }
}
