// ====================================================
// Nicolas Fregin
// ====================================================
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchfilter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, fieldName: any[]): any[] {
    var returnArray = new Array();
    
    // return empty array if array is falsy
    if (!items) { return []; }

    // return the original array if search text is empty
    if (!searchText) { return items; }

    // convert the searchText to lower case
    searchText = searchText.toLowerCase();

     //return the filtered array      
    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      for (var j = 0; j < fieldName.length; j++) {
        if (item[fieldName[j]] != null && item[fieldName[j]] != "") {
          if (item[fieldName[j]].toString().toLowerCase().includes(searchText)) {
            returnArray.push(item);
            break;
          }
        }
      }
    }
    return returnArray;
  }
}
