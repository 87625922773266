import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'

// alert
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { ProgressStatus, ProgressStatusEnum } from '../../models/progress-status.model';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ObligoService } from '../../services/obligo.service';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ActiveObligo } from '../../models/active-obligo.model';
import { Meta } from '@angular/platform-browser';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-navi-info',
  templateUrl: './navi-info.component.html',
  styleUrls: ['./navi-info.component.css']
})
export class NaviInfoComponent implements OnInit {

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  //Session
  private user: User = new User();

  //Rolle Upload beim Einrichten
  userUpload: boolean = false;


  constructor(private httpClient: HttpClient, private alertService: AlertService, private accountService: AccountService,
    private authService: AuthService, private router: Router, private obligoService: ObligoService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  // load user data
  @Input()
  isGeneralEditor = false;

  ngOnInit() {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
      }

      if (this.user.nmndid == 0 || this.user.nmndid == null) {
        this.user.nmndid = this.obligoService.getNMNDID();
      }

    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }  
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    } else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }  
  }

  uRollenName: string;

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
 
    for (var i = 0; i < this.user.roles.length; i++) {
     /* console.log(this.user.roles[i])*/

      if (this.user.roles[i] === "upload") {
        this.userUpload = true;
        break;
      }
     /* console.log(this.userUpload);*/     
    }
   /* console.log("userUpload ---> ",this.userUpload);*/
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    //console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewUpload() {
    return this.accountService.userHasPermission(Permission.viewUploadPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }
}
