// ====================================================
// Davain Pablo Edwards
// 
// ====================================================

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ECrefo } from '../models/ecrefo.model';
import { AuthService } from './auth.service';

@Injectable()
export class ECrefoService {

  private readonly _baseUrl: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl = 'api/ecrefo';
  }

  getECrefoDecision(companyName, street, houseNumber, postCode, city, country) {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );
      return this.httpClient.get<ECrefo[]>(this._baseUrl + '?' + 'companyName=' + companyName + '&' + 'street=' + street + '&' + 'houseNumber=' + houseNumber + '&' + 'postCode=' + postCode + '&' + 'city=' + city + '&' + 'country=' + country, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }
}
