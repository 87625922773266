
import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';


import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { ActiveObligo } from '../../models/active-obligo.model';
import { EndpointFactory } from '../../services/endpoint-factory.service';
import { ContactService } from '../../services/contact.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';


import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from "../../services/account.service";
import { Utilities } from '../../services/utilities';
import { User } from '../../models/user.model';
import { UserEdit } from '../../models/user-edit.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { DateiService } from '../../services/datei.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
// Datepicker date converter
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
moment.locale('de');
registerLocaleData(localeDe, 'de');


@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
  animations: [fadeInOut]
})
export class UserInfoComponent implements OnInit {

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;

  //FactoryPassChange akt. User
  factoryPassChange: boolean = false;
  public formResetToggle = true;
  private isEditingSelf = false;
  private showValidationErrors = false;
  private isChangePassword = false;
  private editingUserName: string;
  private isEditMode = false;
  private uniqueId: string = Utilities.uniqueId();
  private isNewUser = false;
  private isSaving = false;

  //Time
  public now: Date = new Date();
  public datumDsgvo;
  validationNewPassAndCurrentPass: boolean = false;

  //Daten für die DSGVO Datum überschrieben
  dsgvoData = [];

  //Session
  private user: User = new User();
  private userEdit: UserEdit;
  private allRoles: Role[] = [];

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  isModal = false;

  @Input()
  isViewOnly: boolean;

  @Input()
  isGeneralEditor = false;

  @ViewChild('f')
  private form;

  //ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild('userName')
  private userName;

  @ViewChild('userPassword')
  private userPassword;

  @ViewChild('email')
  private email;

  @ViewChild('currentPassword')
  private currentPassword;

  @ViewChild('newPassword')
  private newPassword;

  @ViewChild('confirmPassword')
  private confirmPassword;

  @ViewChild('roles')
  private roles;

  @ViewChild('rolesSelector')
  private rolesSelector;


  constructor(private authService: AuthService, private router: Router, private alertService: AlertService, private accountService: AccountService, private dsgvoServise: ContactService) {
  }


  ngOnInit() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }

      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }

    } catch (error) {}

  }

  private loadCurrentUserData() {

    this.alertService.startLoadingMessage();
   
    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  /**
    * @param user
    * @param roles
    */
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {

    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;

 

    //if (!this.userEdit.isEnabled) {
    //  this.userEdit.changePasswordRequest = true;
    //}

    if (this.user.changePasswordRequest === true) {
      this.editUser(this.user, this.allRoles);  //zusätzlich gebaut vlt muss weg
      this.changePassword();
    } else {
      this.resetForm();
      this.user = user;
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Ladefehler", `Benutzerdaten können nicht vom Server abgerufen werden\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);

    this.user = new User();
  }

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }



  private save() {

    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {


        if (this.userEdit.newPassword === this.userEdit.currentPassword) {

          this.form.invalid = true;

          this.alertService.showMessage("Bitte beachten Sie!!!","Das neue Passwort sollte sich von das aktuellste Passwort unterscheiden!", MessageSeverity.warn);
          this.showValidationErrors = true;
          this.validationNewPassAndCurrentPass = true;
          return;

        } else {

          this.isSaving = true;

          this.userEdit.jobTitle = this.userEdit.newPassword;
         /* console.log("UserEdit.jobTitle---",this.userEdit.jobTitle);*/
          /*  this.userEdit.changePasswordRequest = false;*/
       
          this.dsgvoServise.getUserDsgvo(this.user.id, this.user.nmndid)
            .subscribe(data => {
              this.dsgvoData = data;

              if (this.dsgvoData.length > 0) {

                for (var i = 0; i < this.dsgvoData.length; i++) {
                  this.datumDsgvo = this.dsgvoData[i].dsgvoAccepted;
                  this.userEdit.dsgvoAccepted = this.datumDsgvo;   // DSGVO Datum überschrieben

                    if (!this.isModal) {
                      this.alertService.startLoadingMessage("Änderungen speichern...");
                    }

                    if (this.isNewUser) {
                      this.accountService.newUser(this.userEdit).subscribe(user => this.saveSuccessHelper(user), error => this.saveFailedHelper(error));
                    }
                    else {
                      this.accountService.updateUser(this.userEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
                    }
                }

              } else {
                this.alertService.showMessage("Keine Verbindung zur Datenbank vorhanden!", "Die Daten von der Tabelle AspNetUsers könne nicht gelesen werden", MessageSeverity.warn);
              }
            });

        }

      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  private saveSuccessHelper(user?: User) {

    this.testIsRoleUserCountChanged(this.user, this.userEdit);

    if (user)
      Object.assign(this.userEdit, user);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.isChangePassword = false;
    this.showValidationErrors = false;

    this.deletePasswordFromUser(this.userEdit);
    Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();
    this.resetForm();


    if (this.isGeneralEditor) {
      if (this.isNewUser)
        this.alertService.showMessage("Erfolg", `Benutzer \"${this.user.userName}\" wurde erfolgreich erstellt`, MessageSeverity.success);
      else if (!this.isEditingSelf)
        this.alertService.showMessage("Erfolg", `Änderungen am Benutzer \"${this.user.userName}\" wurde erfolgreich gespeichert`, MessageSeverity.success);
    }

    if (this.isEditingSelf) {
      this.alertService.showMessage("Sie haben Ihre Passwort erfolgreich geändert","", MessageSeverity.success);
      /*this.refreshLoggedInUser();*/
    }

    this.isEditMode = false;

    if (this.changesSavedCallback)
      this.changesSavedCallback();
  }

  private saveFailedHelper(error) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Fehler speichern", "Die folgenden Fehler sind beim Speichern Ihrer Änderungen aufgetreten:", MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback)
      this.changesFailedCallback();
  }









  private getRoleByName(name: string) {
    return this.allRoles.find((r) => r.name === name)
  }

  public deletePasswordFromUser(user: UserEdit | User) {
    const userEdit = <UserEdit>user;

    delete userEdit.currentPassword;
    delete userEdit.newPassword;
    delete userEdit.confirmPassword;
  }

  


  private refreshLoggedInUser() {

    this.accountService.refreshLoggedInUser()
      .subscribe(user => {
        this.loadCurrentUserData();
      },
        error => {
          this.alertService.resetStickyMessage();
          this.alertService.showStickyMessage("Aktualisierung fehlgeschlagen", "Beim Aktualisieren der angemeldeten Benutzerinformationen vom Server ist ein Fehler aufgetreten", MessageSeverity.error, error);
        });
  }

  private changePassword() {
    this.isChangePassword = true;
  }

  resetForm(replace = false) {

   /* this.isChangePassword = false;*/

    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      },500);
    }
  }


  newUser(allRoles: Role[]) {

    this.isGeneralEditor = true;
    this.isNewUser = true;

    this.allRoles = [...allRoles];
    this.editingUserName = null;
    this.user = this.userEdit = new UserEdit();
    this.userEdit.isEnabled = true;
    this.edit();

    return this.userEdit;
  }

  private edit() {

    if (!this.isGeneralEditor) {
      this.isEditingSelf = true;
      this.userEdit = new UserEdit();
      Object.assign(this.userEdit, this.user);
    }
    else {
      if (!this.userEdit)
        this.userEdit = new UserEdit();
      this.isEditingSelf = this.accountService.currentUser ? this.userEdit.id === this.accountService.currentUser.id : false;
    }

    this.isEditMode = true;
    this.showValidationErrors = true;
    this.isChangePassword = false;
  }

  private testIsRoleUserCountChanged(currentUser: User, editedUser: User) {

    const rolesAdded = this.isNewUser ? editedUser.roles : editedUser.roles.filter(role => currentUser.roles.indexOf(role) === -1);
    const rolesRemoved = this.isNewUser ? [] : currentUser.roles.filter(role => editedUser.roles.indexOf(role) === -1);

    const modifiedRoles = rolesAdded.concat(rolesRemoved);

    if (modifiedRoles.length)
      setTimeout(() => this.accountService.onRolesUserCountChanged(modifiedRoles));
  }

  private cancel() {
    if (this.isGeneralEditor)
      this.userEdit = this.user = new UserEdit();
    else
      this.userEdit = new UserEdit();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage("Abbrechen", "Vorgang vom Benutzer abgebrochen", MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor)
      this.isEditMode = false;

    if (this.changesCancelledCallback)
      this.changesCancelledCallback();
  }

  private close() {
    this.userEdit = this.user = new UserEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback)
      this.changesSavedCallback();
  }

  private unlockUser() {

    this.isSaving = true;
    this.alertService.startLoadingMessage("Benutzer entblockieren...");

    this.accountService.unblockUser(this.userEdit.id)
      .subscribe(response => {
        this.isSaving = false;
        this.userEdit.isLockedOut = false;

        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("Erfolgreich", "Benutzer wurde erfolgreich entsperrt", MessageSeverity.success);
      },
        error => {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Fehler beim Entsperren", "Die folgenden Fehler sind beim Entsperren des Benutzers aufgetreten:", MessageSeverity.error, error);
          this.alertService.showStickyMessage(error, null, MessageSeverity.error);
        });
  }

 


  editUser(user: User, allRoles: Role[]) {

    if (user) {
      this.isGeneralEditor = true;
      this.isNewUser = false;

      this.setRoles(user, allRoles);
      this.editingUserName = user.userName;
      this.user = new User();
      this.userEdit = new UserEdit();
      Object.assign(this.user, user);
      Object.assign(this.userEdit, user);

      this.edit();

      return this.userEdit;
    }
    else {
      return this.newUser(allRoles);
    }
  }

  displayUser(user: User, allRoles?: Role[]) {

    this.user = new User();
    Object.assign(this.user, user);
    this.deletePasswordFromUser(this.user);
    this.setRoles(user, allRoles);

    this.isEditMode = false;
  }

  private setRoles(user: User, allRoles?: Role[]) {

    this.allRoles = allRoles ? [...allRoles] : [];

    if (user.roles) {
      for (const ur of user.roles) {
        if (!this.allRoles.some(r => r.name === ur))
          this.allRoles.unshift(new Role(ur));
      }
    }

    if (allRoles === null || this.allRoles.length !== allRoles.length)
      setTimeout(() => this.rolesSelector.refresh());
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(Permission.assignRolesPermission);
  }
}
