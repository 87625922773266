
import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { FormGroup, FormControl } from '@angular/forms';
import { ComboBoxService } from '../../services/combobox-service';
import { LimitService } from '../../services/limit.service';
import { DKI } from '../../models/dki.model';
import { isNumeric } from 'rxjs/internal-compatibility';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';
import { ObligoService } from '../../services/obligo.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-account-change.component',
  templateUrl: './account-change.component.html',
  styleUrls: ['./account-change.component.css'],
  animations: [fadeInOut]
})
export class AccountChangeComponent implements OnInit {

    //Attribute for page rendering event if data is available 
    isDataAvailable: boolean = true;

    //Session
    private user: User = new User();

    //
    accountChange: FormGroup;

    //Obligo
    nfkdkdnr: number;

    //ComboBox lists
    genderDataRows = [];
    debtorGender: string;
    countryDataRows = [];
    vatIdDataRows = [];
    branchNumberDataRows = [];
    //
    accountChangeAndLimitChangeDataRows = [];

    CDKTNAME1: string;
    CDKTNAME2: string;
    CDKTSTR: string;
    CLNDKZ: string;
    CDKTPLZ: string;
    CDKTORT: string;
    NSMWSATZ: number;
    NSMWKEY: number;
    CVRBBEZ1: string;
    CVRBNR: string;
    NDKTZIEL3: string;
    NDKTSKONTO1: number;
    NDKTZIEL1: string;
    NDKTSKONTO2: number;
    NDKTZIEL2: string;
    CDKTBANK1: string;
    CDKTBKTONR1: string;
    CDKTBLZ1: string;
    CDKTFREMDNR: string;
    CDKANAME: string;
    CDKATELEFON: string;
    CDKAFAX: string;
    CDKAEMAIL: string;
    CDKTAUSLAND1: string;
    CDKTAUSLAND2: string;
    NDKTGESCHLECHT: string;
    CDKTGESCHLECHT: string;
    CDKTUSTIDNR: string;
    DDKTGEBURT: Date;
    NDKTLIMIT: number;
    NDKTLIMITWUN: number;
    CDKTTELEFON: string;
    CDKTFAX: string;
    CDKTEMAIL: string;
    CDKTBIC1: string;
    CDKTIBAN1: string;
    NSRFKEY:number;

    //Input value for debtor number in limit view
    NDKTDEBITNR: string = localStorage.getItem('NDKTDEBITNR');

    isMobile = null;

    constructor(private alertService: AlertService, private accountService: AccountService,
      private comboBoxService: ComboBoxService, private limitService: LimitService, private authService: AuthService, private router: Router,
      private deviceService: DeviceDetectorService, private obligoService: ObligoService, private meta: Meta) {
      this.checkDevice();
      this.meta.addTags([
        {name:'robots', content: 'noindex,follow'}
      ]);
    }

  checkDevice() {
    //console.log('Check current device:');
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    //console.log("Device info: " + deviceInfo);
    //console.log("Mobile device? " + this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log("Tablet device? " + isTablet);  // returns if the device us a tablet (iPad etc)
    //console.log("Dektop device? " + isDesktopDevice); // returns if the app is running on a Desktop browser.
  }


  public now: Date = new Date();
  ddkiaufdat = moment(this.now).format("YYYY-MM-DD HH:mm:ss.SSS");
    /*
     * GET all debtor accounts from web service 
     *
     */
    // load user data
  isGeneralEditor = false;
  httpCode: any;

  genderText;
  cvbnummer;
  ngOnInit() {
    try {
        if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
          
          // load user data
            if (!this.isGeneralEditor) {
              this.loadCurrentUserData();
            }

            //ComboBox gender
            this.comboBoxService.getGender()
              .subscribe(data => {
                setTimeout(() => {
                  this.genderDataRows = data;
                }, 500);
              });

            //ComboBox country
            this.comboBoxService.getCountry()
              .subscribe(data => {
                setTimeout(() => {
                  this.countryDataRows = data;
                }, 500);
              });

            //ComboBox vatid
            this.comboBoxService.getVatId()
              .subscribe(data => {
                setTimeout(() => {
                  this.vatIdDataRows = data;
                }, 500);
              });

            //ComboBoy branchnumber
            this.comboBoxService.getBranchNumber()
              .subscribe(data => {
                setTimeout(() => {
                  this.branchNumberDataRows = data;
                }, 500);
              });

        } else {
          this.authService.logout();
          this.router.navigateByUrl('/login');
        }

        this.accountChange = new FormGroup({
          CDKITELEFON: new FormControl(''),
          CDKIFAX: new FormControl(''),
          CDKIEMAIL: new FormControl(''),
          NDKIGESCHLECHT: new FormControl(''),
          CDKIUSTIDNR: new FormControl(''),
          DDKIGEBURT: new FormControl('01.01.1900'),
          NSMWKEY: new FormControl(''),
          NSRFKEY: new FormControl(''),
          CVRBNR: new FormControl(''),
          CDKIFREMDNR: new FormControl(''),
          CDKIBIC1: new FormControl(''),
          CDKIIBAN1: new FormControl(''),
          CDKIBANK1: new FormControl(''),
          NDKISKONTO1: new FormControl(''),
          NDKIZIEL1: new FormControl(''),
          NDKISKONTO2: new FormControl(''),
          NDKIZIEL2: new FormControl(''),
          NDKIZIEL3: new FormControl(''),
          CDKIBKTONR1: new FormControl(''),
          CDKTBIC1: new FormControl(''),
          CDKTIBAN1: new FormControl(''),
          CDKINAMEA: new FormControl(''),
          CDKITELEFONA: new FormControl(''),
          CDKIFAXA: new FormControl(''),
          CDKIEMAILA: new FormControl('')
        });
      
    } catch (error) {
      console.log(error);
    }
  }

  ndkisk1: any;
  ndkisk2: any;
  limitAdminRechte: boolean = false;

  onSubmit({ value }: { value: DKI }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        this.limitService.getUserLimit().subscribe(data => data);

        if (this.user.roles.find(element => element == 'adminFactor') == "adminFactor") {

          this.limitAdminRechte = true;
          this.alertService.showMessage("Sie verfügen nicht über genügend Rechte!", "", MessageSeverity.warn);

        } else {
          this.limitAdminRechte = false;
          if (isNumeric(value.NDKIZIEL3) && this.accountChange.controls.NDKIZIEL3.valid) {

            value.NMNDID = this.user.nmndid;

            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidL"));

            value.NFKDID = this.user.nfkdid;

            value.NDKIDEBITNR = Number(this.NDKTDEBITNR);
            value.CDKINAME1 = this.CDKTNAME1;
            value.CDKINAME2 = this.CDKTNAME2;
            value.CDKISTR = this.CDKTSTR;
            value.CLNDKZ = this.CLNDKZ;
            value.CDKIPLZ = this.CDKTPLZ;
            value.CDKIORT = this.CDKTORT;
            value.NDKIVORGANG = 2;

            //wenn die Daten leer gelassen sind weden die Daten von DKT ueberschrieben 

            if (value.CDKIEMAIL === '' || value.CDKIEMAIL === null) {
              value.CDKIEMAIL = this.CDKTEMAIL;
            }
            if (value.CDKIEMAILA === '' || value.CDKIEMAILA === null) {
              value.CDKIEMAILA = this.CDKAEMAIL;
            }
            if (value.NDKIZIEL1 === '' || value.NDKIZIEL1 === null) {
              value.NDKIZIEL1 = this.NDKTZIEL1
            }
            if (value.NDKISKONTO1 === null) {
              value.NDKISKONTO1 = this.NDKTSKONTO1;
              this.ndkisk1 = String(value.NDKISKONTO1).replace(/,/g, '.');
              this.ndkisk1 = Number(this.ndkisk1);
            }
            if (value.NDKIZIEL2 === '' || value.NDKIZIEL2 === null) {
              value.NDKIZIEL2 = this.NDKTZIEL2;
            }
            if (value.NDKISKONTO2 === null) {
              value.NDKISKONTO2 = this.NDKTSKONTO2;
              this.ndkisk2 = String(value.NDKISKONTO2).replace(/,/g, '.');
              this.ndkisk2 = Number(this.ndkisk2);
            }
            if (value.CDKINAMEA === '' || value.CDKINAMEA === null) {
              value.CDKINAMEA = this.CDKANAME;
            }
            if (value.CDKIBANK1 === '' || value.CDKIBANK1 === null) {
              value.CDKIBANK1 = this.CDKTBANK1;
            }
            if (value.CDKIBIC1 === '' || value.CDKIBIC1 === null) {
              value.CDKIBIC1 = this.CDKTBIC1;
            }
            if (value.CDKIIBAN1 === '' || value.CDKIIBAN1 === null) {
              value.CDKIIBAN1 = this.CDKTIBAN1;
            }
            if (value.NDKIGESCHLECHT === '' || value.NDKIGESCHLECHT === null) {
              value.NDKIGESCHLECHT = this.NDKTGESCHLECHT;
            }
            if (value.NSMWKEY === 0 || value.NSMWKEY === null) {
              value.NSMWKEY = this.NSMWKEY;
            }
            if (value.CVRBNR === '' || value.CVRBNR === null) {
              value.CVRBNR = this.CVRBNR;
            }
            if (value.CDKIUSTIDNR === '' || value.CDKIUSTIDNR === null) {
              value.CDKIUSTIDNR = this.CDKTUSTIDNR;
            }
            if (value.CDKIFREMDNR === '' || value.CDKIFREMDNR === null) {
              value.CDKIFREMDNR = this.CDKTFREMDNR;
            }


            this.ndkisk1 = String(value.NDKISKONTO1).replace(/,/g, '.');
            this.ndkisk2 = String(value.NDKISKONTO2).replace(/,/g, '.');
            this.ndkisk1 = Number(this.ndkisk1);
            this.ndkisk2 = Number(this.ndkisk2);
            value.NDKISKONTO1 = this.ndkisk1;
            value.NDKISKONTO2 = this.ndkisk2;
            value.NSMWKEY = this.NSMWKEY;
            value.NSRFKEY = this.NSRFKEY;
            value.DDKIAUFDAT = new Date();


            this.limitService.postAccountChangeData(value)
              .subscribe(res => {

                this.httpCode = res;

                if (this.httpCode === 500) {
                  this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);
                } else {

                  this.accountChangeAndLimitChangeDataRows.push(res);
                  this.navigateToTopIfMobileDevice();                  
                  setTimeout(() => {
                    this.router.navigateByUrl('/account-change-success');
                  }, 500);
                }
              }), err => {
                console.log("Error Occured " + err);
              }

          } else {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Das Nettoziel in Tagen [0-9] muss angegeben werden!", "", MessageSeverity.error);
          }

        }

      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
    sessionStorage.removeItem('nfkdkdnr');
    sessionStorage.removeItem('ndktdebitnr');
    }

    //Load user data
    private loadCurrentUserData() {
        this.alertService.startLoadingMessage();

        if (this.canViewAllRoles) {
            this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
        }
        else {
            this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
        }
    }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        this.limitService.getUserLimit().subscribe(data => data);

        this.alertService.stopLoadingMessage();
        this.user = user;

        this.user.nfkdkdnr = JSON.parse(localStorage.getItem("oblLimit"));
        this.nfkdkdnr = user.nfkdkdnr;
        
        //this.allRoles = roles;

        this.limitService.getAccountChangeAndLimitChangeData(this.user.nmndid, this.user.nfkdkdnr, this.NDKTDEBITNR)
          .subscribe(data => {
            setTimeout(() => {
              this.accountChangeAndLimitChangeDataRows = data;
         
           
              for (let i = 0; i < data.length; i++) {
                this.CDKTNAME1 = this.accountChangeAndLimitChangeDataRows[i].cdktnamE1;
                this.CDKTNAME2 = this.accountChangeAndLimitChangeDataRows[i].cdktnamE2;
                this.CDKTSTR = this.accountChangeAndLimitChangeDataRows[i].cdktstr;
                this.CLNDKZ = this.accountChangeAndLimitChangeDataRows[i].clndkz;
                this.CDKTPLZ = this.accountChangeAndLimitChangeDataRows[i].cdktplz;
                this.CDKTORT = this.accountChangeAndLimitChangeDataRows[i].cdktort;
                this.NSMWSATZ = this.accountChangeAndLimitChangeDataRows[i].nsmwsatz;
                this.NSMWKEY = this.accountChangeAndLimitChangeDataRows[i].nsmwkey;
                //console.log("NSMWKEY", this.NSMWKEY);
                this.CVRBBEZ1 = this.accountChangeAndLimitChangeDataRows[i].cvrbbeZ1;
                this.CVRBNR = this.accountChangeAndLimitChangeDataRows[i].cvrbnr;
                this.NDKTZIEL3 = this.accountChangeAndLimitChangeDataRows[i].ndktzieL3;
                this.NDKTSKONTO1 = this.accountChangeAndLimitChangeDataRows[i].ndktskontO1;
                this.NDKTZIEL1 = this.accountChangeAndLimitChangeDataRows[i].ndktzieL1;               
                this.NDKTSKONTO2 = this.accountChangeAndLimitChangeDataRows[i].ndktskontO2;
                this.NDKTZIEL2 = this.accountChangeAndLimitChangeDataRows[i].ndktzieL2;              
                this.CDKTBANK1 = this.accountChangeAndLimitChangeDataRows[i].cdktbanK1;
                this.CDKTBKTONR1 = this.accountChangeAndLimitChangeDataRows[i].cdktbktonR1;
                this.CDKTBLZ1 = this.accountChangeAndLimitChangeDataRows[i].cdktblZ1;
                this.CDKTFREMDNR = this.accountChangeAndLimitChangeDataRows[i].cdktfremdnr;
                this.CDKANAME = this.accountChangeAndLimitChangeDataRows[i].cdkaname;
                this.CDKATELEFON = this.accountChangeAndLimitChangeDataRows[i].cdkatelefon;
                this.CDKAFAX = this.accountChangeAndLimitChangeDataRows[i].cdkafax;
                this.CDKAEMAIL = this.accountChangeAndLimitChangeDataRows[i].cdkaemail;
                this.CDKTAUSLAND1 = this.accountChangeAndLimitChangeDataRows[i].cdktauslanD1;
                this.CDKTAUSLAND2 = this.accountChangeAndLimitChangeDataRows[i].cdktauslanD2;
                this.NDKTGESCHLECHT = this.accountChangeAndLimitChangeDataRows[i].ndktgeschlecht;
                this.CDKTGESCHLECHT = this.accountChangeAndLimitChangeDataRows[i].cdktgeschlecht;
                this.CDKTUSTIDNR = this.accountChangeAndLimitChangeDataRows[i].cdktustidnr;
                this.DDKTGEBURT = this.accountChangeAndLimitChangeDataRows[i].ddktgebur;
                this.NDKTLIMIT = this.accountChangeAndLimitChangeDataRows[i].ndktlimit;
                this.NDKTLIMITWUN = this.accountChangeAndLimitChangeDataRows[i].ndktlimitwun;
                this.CDKTTELEFON = this.accountChangeAndLimitChangeDataRows[i].cdkttelefon;
                this.CDKTFAX = this.accountChangeAndLimitChangeDataRows[i].cdktfax;
                this.CDKTEMAIL = this.accountChangeAndLimitChangeDataRows[i].cdktemail;
                this.CDKTBIC1 = this.accountChangeAndLimitChangeDataRows[i].cdktbiC1;
                this.CDKTIBAN1 = this.accountChangeAndLimitChangeDataRows[i].cdktibaN1;
                this.NSRFKEY = this.accountChangeAndLimitChangeDataRows[i].nsrfkey;
              }
            }, 500);
          });
      }
    } catch (error) {
      //console.log(error);
    }
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    //console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
   this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  private navigateToTopIfMobileDevice() {
    // if mobile device navigate automatically to top of page
    if (this.isMobile === true) {
      document.getElementById('top').scrollIntoView();
    }
  }

  private storeDataForContact() {
    sessionStorage.setItem('nfkdkdnr', "Obligo " + String(this.nfkdkdnr));
    sessionStorage.setItem('ndktdebitnr', ", Debitor " + String(this.NDKTDEBITNR));
  }
}
