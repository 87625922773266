// ====================================================
// Davain Pablo Edwards
// 
// ====================================================

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NrsigaData } from '../models/nrsiga-data.model';
import { AuthService } from './auth.service';


@Injectable()
export class NrsigaService {

  private readonly _baseUrl1: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._baseUrl1 = 'api/debtor/nrsiga/data';
  }

  getNrsigaData(nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<NrsigaData[]>(this._baseUrl1 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
}
