
import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
/*import { RecommendationService } from 'src/app/services/recommendation.service';*/
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Role } from '../../models/role.model';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
//Pagination
import * as _ from 'underscore';
import { NgxSpinnerService } from 'ngx-spinner';
import { NrsigaService } from '../../services/nrsiga.service';
import { fadeInOut } from '../../services/animations';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { Contact } from '../../models/contact.model';
import { Email } from '../../models/email.model';
import { ContactService } from '../../services/contact.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ObligoService } from '../../services/obligo.service';
import { RecommendationService } from '../../services/recommendation.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-recommendation-component',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.css'],
  animations: [fadeInOut]
})

export class RecommendationComponent implements AfterViewInit, OnInit {

  //Load data before view
  ngAfterViewInit(): void {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      this.onClickLoadData(1);
    }
  }

  private user: User = new User();
  recommendation: FormGroup;
  debtorRecommendationMainDataRows = [];
  debtorRecommendationRows = [];
  debtorRecommendation2Rows = [];
  recht: string;

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;
  debtorNdktid: number;
  debtorNdktkdnr: number;
  debtorNmndid: number;
  debtorCdktname1: string;
  debtorNdktdebitnr: number;
  debtorNrsiga: number;
  factorCfacname1: string;
  factorCfacname2: string;
  factorCfacstr: string;
  factorCfacplzort: string;
  factorCfactelefon: string;
  factorCfacfax: string;
  factorCfacemail: string;
  debtorCdktstr: string;
  debtorCdktplz: string;
  debtorCdktort: string;  
  debtorCfkdfax: string = "Keine";
  debtorCbrabez: string;
  debtorCfkdname: string;
  private readonly _baseUrl: string;

  constructor(private accountService: AccountService, private alertService: AlertService, private spinner: NgxSpinnerService,
    private recommendationService: RecommendationService, private nrsigaService: NrsigaService,
    private httpClient: HttpClient, private contactService: ContactService, private authService: AuthService,
    private router: Router, private obligoService: ObligoService, private meta: Meta) {
    this._baseUrl = 'api/pdfcreator';
    this.recht = "";
    this.debtorNrsiga = 2;
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  // load user data
  @Input()
  isGeneralEditor = false;

  ngOnInit() {
    this.recommendation = new FormGroup({
      contactPerson: new FormControl('Debitor'),
      contactPhone: new FormControl('-'),
      contactFax: new FormControl('-'),
      messageText: new FormControl('Test E-Mail (Empfehlung):' + "\n" + 'E-Mail Zuordnung von Zuständige Factoring-Gesellschaft fehlt noch.' + "\n" + "Kontaktfragebogen.pdf Anhang fehlt noch."),
      fromAddress: new FormControl('support@se-vertrieb.de'),
      subject: new FormControl('Kontaktfragebogen Test'),
    });

    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      // load user data
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
      }       

      this.debtorRecommendationMainDataRows = JSON.parse(localStorage.getItem('selectedOptions'));

      //Page rendering event if data is available 
      if (this.debtorRecommendationMainDataRows.length !== 0) {
        this.isDataAvailable = true;
      } else if (this.debtorRecommendationMainDataRows.length === 0) {
          this.alertService.showMessage("Bitte wählen Sie einen Debitor im Fenster Debitorenkonten aus!", "", MessageSeverity.warn);

          //Load spinner
          this.spinner.show();          
        }
      } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
      }
  }

  onClickLoadData(paginationPoistion) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

    for (var i = 0; i < this.debtorRecommendationMainDataRows.length; i++) {

      this.debtorNdktid = this.debtorRecommendationMainDataRows[paginationPoistion-1].ndktid;
      this.debtorNdktkdnr = this.debtorRecommendationMainDataRows[paginationPoistion-1].ndktkdnr;
      this.debtorNmndid = this.debtorRecommendationMainDataRows[paginationPoistion-1].nmndid;

      if (this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktnamE1 === null) {
        this.debtorCdktname1 = "";
      } else {
        this.debtorCdktname1 = this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktnamE1;
      }

      this.debtorNdktdebitnr = this.debtorRecommendationMainDataRows[paginationPoistion-1].ndktdebitnr;

      if (this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktstr === null) {
        this.debtorCdktstr = "";
      } else {
        this.debtorCdktstr = this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktstr;
      }

      if (this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktplz === null) {
        this.debtorCdktplz = "";
      } else {
        this.debtorCdktplz = this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktplz;
      }

      if (this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktort === null) {
        this.debtorCdktort = "";
      } else {
        this.debtorCdktort = this.debtorRecommendationMainDataRows[paginationPoistion-1].cdktort;
      }
    }
    //console.log("pagination position " + paginationPoistion);
    //console.log("NDKTID: " + this.debtorNdktid);
    //console.log("NDKTKDNR: " + this.debtorNdktkdnr);
    //console.log("NMNDID: " + this.debtorNmndid);
    //console.log("CDKTNAME1: " + this.debtorCdktname1);
    //console.log("NDKTDEBITNR: " + this.debtorNdktdebitnr);
    //console.log("---------------------");

      this.getNrsiga();

    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
    } catch (error) {
      console.log(error);
    }
  }

  //Spinner
  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
  }

  getNrsiga() {
    try {   
      this.nrsigaService.getNrsigaData(this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr)
        .subscribe(data => {
            for (var i = 0; i < data.length; i++) {
              this.debtorNrsiga = data[i].nrsiga;
            }
            //console.log("NRSIGA: " + this.debtorNrsiga);
            //console.log("---------------------");            
        });

        // Webservice calls
        if (this.debtorNrsiga > 0) {
          this.recommendationService.getRecommendationDebtor(this.debtorNrsiga, this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr)
            .subscribe(data => {
              this.debtorRecommendationRows = data;
             /* console.log('DebtorRecommendationRows--> ',this.debtorRecommendationRows);*/
            });
        }

        //
        if (this.debtorNrsiga > 0) {
          this.recommendationService.getRecommendation2Debtor(this.recht, this.debtorNrsiga, this.debtorNmndid, this.debtorNdktid, this.debtorNdktkdnr)
            .subscribe(data => {
              this.debtorRecommendation2Rows = data;              

              for (var i = 0; i < this.debtorRecommendation2Rows.length; i++) {
                //NAME1
                if (this.debtorRecommendation2Rows[0].targetCFACNAME1 === null) {
                  this.factorCfacname1 = this.debtorRecommendation2Rows[0].thisCFACNAME1;
                } else {
                  this.factorCfacname1 = this.debtorRecommendation2Rows[0].targetCFACNAME1;
                }       

                //NAME2
                if (this.debtorRecommendation2Rows[0].targetCFACNAME2 === null) {
                  this.factorCfacname2 = this.debtorRecommendation2Rows[0].thisCFACNAME2;
                } else {
                  this.factorCfacname2 = this.debtorRecommendation2Rows[0].targetCFACNAME2;
                }

                //STR
                if (this.debtorRecommendation2Rows[0].targetCFACSTR === null) {
                  this.factorCfacstr = this.debtorRecommendation2Rows[0].thisCFACSTR;
                } else {
                  this.factorCfacstr = this.debtorRecommendation2Rows[0].targetCFACSTR;
                }

                //PLZORT
                if (this.debtorRecommendation2Rows[0].targetCFACPLZORT === null) {
                  this.factorCfacplzort = this.debtorRecommendation2Rows[0].thisCFACPLZORT;
                } else {
                  this.factorCfacplzort = this.debtorRecommendation2Rows[0].targetCFACPLZORT;
                }

                //TELEFON
                if (this.debtorRecommendation2Rows[0].targetCFACTELEFON === null) {
                  this.factorCfactelefon = this.debtorRecommendation2Rows[0].thisCFACTELEFON;
                } else {
                  this.factorCfactelefon = this.debtorRecommendation2Rows[0].targetCFACTELEFON;
                }

                //FAX
                if (this.debtorRecommendation2Rows[0].targetCFACFAX === null) {
                  this.factorCfacfax = this.debtorRecommendation2Rows[0].thisCFACFAX;
                } else {
                  this.factorCfacfax = this.debtorRecommendation2Rows[0].targetCFACFAX;
                }

                //E-MAIL
                if (this.debtorRecommendation2Rows[0].targetCFACEMAIL === null) {
                  this.factorCfacemail = this.debtorRecommendation2Rows[0].thisCFACEMAIL;
                } else {
                  this.factorCfacemail = this.debtorRecommendation2Rows[0].targetCFACEMAIL;
                }                

                if (this.debtorRecommendation2Rows[0].cbrabez === null) {
                  this.debtorCbrabez = "";
                } else {
                  this.debtorCbrabez = this.debtorRecommendation2Rows[0].cbrabez;
                }

                if (this.debtorRecommendation2Rows[0].cfkdname === null) {
                  this.debtorCfkdname = "";
                } else {
                  this.debtorCfkdname = this.debtorRecommendation2Rows[0].cfkdname;
                }
              }

              //console.log("TELEFON TEST: " + this.debtorCfactelefon);
              //console.log("FAX TEST: " + this.debtorCfkdfax);
              //console.log("EMAIL TEST: " + this.debtorCfacemail);
              //console.log("BRANCHE TEST: " + this.debtorCbrabez);
              //console.log("RECOMMEND TEST: " + this.debtorCfkdname);
            });
        }   
    } catch (error) {
      console.log(error);
    }
  }

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  /**
   * 
   * @param user
   * @param roles
   */
  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    //this.allRoles = roles;


  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    //this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,MessageSeverity.error, error);
    //console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewRecommendationManagement() {
    return this.accountService.userHasPermission(Permission.viewRecommendationManagementPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }


  // Radio button 
  selectedOption: string = '';
  selection: any = [
    'downloadpdf',
    'sendpdf'
  ];

  radioChangeHandler(event: any) {
    this.selectedOption = event.target.value;
  }

  onSubmit({ value }: { value: Contact }) {
    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
      if (this.selectedOption !== '') {
        switch (this.selectedOption) {
          case "downloadpdf": {
            this.downloadPdf();
            /*console.log("downlaod pdf");*/
            break;
          }
          case "sendpdf": {
            this.sendEmailToResponsibleCooperative({ value });
           /* console.log("send pdf per mail");*/
            break;
          }
          default: {
            /*console.log("Invalid choice");*/
            break;
          }
        }
      } else {
        this.alertService.showMessage("Bitte wählen Sie eine Option aus.", "", MessageSeverity.warn);
        }
    } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
    }
  }


  private readonly _responsibleCooperativeEmail: string = "de@se-vertrieb.de";

  sendEmailToResponsibleCooperative({ value }: { value: Contact }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        if (this.accountService.userHasPermission(Permission.viewRecommendationManagementPermission)) {

          //console.log("--------------");
          //console.log('Ansprechpartner:' + ' ' + value.contactPerson + '\n' +
          //  'Telefon: ' + ' ' + value.contactPhone + '\n' +
          //  'Fax: ' + ' ' + value.contactFax + '\n' +
          //  'Mitteilung: ' + ' ' + value.messageText + '\n' +
          //  'von E-Mail: ' + ' ' + value.fromAddress + '\n' +
          //  'an E-Mail: ' + ' ' + this._responsibleCooperativeEmail + '\n' +
          //  'Betreff: ' + ' ' + value.subject
          //);

          const email: Email = {
            contactPerson: value.contactPerson,
            contactPhone: value.contactPhone,
            contactFax: value.contactFax,
            messageText: value.messageText,
            fromAddress: value.fromAddress,
            toAddress: this._responsibleCooperativeEmail,
            //attachment: response,  https://stackoverflow.com/questions/52032438/sending-blob-files-to-server
            subject: value.subject
          };

          this.contactService.createFactorContactEmail(email)
            .subscribe(
              success => console.log("Done"),
              error => console.log(error)
            );

          this.alertService.showMessage("Die Test E-Mail wurde an " + this._responsibleCooperativeEmail + " übermittelt.", "", MessageSeverity.success);
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  downloadPdf() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        this.user.nfkdkdnr = this.obligoService.getNFKDKDNR();
        if (this.user.nfkdkdnr == 0 || this.user.nfkdkdnr == null) {
          this.user.nfkdkdnr = JSON.parse(localStorage.getItem("obl"));
        }     

        const mediaType = 'application/pdf';
        const httpHeaders = new HttpHeaders()
            .set('Authorization', 'Bearer ' +
              this.authService.accessToken
            );

          //console.log("debtorCdktname1 -->", this.debtorCdktname1);
          //console.log("debtorCdktstr -->", this.debtorCdktstr);
          //console.log("debtorCdktplz -->", this.debtorCdktplz);
          //console.log("debtorCbrabez -->", this.debtorCbrabez);
          //console.log("debtorCfkdname -->", this.debtorCfkdname);
          //console.log("factorCfactelefon -->", this.factorCfactelefon);
          //console.log("debtorCfkdfax -->", this.debtorCfkdfax);
          //console.log("factorCfacemail -->", this.factorCfacemail);         
       
          return this.httpClient.get(this._baseUrl + '?' + 'companyAndAddress=' + this.debtorCdktname1 + " " + this.debtorCdktstr + " " + this.debtorCdktplz + '&' + 'industry=' + this.debtorCbrabez + '&' + 'recommend=' + this.debtorCfkdname + '&' + 'phoneNumber=' + this.factorCfactelefon + '&' + 'faxNumber=' + this.debtorCfkdfax + '&' + 'emailAddress=' + this.factorCfacemail, { headers: httpHeaders, responseType: 'blob' }).subscribe(
            (response) => {
              const blob = new Blob([response], { type: mediaType });
              saveAs(blob, 'KurzfragebogenFactoring.pdf');
            }
            ,
            error => {
              console.log(error);
            }
          );
        } else {
          this.authService.logout();
          this.router.navigateByUrl('/login');
        }
    } catch (error) {
      console.log(error);
    }
  }
}
