// ====================================================
// Davain Pablo Edwards
// 
// ====================================================

import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class NotificationEndpoint {
  private demoNotifications = [
    {
      "id": 1,
      "header": "TestNotification#1",
      "body": "20 new \"Documents\" were added to your inbox by \"administrator\" on 9/11/2018 4:54:13 PM",
      "isRead": true,
      "isPinned": true,
      "date": "2018-11-09T09:39:13.5877958"
    },
    {
      "id": 2,
      "header": "TestNotification#2",
      "body": "10 new \"Invoices\" were added to your inbox by \"administrator\" on 9/11/2018 4:54:13 PM",
      "isRead": false,
      "isPinned": false,
      "date": "2018-09-09T09:44:42.4144502"
    },
    {
      "id": 3,
      "header": "TestNotification#3",
      "body": "Test Test Test Test message incoming...",
      "isRead": false,
      "isPinned": false,
      "date": "2018-11-09T09:39:42.4144502"
    }
  ];

  getNotificationEndpoint<T>(notificationId: number): Observable<T> {
    const notification = this.demoNotifications.find(val => val.id === notificationId);
    let response: HttpResponse<T>;

    if (notification) {
      response = this.createResponse<T>(notification, 200);
    } else {
      response = this.createResponse<T>(null, 404);
    }
    return of(response.body);
  }

  getNotificationsEndpoint<T>(page: number, pageSize: number): Observable<T> {
    const notifications = this.demoNotifications;
    const response = this.createResponse<T>(this.demoNotifications, 200);

    return of(response.body);
  }

  getUnreadNotificationsEndpoint<T>(userId?: string): Observable<T> {
    const unreadNotifications = this.demoNotifications.filter(val => !val.isRead);
    const response = this.createResponse<T>(unreadNotifications, 200);

    return of(response.body);
  }

  getNewNotificationsEndpoint<T>(lastNotificationDate?: Date): Observable<T> {
    const unreadNotifications = this.demoNotifications;
    const response = this.createResponse<T>(unreadNotifications, 200);

    return of(response.body);
  }

  getPinUnpinNotificationEndpoint<T>(notificationId: number, isPinned?: boolean, ): Observable<T> {
    const notification = this.demoNotifications.find(val => val.id === notificationId);
    let response: HttpResponse<T>;

    if (notification) {
      response = this.createResponse<T>(null, 204);

      if (isPinned === null)
        isPinned = !notification.isPinned;

      notification.isPinned = isPinned;
      notification.isRead = true;
    } else {
      response = this.createResponse<T>(null, 404);
    }
    return of(response.body);
  }



  getReadUnreadNotificationEndpoint<T>(notificationIds: number[], isRead: boolean, ): Observable<T> {
    for (const notificationId of notificationIds) {
      const notification = this.demoNotifications.find(val => val.id === notificationId);

      if (notification) {
        notification.isRead = isRead;
      }
    }
    const response = this.createResponse<T>(null, 204);
    return of(response.body);
  }

  getDeleteNotificationEndpoint<T>(notificationId: number): Observable<T> {
    const notification = this.demoNotifications.find(val => val.id === notificationId);
    let response: HttpResponse<T>;

    if (notification) {
      this.demoNotifications = this.demoNotifications.filter(val => val.id !== notificationId)
      response = this.createResponse<T>(notification, 200);
    } else {
      response = this.createResponse<T>(null, 404);
    }
    return of(response.body);
  }

  private createResponse<T>(body, status: number) {
    return new HttpResponse<T>({ body: body, status: status });
  }
}
