import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from "../../directives/bootstrap-tab.directive";
import { AccountService } from "../../services/account.service";
import { Permission } from '../../models/permission.model';
import { AuthService } from '../../services/auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  animations: [fadeInOut]
})

export class SettingsComponent implements OnInit, OnDestroy {
  isProfileActivated = true;
  isPreferencesActivated = false;
  isUsersActivated = false;
  isRolesActivated = false;
  fragmentSubscription: any;

  readonly profileTab = "profile";
  readonly preferencesTab = "preferences";
  readonly usersTab = "users";
  readonly rolesTab = "roles";

  @ViewChild("tab")
  tab: BootstrapTabDirective;

  constructor(private route: ActivatedRoute, private accountService: AccountService, private authService: AuthService, private router: Router, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  adminRolesUserAndRillenChange: boolean = false;
  ngOnInit() {
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
      if ((this.authService.currentUser.roles.find((r) => r === "admin")) === "admin") {
        this.adminRolesUserAndRillenChange = true;
      } else {
        this.adminRolesUserAndRillenChange = false;
      }
 
    if (this.authService.isSessionExpired === true && this.authService.isLoggedIn === true) {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  ngOnDestroy() {
    this.fragmentSubscription.unsubscribe();
  }

  showContent(anchor: string) {
    if ((this.isFragmentEquals(anchor, this.usersTab) && !this.canViewUsers) ||
      (this.isFragmentEquals(anchor, this.rolesTab) && !this.canViewRoles))
      return;

    this.tab.show(`#${anchor || this.profileTab}Tab`);
  }

  isFragmentEquals(fragment1: string, fragment2: string) {
    if (fragment1 == null)
      fragment1 = "";

    if (fragment2 == null)
      fragment2 = "";

    return fragment1.toLowerCase() === fragment2.toLowerCase();
  }

  onShowTab(event) {
    const activeTab = event.target.hash.split("#", 2).pop();
    this.isProfileActivated = activeTab === this.profileTab;
    this.isPreferencesActivated = activeTab === this.preferencesTab;
    this.isUsersActivated = activeTab === this.usersTab;
    this.isRolesActivated = activeTab === this.rolesTab;
  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }
}
