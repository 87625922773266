import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { Utilities } from '../../services/utilities';
// alert
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { ContactService } from '../../services/contact.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Dsgvo } from '../../models/dsgvo';
import * as moment from 'moment';
import { UserEdit } from '../../models/user-edit.model';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})

export class DatenschutzComponent implements OnInit {

  dsgvoRows = [];
  dsgvoAcceptedData: Date;
  checked = false;
  changetext = true; //muss false sein 
  dsgvoAcceptedCreation: FormGroup;

  // load user data
  @Input()
  isGeneralEditor = false;

  //Session
  private user: User = new User();
  private userEdit: UserEdit;

  constructor(private httpClient: HttpClient, private alertService: AlertService, private accountService: AccountService, private authService: AuthService,
    private router: Router, private dsgvoService: ContactService, private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  ngOnInit() {
    try {

    if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

      // load user data
      if (!this.isGeneralEditor) {
        this.loadCurrentUserData();
      }
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    }
    } catch (error) {
      console.log(error);
    }
  }

  
  sendValues(dsgvoAcceptedData: Date) {
    this.dsgvoAcceptedData = dsgvoAcceptedData;
  }

  //Time
  public now: Date = new Date();
  dsgvoaendat = this.now;

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;

    this.dsgvoService.getDsgvo(this.user.nmndid).subscribe(data => {
        setTimeout(() => {
          this.dsgvoRows = data;

          if (this.dsgvoRows.length == 0) {
            this.alertService.showMessage("Keine Verbindung zur Datenbank vorhanden!", "Die Daten von der Tabelle FAC können nicht gelesen werden", MessageSeverity.warn);
          }

        }, 100);
      });
  }

  selectedCheckbox() {

    if (this.checked) {
      localStorage.setItem("dsgvo", JSON.stringify(this.checked));
      JSON.parse(localStorage.getItem("dsgvo"));
    }
      //wenn change da sind 
     else {
      this.router.navigateByUrl('/login');
    }
    this.alertService.startLoadingMessage("DSGVO akzeptiert...");
  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, MessageSeverity.error, error);
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }
}
