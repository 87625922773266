import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable} from "rxjs";
import { AuthService } from './auth.service';
import { ObligoService } from './obligo.service';
import { UserObligo } from '../models/user-obligo.model';


@Injectable()
export class DownloadService {
  private _baseApiUrl: string;
  private _apiDownloadUrl: string;
  private _apiFileUrl: string;
  private _apiCreateZipFileUrl: string;
  private _apiGetFirstFileByCreatedDateUrl: string;

  //user
  private readonly _baseUrlUser: string;

  constructor(private httpClient: HttpClient, private authService: AuthService, private obligoService: ObligoService) {
    this._baseApiUrl = '/api/down/';
    this._apiDownloadUrl = this._baseApiUrl + 'download';
    this._apiFileUrl = this._baseApiUrl + 'show/files';
    this._apiCreateZipFileUrl = this._baseApiUrl + 'zip';
    this._apiGetFirstFileByCreatedDateUrl = this._baseApiUrl + 'show/first/file/by/create/date';

    // factor user
    this._baseUrlUser = this._baseApiUrl + 'userDownload';
  }

  getUserDownload() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<UserObligo[]>(this._baseUrlUser, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  public getFiles(nmndid, nfkdkdnr, expectedFolder) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);      

      return this.httpClient.get(this._apiFileUrl + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'expectedFolder=' + expectedFolder, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  public createZipFile(nmndid, nfkdkdnr, expectedFolder, from, until) {
    try {
      const httpHeaders = new HttpHeaders().append('Content-Type', 'application/zip').set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get(this._apiCreateZipFileUrl + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'expectedFolder=' + expectedFolder + '&' + 'from=' + from + '&' + 'until=' + until, {
        headers: httpHeaders,
        responseType: 'blob',
        observe: 'body'
      });
    } catch (error) {
      console.log(error);
    }
  }

  public downloadFiles(file: string, nmndid: number, nfkdkdnr: number, expectedFolder: string): Observable<Blob> {
    try {

      file = file.split('&').join("%26");
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get(this._apiDownloadUrl + '?' + 'file=' + file + '&' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'expectedFolder=' + expectedFolder,
        {
          responseType: 'blob',
          headers: httpHeaders
        },
      );
    } catch (error) {
      console.log(error);
    }
  }

  public getFirstFileByCreateDate(nmndid, nfkdkdnr, expectedFolder) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<string>(this._apiGetFirstFileByCreatedDateUrl + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr + '&' + 'expectedFolder=' + expectedFolder, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
}
