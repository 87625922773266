import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Datei } from '../models/datei';
import { Obligosub } from '../models/obligosub';
import { Eni } from '../models/eni';
import { EniMaxGruppenId } from '../models/enimaxgruppenid.model';
import { UserObligo } from '../models/user-obligo.model';

@Injectable()
export class DateiService {
  private readonly _apiDatei: string;
  private readonly _apiEni: string;
  private readonly _apiGetEni: string;
  private readonly _apiGetGruppenIdMax: string;
  private readonly _apiNFKDID: string;
  private readonly _apiUploadF: string;
  private readonly _apiDatei2: string;
  //user
  private readonly _baseUrlUser: string;
  private readonly _baseUrlUserDatei: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this._apiDatei = 'api/datei/save';
    this._apiEni = 'api/datei/saveEniFile';
    this._apiGetEni = 'api/datei/getEni';
    this._apiGetGruppenIdMax = 'api/datei/getEniGruppenIdMax';
    this._apiNFKDID = 'api/datei/getNfkdidDatei';
    this._baseUrlUserDatei = 'api/datei/userDateis';
    this._apiDatei2 = 'api/datei/dateis';
    this._apiUploadF = "api/upload/submissions";
    this._baseUrlUser = 'api/upload/userUpload';
  }

  getSaveDateis(nmndid, nfkdid) {
    try {
      const httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<Datei[]>(this._apiDatei2 + '?' + 'nmndid=' + nmndid + '&' + 'nfkdid=' + nfkdid, {headers: httpOptions});
    } catch (error) {
      console.log(error);
    }
  }

  getUserDateis() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<UserObligo[]>(this._baseUrlUserDatei, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getUserUpload() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.get<UserObligo[]>(this._baseUrlUser, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  getNfkdidDatei(nmndid, nfkdkdnr) {
    try {
      const httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<Obligosub[]>(this._apiNFKDID + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, {headers: httpOptions});
    } catch (error) {
      console.log(error);
    }
  }

  getEni(nmndid, nfkdid) {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<Eni[]>(this._apiGetEni + '?' + 'nmndid=' + nmndid + '&' + 'nfkdid=' + nfkdid, { headers: httpHeaders });
    } catch (error) {
      console.log(error);
    }
  }

  getEniGruppenIdMax() {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.get<EniMaxGruppenId[]>(this._apiGetGruppenIdMax, { headers: httpHeaders });
    } catch (error) {
      console.log(error);
    }
  }

  postDatei(datei: Datei) {
    try {
      const body = {
        NMNDID: datei.NMNDID,
        NFKDID: datei.NFKDID,
        CDATEI: datei.CDATEI,
        DAUFDAT: datei.DAUFDAT,
        CNAME: datei.cname
      }

      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.post<Datei>(this._apiDatei, body, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  postUploadFile(nmndid, nfkdkdnr, data: FormData): Observable<any> {
    try {
      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);

      return this.httpClient.post<any>(this._apiUploadF + '?' + 'nmndid=' + nmndid + '&' + 'nfkdkdnr=' + nfkdkdnr, data, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }

  postCreationEniFile(eni: Eni) {
    try {
      const body = {
        NMNDID: eni.NMNDID,
        NFKDID: eni.NFKDID,
        CENIDATEI: eni.CENIDATEI,
        DENIAUFDAT: eni.DENIAUFDAT,
        CENISTATUS: eni.CENISTATUS,
        NENIFILESIZE: eni.NENIFILESIZE,
        DENIERSTELLDAT: eni.DENIERSTELLDAT,
        DENISTATUSDAT: eni.DENISTATUSDAT,
        CENIPFADID: eni.CENIPFADID,
        DENIDRUCKDAT: eni.DENIDRUCKDAT,
        NENITYP: eni.NENITYP,
        NENIART: eni.NENIART,
        NENIKONTROLLSUM: eni.NENIKONTROLLSUM,
        NENIGRUPPENID: eni.NENIGRUPPENID
      }

      const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      return this.httpClient.post<Eni>(this._apiEni, body, {headers: httpHeaders});
    } catch (error) {
      console.log(error);
    }
  }
}
