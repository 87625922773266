
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { RoleEditorComponent } from "./role-editor.component";


@Component({
  selector: 'roles-management',
  templateUrl: './roles-management.component.html',
  styleUrls: ['./roles-management.component.css']
})
export class RolesManagementComponent implements OnInit, AfterViewInit {
  columns: any[] = [];
  rows: Role[] = [];
  rowsCache: Role[] = [];
  allPermissions: Permission[] = [];
  editedRole: Role;
  sourceRole: Role;
  editingRoleName: { name: string };
  loadingIndicator: boolean;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal')
  editorModal: ModalDirective;

  @ViewChild('roleEditor')
  roleEditor: RoleEditorComponent;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService) {
  }


  ngOnInit() {

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: "index", name: '#', width: 50, cellTemplate: this.indexTemplate, canAutoResize: false },
      { prop: 'name', name: gT('roles.management.Name'), width: 200 },
      { prop: 'description', name: gT('roles.management.Description'), width: 350 },
      { prop: 'usersCount', name: gT('roles.management.Users'), width: 80 },
      { name: '', width: 130, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
    ];

    this.loadData();
  }

  ngAfterViewInit() {

    this.roleEditor.changesSavedCallback = () => {
      this.addNewRoleToList();
      this.editorModal.hide();
    };

    this.roleEditor.changesCancelledCallback = () => {
      this.editedRole = null;
      this.sourceRole = null;
      this.editorModal.hide();
    };
  }

  addNewRoleToList() {
    if (this.sourceRole) {
      Object.assign(this.sourceRole, this.editedRole);

      let sourceIndex = this.rowsCache.indexOf(this.sourceRole, 0);
      if (sourceIndex > -1)
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);

      sourceIndex = this.rows.indexOf(this.sourceRole, 0);
      if (sourceIndex > -1)
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);

      this.editedRole = null;
      this.sourceRole = null;
    }
    else {
      const role = new Role();
      Object.assign(role, this.editedRole);
      this.editedRole = null;

      let maxIndex = 0;
      for (let r of this.rowsCache) {
        if ((<any>r).index > maxIndex)
          maxIndex = (<any>r).index;
      }

      (<any>role).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, role);
      this.rows.splice(0, 0, role);
      this.rows = [...this.rows];
    }
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.accountService.getRolesAndPermissions()
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const roles = results[0];
        const permissions = results[1];

        roles.forEach((role, index, roles) => {
          (<any>role).index = index + 1;
        });

        this.rowsCache = [...roles];
        this.rows = roles;

        this.allPermissions = permissions;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage("Ladefehler", `Rollen können nicht vom Server abgerufen werden.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.name, r.description));
  }

  onEditorModalHidden() {
    this.editingRoleName = null;
    this.roleEditor.resetForm(true);
  }

  newRole() {
    this.editingRoleName = null;
    this.sourceRole = null;
    this.editedRole = this.roleEditor.newRole(this.allPermissions);
    this.editorModal.show();
  }

  editRole(row: Role) {
    this.editingRoleName = { name: row.name };
    this.sourceRole = row;
    this.editedRole = this.roleEditor.editRole(row, this.allPermissions);
    this.editorModal.show();
  }

  deleteRole(row: Role) {
    this.alertService.showDialog('Möchten Sie die wirklich die Rolle \"' + row.name + '\" löschen?', DialogType.confirm, () => this.deleteRoleHelper(row));
  }

  deleteRoleHelper(row: Role) {

    this.alertService.startLoadingMessage("Löschen...");
    this.loadingIndicator = true;

    this.accountService.deleteRole(row)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row)
        this.rows = this.rows.filter(item => item !== row)
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage("Fehler löschen", `Beim Löschen der Rolle ist ein Fehler aufgetreten.\r\nFehler: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });

    this.onEditorModalHidden();

    this.roleEditor.changesCancelledCallback = () => {
      this.editedRole = null;
      this.sourceRole = null;
      this.editorModal.hide();
    };

  }

  get canManageRoles() {
    return this.accountService.userHasPermission(Permission.manageRolesPermission)
  }

}
