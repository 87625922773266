
export class AccountChangeAndLimitChange {
    CDKTNAME1: string;
    CDKTNAME2: string;
    CDKTSTR: string;
    CLNDKZ: string;
    CDKTPLZ: string;
    CDKTORT: string;
    NSMWSATZ: number;
    NSMWKEY: number;
    CVRBBEZ1: string;
    CVRBNR: string;
    NDKTZIEL3: number;
    NDKTSKONTO1: number;
    NDKTZIEL1: number;
    NDKTSKONTO2: number;
    NDKTZIEL2: number;
    CDKTBANK1: string;
    CDKTBKTONR1: string;
    CDKTBLZ1: string;
    CDKTFREMDNR: string;
    CDKANAME: string;
    CDKATELEFON: string; 
    CDKAFAX: string;
    CDKAEMAIL: string;
    CDKTAUSLAND1: string;
    CDKTAUSLAND2: string;
    NDKTGESCHLECHT: number;
    CDKTGESCHLECHT: string;
    CDKTUSTIDNR: string;
    DDKTGEBURT: Date;
    NDKTLIMIT: number;
    NDKTLIMITWUN: number;
    CDKTTELEFON: string;
    CDKTFAX: string;
    CDKTEMAIL: string;
    CDKTBIC1: string; 
    CDKTIBAN1: string;
    NSRFKEY: number;
}
