import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { DebtorInvoiceList  } from '../models/debtor-invoice-list.model';
import { DebtorPayment } from '../models/debtor-payment.model';
import { DebtorInvoiceListMainData } from '../models/debtor-invoice-list-main-data.model';
import { DebtorFilterDateData } from '../models/debtor-filter-date-data.model';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { AlertService, MessageSeverity } from './alert.service';
import { DebtorInvoiceBua } from '../models/debtor-invoice-bua.model';
import { DebtorInvoicePn } from '../models/debtor-invoice-pn';

@Injectable()
export class InvoiceListService {
  private readonly _baseUrl: string;
  private readonly _baseUrl2: string;
  private readonly _baseUrl3: string;
  private readonly _baseUrl4: string;
  private readonly _baseUrl5: string;
  private readonly _baseUrl6: string;
  private readonly _baseUrl7: string;
  private readonly _baseUrl8: string;
  private readonly _baseUrl9: string;


  constructor(private httpClient: HttpClient, private authService: AuthService, private alertService: AlertService) {
    this._baseUrl = 'api/debtor/invoice/list/data';
    this._baseUrl2 = 'api/debtor/payment/data';
    this._baseUrl3  = 'api/debtor/invoice/main/data';
    this._baseUrl4 = 'api/debtor/week/filter';
    this._baseUrl5 = 'api/debtor/from/until/filter';
    this._baseUrl6 = 'api/debtor/only/open/invoice/filter';
    this._baseUrl7 = 'api/debtor/plusmunus/filter';
    this._baseUrl8 = 'api/debtor/invoice/bua';
    this._baseUrl9 = 'api/debtor/invoice/pn';

  }



 getDebtorInvoicePn(nmndid, ndktkdnr, ndktdebitnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
      return this.httpClient.get<DebtorInvoicePn[]>(this._baseUrl9 + '?' + 'nmndid=' + nmndid + '&' + 'ndktkdnr=' + ndktkdnr + '&' + 'ndktdebitnr=' + ndktdebitnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }

 getDebtorInvoiceBua(nmndid, ndktid) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
      return this.httpClient.get<DebtorInvoiceBua[]>(this._baseUrl8 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }


  getDebtorInvoiceLists(nmndid, ndktid, nrekkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<DebtorInvoiceList[]>(this._baseUrl + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'nrekkdnr=' + nrekkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }

  getDebtorPaymentDatas(nrekid, nmndid) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<DebtorPayment[]>(this._baseUrl2 + '?' + 'nrekid=' + nrekid + '&' + 'nmndid=' + nmndid, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }

  getDebtorInvoiceMainData(nmndid, ndktid, ndktkdnr) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<DebtorInvoiceListMainData[]>(this._baseUrl3 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'ndktkdnr=' + ndktkdnr, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
 
  // Filter
  getDebtorWeekFilter(nmndid, ndktid, weekNum) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<DebtorFilterDateData[]>(this._baseUrl4 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'weekNum=' + weekNum, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }



  // Filter +/-5%
  getDebtorPlusMunusFuenfFilter(nmndid, ndktid, fuenfProcent) {
    try {
      const httpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' +
          this.authService.accessToken
        );
      return this.httpClient.get<DebtorFilterDateData[]>(this._baseUrl7 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'fuenfProcent=' + fuenfProcent, {
        headers: httpHeaders
      });
    } catch (error) {
      console.log(error);
    }
  }




  getDebtorFromUntilDateFilter(nmndid, ndktid, fromDate, untilDate) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
      );

   

        return this.httpClient.get<DebtorFilterDateData[]>(this._baseUrl5 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid + '&' + 'from=' + fromDate + '&' + 'until=' + untilDate, {
          headers: httpHeaders
        });

    } catch (error) {
      console.log(error);
    }
    }

  getDebtorOnlyOpenInvoicesFilter(nmndid, ndktid) {
    try {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        return this.httpClient.get<DebtorFilterDateData[]>(this._baseUrl6 + '?' + 'nmndid=' + nmndid + '&' + 'ndktid=' + ndktid, {
          headers: httpHeaders
        });
    } catch (error) {
      console.log(error);
    }
  }
}
