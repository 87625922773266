
import { Component, OnInit, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { SmartSignUpService } from '../../services/smart-sing-up.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, finalize, debounceTime, tap } from 'rxjs/operators';
import { ComboBoxService } from '../../services/combobox-service';
import { DKI } from '../../models/dki.model';
import { LimitService } from '../../services/limit.service';
import { isNumeric } from 'rxjs/internal-compatibility';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ECrefoService } from '../../services/ecrefo-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { data, trim } from 'jquery';
import { ActiveObligo } from '../../models/active-obligo.model';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-account-creation.component',
  templateUrl: './account-creation.component.html',
  styleUrls: ['./account-creation.component.css'],
  animations: [fadeInOut]
})
export class AccountCreationComponent implements OnInit {

  //Attribute for page rendering event if data is available 
  isDataAvailable = false;
  isDataAvailable2 = false;

  //Session
  private user: User = new User();

  accountCreation: FormGroup;

  //SmartSignUp
  smartSignUpDataRows = [];

  //jqx
  filterable = true;

  //Autocomplete
  searchCompaniesCtrl = new FormControl();
  filteredCompanies = [];
  isLoading = false;
  errorMsg: string;

  // firmenwissen
  companyCrefoNumber: string;
  companyName: string;
  companyBusinessName: string;
  companyCountry: string;
  companyState: string;
  companyPostCode: string;
  companyCity: string;
  companyStreetHousenumber: string;

  //Obligo
  nfkdkdnr: number;

  //ComboBox lists
  rechtsformDataRows = [];
  genderDataRows = [];
  debtorGender: string;
  countryDataRows = [];
  vatIdDataRows = [];
  branchNumberDataRows = [];
  CLNDKZ: string;
  NSMWKEY: number;
  CVRBNR: string;
  NDKIGESCHLECHT: string; 



  //Rechtsform
  NSRFKEY: number;
  module: string;
  accountCreationDataRows = []; // dummy
  httpCode: any;
  validationError: any;
  technicalError: any;

  //Input value for debtor number in limit view
  NDKTDEBITNR: string = localStorage.getItem('NDKTDEBITNR');
  mitgliedsNummerVorhanden;
  isMobile = null;
  ndkisk1: any;
  ndkisk2: any;

  //Time
  public now: Date = new Date();

  constructor(private alertService: AlertService, private accountService: AccountService,
    private smartSignUpService: SmartSignUpService, private comboBoxService: ComboBoxService, private httpClient: HttpClient,
    private limitService: LimitService, private authService: AuthService, private router: Router, private eCrefoService: ECrefoService,
    private deviceService: DeviceDetectorService, private meta: Meta) {
    this.CLNDKZ = "DE";
    this.NSMWKEY = 0;
    this.NSRFKEY = 0;
    this.companyName = null;
    this.nfkdkdnr = JSON.parse(localStorage.getItem("oblLimit"));
    this.mitgliedsNummerVorhanden = JSON.parse(localStorage.getItem("mitgliedsNummer"));
    this.checkDevice();
    this.meta.addTags([
      { name: 'robots', content: 'noindex,follow' }
    ]);
  }

  checkDevice() {
    //console.log('Check current device:');
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    //console.log("Device info: " + deviceInfo);
    //console.log("Mobile device? " + this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log("Tablet device? " + isTablet);  // returns if the device us a tablet (iPad etc)
    //console.log("Dektop device? " + isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  /*
   * GET all debtor accounts from web service 
   *
   */
  // load user data
  @Input()
  isGeneralEditor = false

  ngOnInit(): void {
    try {

      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        // load user data
        if (!this.isGeneralEditor) {
          this.loadCurrentUserData();
        }
        this.nfkdkdnr = JSON.parse(localStorage.getItem("oblLimit"));

        //Rechtsform
        this.comboBoxService.getRechtsform()
          .subscribe(data => {
            setTimeout(() => {
              this.rechtsformDataRows = data;
            }, 500);
          });

        //ComboBox gender
        this.comboBoxService.getGender()
          .subscribe(data => {
            setTimeout(() => {
              this.genderDataRows = data;
            }, 500);
          });

        //ComboBox country
        this.comboBoxService.getCountry()
          .subscribe(data => {
            setTimeout(() => {
              this.countryDataRows = data;
            }, 500);
          });

        //ComboBox vatid
        this.comboBoxService.getVatId()
          .subscribe(data => {
            setTimeout(() => {
              this.vatIdDataRows = data;
            }, 500);
          });

        //ComboBoy branchnumber
        this.comboBoxService.getBranchNumber()
          .subscribe(data => {
            setTimeout(() => {
              this.branchNumberDataRows = data;
            }, 500);
          });


        if (this.mitgliedsNummerVorhanden === true) {

          if (this.accountService.userHasPermission(Permission.viewECrefoPermission)) {

            this.alertService.showMessage("Sie verwenden das Modul eCrefo.", "", MessageSeverity.info);
            this.module = "(eCrefo)";

          }
        }

        //if (!this.accountService.userHasPermission(Permission.viewECrefoPermission)) {
        //  this.alertService.showMessage("Sie verwenden die normale Kontoeinrichtung.", "", MessageSeverity.info);
        //  this.module = "(Standard)";
        //}


        if (!this.accountService.userHasPermission(Permission.viewSmartSignUpPermission) && !this.accountService.userHasPermission(Permission.viewECrefoPermission)) {

          this.alertService.showMessage("Sie verwenden die normale Kontoeinrichtung.", "", MessageSeverity.info);
          this.module = "(Standard)";
        }



        if (this.accountService.userHasPermission(Permission.viewSmartSignUpPermission)) {

          this.alertService.showMessage("Sie verwenden das Modul Smart Sign-Up.", "", MessageSeverity.info);
          this.module = "(Smart Sign-Up)";

          this.checkResponse();

          //Autocomplete
          this.searchCompaniesCtrl.valueChanges
            .pipe(
              debounceTime(200),
              tap(() => {
                this.errorMsg = "";
                this.filteredCompanies = [];
                this.isLoading = true;
              }),

              switchMap(
                value => this.smartSignUpService.getSmartSignUp(value, "")
                  .pipe(
                    finalize(() => {
                      this.isLoading = false
                    }),
                  )
              )
            )
            .subscribe(data => {
              setTimeout(() => {
                this.httpCode = data;
                this.smartSignUpDataRows = Array.of(this.smartSignUpDataRows);

                if (this.httpCode === 503) {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("Service zurzeit nicht verfügbar!", "", MessageSeverity.warn);

                } else if (this.httpCode === 500) {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("Servicefehler!", "", MessageSeverity.warn);

                } else {

                  this.smartSignUpDataRows = data; this.smartSignUpDataRows = Array.of(this.smartSignUpDataRows);

                  this.smartSignUpDataRows = data;

                }

                //console.log('Debitorensuche: ', this.httpCode);
                if (this.smartSignUpDataRows !== null) {
                  this.isDataAvailable = true;
                  this.isDataAvailable2 = true;
                }
             }, 500);
            });
        }

      } else {
        this.router.navigateByUrl('/login');
        this.authService.logout();
      }
      //
      this.accountCreation = new FormGroup({
        NDKILIMITWUN: new FormControl('', Validators.required),
        CDKINAME1: new FormControl('', Validators.required),
        CDKINAME2: new FormControl(''),
        CDKISTR: new FormControl('', Validators.required),
        houseNumber: new FormControl('', Validators.required),
        CDKIPLZ: new FormControl('', Validators.required),
        CDKIORT: new FormControl('', Validators.required),
        CDKIAUSLAND1: new FormControl(''),
        CDKIAUSLAND2: new FormControl(''),
        CDKITELEFON: new FormControl(''),
        CDKIFAX: new FormControl(''),
        CDKIEMAIL: new FormControl(''),
        NDKIGESCHLECHT: new FormControl(''),
        CDKIUSTIDNR: new FormControl(''),

        DDKIGEBURT: new FormControl('01.01.1900'),

        CLNDKZ: new FormControl(this.CLNDKZ),
        NSMWKEY: new FormControl(this.NSMWKEY),
        CVRBNR: new FormControl(''),
    /*    NSRFKEY: new FormControl(this.NSRFKEY, Validators.required),*/
        NSRFKEY: new FormControl('', Validators.required),
        CDKIFREMDNR: new FormControl(''),
        CDKIBANK1: new FormControl(''),
        NDKISKONTO1: new FormControl(''),
        NDKIZIEL1: new FormControl(''),
        NDKISKONTO2: new FormControl(''),
        NDKIZIEL2: new FormControl(''),
        NDKIZIEL3: new FormControl('', Validators.required),
        CDKIBKTONR1: new FormControl(''),
        CDKIBIC1: new FormControl(''),
        CDKIIBAN1: new FormControl(''),
        CDKINAMEA: new FormControl(''),
        CDKITELEFONA: new FormControl(''),
        CDKIFAXA: new FormControl(''),
        CDKIEMAILA: new FormControl('')
      });

    } catch (error) {
      //console.log(error);
    }
  }

  checkResponse() {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {
        const httpHeaders = new HttpHeaders()
          .set('Authorization', 'Bearer ' +
            this.authService.accessToken
          );
        this.httpClient.get('api/smartsignup/company', { observe: 'response', headers: httpHeaders })
          .subscribe(response => console.log(response.status));
      }
    } catch (error) {
      console.log(error);
    }
  }

  //------------------------------------------------------------------------------------------------------------------------------- Smart Sign-Up Submit BEGIN

  onSmartSignUpSubmit({ value }: { value: DKI }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        //console.log("-------------------------------------");
        //console.log("Smart Sign-Up:");
        //console.log(
        //  'Limitwunsch:' + ' ' + value.NDKILIMITWUN + "\n" +
        //  'Crefonnummer: ' + this.companyCrefoNumber + "\n" +
        //  'Name 1: ' + this.companyName + "\n" +
        //  'Name 2: ' + this.companyBusinessName + "\n" +
        //  'Straße: ' + this.companyStreetHousenumber + "\n" +
        //  'Plz: ' + this.companyPostCode + "\n" +
        //  'Ort: ' + this.companyCity + "\n" +
        //  'Land: ' + this.companyCountry + "\n" +
        //  'zus&auml;tzl. Adressfeld 1 (max. 40 Z.): ' + value.CDKIAUSLAND1 + "\n" +
        //  'zus&auml;tzl. Adressfeld 1 (max. 40 Z.): ' + value.CDKIAUSLAND2 + "\n" +
        //  'Telefon-Nr.: ' + value.CDKITELEFON + "\n" +
        //  'Fax-Nr.: ' + value.CDKIFAX + "\n" +
        //  'E-Mail: ' + value.CDKIEMAIL + "\n" +
        //  'Geschlecht: ' + value.NDKIGESCHLECHT + "\n" +
        //  'USt-Ident-Nr.: ' + value.CDKIUSTIDNR + "\n" +
        //  'Geburtsdatum: ' + value.DDKIGEBURT + "\n" +
        //  'USt. Schl&uuml;ssel: ' + value.NSMWKEY + "\n" +
        //  'Mitglied-/Filial-/Fanchisenr.: ' + value.CDKIFREMDNR + "\n" +
        //  'Verband-Nr: ' + value.CVRBNR + "\n" +
        //  'Nettoziel in Tagen: ' + value.CDKIBANK1 + "\n" +
        //  'Skonto 1 = x% bei xTg: ' + value.NDKISKONTO1 + "\n" +
        //  '% bei: ' + value.NDKIZIEL1 + "\n" +
        //  'Skonto 1 = x% bei xTg: ' + value.NDKISKONTO2 + "\n" +
        //  '% bei: ' + value.NDKIZIEL2 + "\n" +
        //  'Name und Ort der Hausbank: ' + value.CDKIBKTONR1 + "\n" +
        //  'BIC: ' + value.CDKIBIC1 + "\n" +
        //  'IBAN: ' + value.CDKIIBAN1 + "\n" +
        //  'Name: ' + value.CDKINAMEA + "\n" +
        //  'Telefon-Nr.: ' + value.CDKITELEFONA + "\n" +
        //  'Fax-Nr.: ' + value.CDKIFAXA + "\n" +
        //  'E-Mail: ' + value.CDKIEMAILA
        //);


        if (this.companyName === null || this.companyName === "" && this.companyCrefoNumber === null || this.companyCrefoNumber === "") {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Die Debitorenkonto Suche muss ausgeführt werden!", "", MessageSeverity.error);

        }
        if ((!isNumeric(value.NDKILIMITWUN))) {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Der Limitwunsch in EUR [0-9] muss angegeben werden!", "", MessageSeverity.error);

        }
        else if (!isNumeric(value.NDKIZIEL1)) {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Das Nettoziel in Tagen [0-9] muss angegeben werden!", "", MessageSeverity.error);

        }
        else {

          value.NMNDID = this.user.nmndid;
          value.NFKDID = this.nfkdkdnr;
          value.NDKIDEBITNR = Number(this.NDKTDEBITNR);
          value.CDKINAME1 = this.companyName;
          value.CrefoNumber = Number(this.companyCrefoNumber);
          value.CDKINAME2 = this.companyBusinessName;
          value.CLNDKZ = this.CLNDKZ;
          value.CDKIPLZ = this.companyPostCode;
          value.CDKIORT = this.companyCity;
          value.CDKISTR = this.companyStreetHousenumber;
          value.CVRBNR = this.CVRBNR;

          value.NSRFKEY = this.NSRFKEY;

          value.NDKIGESCHLECHT = this.NDKIGESCHLECHT;

          this.limitService.postAccountCreationData(value)
            .subscribe(res => {

              this.httpCode = res;

              if (this.httpCode === 500) {

                this.navigateToTopIfMobileDevice();
                this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);

              } else {

                //this.accountCreationDataRows.push(res);
                this.navigateToTopIfMobileDevice();
                this.alertService.showMessage("Die Kontoeinrichtung wurde erfolgreich erstellt und wird von den zust&auml;ndigen Debitormanagern zeitnah gepr&uuml;ft.", "", MessageSeverity.success);
                this.router.navigateByUrl('/account-creation-success');
              }

            });
        }

      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * firmenwissen
   * @param crefonummer
   * @param identnummer
   * @param name
   * @param handelsName
   * @param land
   * @param bundesland
   * @param plz
   * @param ort
   * @param strasseHausnummer
   */
  sendValues(crefonummer: string,
    name: string,
    handelsName: string,
    land: string,
    bundesland: string,
    plz: string,
    ort: string,
    strasseHausnummer: string
    //event
  ) {

    this.companyCrefoNumber = crefonummer;
    this.companyName = name;
    this.companyBusinessName = handelsName;
    this.companyCountry = land;
    this.companyState = bundesland;
    this.companyPostCode = plz;
    this.companyCity = ort;
    this.companyStreetHousenumber = strasseHausnummer;

    //if (event.key === "Enter") {
    //   alert(event);
    //}


    //if (crefonummer != null) {
    //    this.isDataAvailable = true;
    //} else if (crefonummer == null) {
    //    this.isDataAvailable = false;
    //}
  }

  //public keypressed;

  //@HostListener('window:keydown', ['$event'])
  //handleKeyboardEvent(event: KeyboardEvent) {
  //    this.keypressed = event.keyCode;



  //    if (this.keypressed == 13) {
  //        this.companyCrefoNumber = '123';
  //        this.companyIdentificationNumber = '0123';
  //        this.companyName = 'name';
  //        this.companyBusinessName = 'business name';
  //        this.companyCountry = 'country';
  //        this.companyState = 'state';
  //        this.companyPostCode = 'post code';
  //        this.companyCity = 'city';
  //        this.companyStreetHousenumber = 'street housenumber';
  //    }

  //}

  //values = '';
  //onKey(event: any) { // without type info
  //    //this.values += event.target.value + ' | ';
  //    this.values += event.target.value;

  //    this.companyIdentificationNumber = '';
  //    this.companyName += this.values;
  //    this.companyBusinessName += this.values;
  //    this.companyCountry = '';
  //    this.companyState = '';
  //    this.companyPostCode = '';
  //    this.companyCity = '';
  //    this.companyStreetHousenumber = ''

  //}

  clearSearch() {
    this.companyCrefoNumber = '';
    //this.companyName = name;
    this.companyName = '';
    this.companyBusinessName = '';
    this.companyCountry = '';
    this.companyState = '';
    this.companyPostCode = '';
    this.companyCity = '';
    this.companyStreetHousenumber = '';


    this.isDataAvailable = false;
    this.isDataAvailable2 = false;

  }

  //------------------------------------------------------------------------------------------------------------------------------- Smart Sign-Up Submit END

  //------------------------------------------------------------------------------------------------------------------------------- Account Creation Submit BEGIN

  limitAdminRechte: boolean = false;
  onAccountCreationSubmit({ value }: { value: DKI }) {
    try {

      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        this.limitService.getUserLimit().subscribe(data => data);


        if (this.user.roles.find(element => element == 'adminFactor') == "adminFactor") {

          this.limitAdminRechte = true;
          this.alertService.showMessage("Sie verfügen nicht über genügend Rechte!", "", MessageSeverity.warn);


        } else {

          this.limitAdminRechte = false;

          value.CDKIORT = trim(value.CDKIORT);
          value.CDKINAME1 = trim(value.CDKINAME1);
          value.CDKINAME2 = trim(value.CDKINAME2);
          value.CDKISTR = trim(value.CDKISTR);
          value.CDKIPLZ = trim(value.CDKIPLZ);


          if ((!isNumeric(value.NDKILIMITWUN))) {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Der Limitwunsch in EUR [0-9] muss angegeben werden!  ", "", MessageSeverity.error);
          }
          else if (this.accountCreation.controls.NDKILIMITWUN.invalid){
            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Bitte geben Sie die ganze EURO-Beträge ohne Tausendertrennzeichen aus!", "", MessageSeverity.error);
          }
          else if (value.CDKINAME1 === null || value.CDKINAME1 === '' || this.accountCreation.controls.CDKINAME1.invalid) {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Der Unternehmensname 1 muss angegeben werden!", "", MessageSeverity.error);

          }
          else if (value.CDKISTR === null || value.CDKISTR === '' || this.accountCreation.controls.CDKISTR.invalid) {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Die Straße muss angegeben werden!", "", MessageSeverity.error);

          }
          else if (value.houseNumber === null || value.houseNumber === '' || this.accountCreation.controls.houseNumber.invalid) {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Die Hausnummer muss angegeben werden!", "", MessageSeverity.error);

          }
          else if (value.CDKIPLZ === null || value.CDKIPLZ === '' || this.accountCreation.controls.CDKIPLZ.invalid) {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Die Postleitzahl muss angegeben werden!", "", MessageSeverity.error);

          }
          else if (value.CDKIORT === null || value.CDKIORT === '' || this.accountCreation.controls.CDKIORT.invalid) {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Der Ort muss angegeben werden!", "", MessageSeverity.error);

          }
          else if (!isNumeric(value.NSRFKEY) || value.NSRFKEY === 0 || this.accountCreation.controls.NSRFKEY.invalid) {

            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Die Rechtsform muss angegeben werden!", "", MessageSeverity.error);

          }
          else if (!isNumeric(value.NDKIZIEL3) || this.accountCreation.controls.NDKIZIEL3.invalid) {
            this.navigateToTopIfMobileDevice();
            this.alertService.showMessage("Das Nettoziel in Tagen [0-9] muss angegeben werden!", "", MessageSeverity.error);
          }
          else {

            value.NMNDID = this.user.nmndid;


            this.user.nfkdid = JSON.parse(localStorage.getItem("fkdidL"));

            value.NFKDID = this.user.nfkdid;

            value.NDKIDEBITNR = Number(this.NDKTDEBITNR);
            value.CDKISTR = value.CDKISTR + " " + value.houseNumber;
            value.NDKIVORGANG = 1;
            this.ndkisk1 = String(value.NDKISKONTO1).replace(/,/g, '.');
            this.ndkisk2 = String(value.NDKISKONTO2).replace(/,/g, '.');
            this.ndkisk1 = Number(this.ndkisk1);
            this.ndkisk2 = Number(this.ndkisk2);
            value.NDKISKONTO1 = this.ndkisk1;
            value.NDKISKONTO2 = this.ndkisk2;
            value.DDKIAUFDAT = new Date();
            console.debug(value.DDKIAUFDAT);
            value.DDKIGEBURT = this.accountCreation.get('DDKIGEBURT').value;
            console.debug(value.DDKIGEBURT);

            this.limitService.postAccountCreationData(value)
              .subscribe(res => {

                this.httpCode = res;

                if (this.httpCode === 500) {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);

                } else {

                  this.accountCreationDataRows.push(res);
                  this.navigateToTopIfMobileDevice();
                  //this.alertService.showMessage("Die Kontoeinrichtung wurde erfolgreich erstellt und wird von den zuständigen Debitormanagern zeitnah geprüft.", "", MessageSeverity.success);
                  setTimeout(() => {
                    this.router.navigateByUrl('/account-creation-success');
                  }, 500);
                }

              });
          }



        }



      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }


  //------------------------------------------------------------------------------------------------------------------------------- Account Creation Submit END

  //------------------------------------------------------------------------------------------------------------------------------- eCrefo Submit BEGIN

  onEcrefoSubmit({ value }: { value: DKI }) {
    try {
      if (this.authService.isSessionExpired === false && this.authService.isLoggedIn === true) {

        if ((!isNumeric(value.NDKILIMITWUN))) {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Der Limitwunsch in EUR [0-9] muss angegeben werden!", "", MessageSeverity.error);

        }
        else if (value.CDKINAME1 === null || value.CDKINAME1 === '') {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Der Unternehmensname 1 muss angegeben werden!", "", MessageSeverity.error);

        }
        else if (value.CDKISTR === null || value.CDKISTR === '') {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Die Straße muss angegeben werden!", "", MessageSeverity.error);

        }
        else if (value.houseNumber === null || value.houseNumber === '') {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Die Hausnummer muss angegeben werden!", "", MessageSeverity.error);

        }
        else if (value.CDKIPLZ === null || value.CDKIPLZ === '') {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Die Postleitzahl muss angegeben werden!", "", MessageSeverity.error);

        }
        else if (value.CDKIORT === null || value.CDKIORT === '') {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Der Ort muss angegeben werden!", "", MessageSeverity.error);

        }
        else if (!isNumeric(value.NDKIZIEL1)) {

          this.navigateToTopIfMobileDevice();
          this.alertService.showMessage("Das Nettoziel in Tagen [0-9] muss angegeben werden!", "", MessageSeverity.error);

        } else {

          this.eCrefoService.getECrefoDecision(value.CDKINAME1, value.CDKISTR, value.houseNumber, value.CDKIPLZ, value.CDKIORT, value.CLNDKZ)
            .subscribe(res => {

              this.httpCode = res;

              console.log('eCrefo++++++++', this.httpCode);

              if (this.httpCode === 503) {

                this.alertService.showMessage("Service zurzeit nicht verfügbar!", "", MessageSeverity.warn);

              } else if (this.httpCode === 204) {

                this.alertService.showMessage("Inhalt konnte nicht verarbeitet werden!", "", MessageSeverity.error);

              } else {

                console.log("-------------------------------------");
                console.log("eCrefo:");
                console.log(
                  'Limitwunsch:' + ' ' + value.NDKILIMITWUN + "\n" +
                  'Crefonnummer: ' + value.CrefoNumber + "\n" +
                  'Name 1: ' + value.CDKINAME1 + "\n" +
                  'Name 2: ' + value.CDKINAME2 + "\n" +
                  'Straße: ' + value.CDKISTR + " " + value.houseNumber + "\n" +
                  'Plz: ' + value.CDKIPLZ + "\n" +
                  'Ort: ' + value.CDKIORT + "\n" +
                  'Land: ' + value.CLNDKZ + "\n" +
                  'zus&auml;tzl. Adressfeld 1 (max. 40 Z.): ' + value.CDKIAUSLAND1 + "\n" +
                  'zus&auml;tzl. Adressfeld 1 (max. 40 Z.): ' + value.CDKIAUSLAND2 + "\n" +
                  'Telefon-Nr.: ' + value.CDKITELEFON + "\n" +
                  'Fax-Nr.: ' + value.CDKIFAX + "\n" +
                  'E-Mail: ' + value.CDKIEMAIL + "\n" +
                  'Geschlecht: ' + value.NDKIGESCHLECHT + "\n" +
                  'USt-Ident-Nr.: ' + value.CDKIUSTIDNR + "\n" +
                  'Geburtsdatum: ' + value.DDKIGEBURT + "\n" +


                  'USt. Schl&uuml;ssel: ' + value.NSMWKEY + "\n" +
                  'Rechtsform: ' + value.NSRFKEY + "\n" +


                  'Mitglied-/Filial-/Fanchisenr.: ' + value.CDKIFREMDNR + "\n" +
                  'Verband-Nr: ' + value.CVRBNR + "\n" +
                 
                  'Nettoziel in Tagen: ' + value.CDKIBANK1 + "\n" +
                  'Skonto 1 = x% bei xTg: ' + value.NDKISKONTO1 + "\n" +
                  '% bei: ' + value.NDKIZIEL1 + "\n" +
                  'Skonto 1 = x% bei xTg: ' + value.NDKISKONTO2 + "\n" +
                  '% bei: ' + value.NDKIZIEL2 + "\n" +
                  'Name und Ort der Hausbank: ' + value.CDKIBKTONR1 + "\n" +
                  'BIC: ' + value.CDKIBIC1 + "\n" +
                  'IBAN: ' + value.CDKIIBAN1 + "\n" +
                  'Name: ' + value.CDKINAMEA + "\n" +
                  'Telefon-Nr.: ' + value.CDKITELEFONA + "\n" +
                  'Fax-Nr.: ' + value.CDKIFAXA + "\n" +
                  'E-Mail: ' + value.CDKIEMAILA
                );

                if (this.httpCode === "RIJM-30") {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("Limitanfrage für das Unternehmen " + value.CDKINAME1 + " abgelehnt!", "", MessageSeverity.error);

                } else if (this.httpCode === "ER-35") {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage(this.httpCode, "", MessageSeverity.warn);

                } else if (this.httpCode === "ER-50") {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("Kein Treffer für das Unternehmen " + value.CDKINAME1 + ".", "", MessageSeverity.info);

                } else if (this.httpCode === "ER-23") {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("Die Minimallänge für den Namen 1 ist unterschritten!", "", MessageSeverity.warn);

                } else if (this.httpCode === "ER-60") {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("Numerische Eingabe für die Postleitzahl (PLZ) erforderlich!", "", MessageSeverity.warn);

                } else if (this.httpCode === "ER-21") {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage("Eine Überprüfung in diesem Land ist momentan nicht möglich!", "", MessageSeverity.warn);

                  // error code 666 from ecrefo webservice response if validation error!
                } else if (this.httpCode.indexOf("666") !== null && this.httpCode.indexOf("666") !== -1) {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage(this.httpCode, "", MessageSeverity.warn);

                  // error code 500 from ecrefo webservice response if technical error!
                } else if (this.httpCode.indexOf("500") !== null && this.httpCode.indexOf("500") !== -1) {

                  this.navigateToTopIfMobileDevice();
                  this.alertService.showMessage(this.httpCode, "", MessageSeverity.warn);

                } else if (this.httpCode.indexOf("001") !== null && this.httpCode.indexOf("001") !== -1) {

                  this.alertService.showMessage(this.httpCode, "", MessageSeverity.default);

                } else if (this.httpCode.indexOf("002") !== null && this.httpCode.indexOf("002") !== -1) {

                  this.alertService.showMessage(this.httpCode, "", MessageSeverity.default);

                } else if (this.httpCode.indexOf("003") !== null && this.httpCode.indexOf("003") !== -1) {

                  this.alertService.showMessage(this.httpCode, "", MessageSeverity.default);

                } else if (this.httpCode === "RIJM-10") {

                  console.log("-------------------------------------");
                  console.log("Account Creation after eCrefo:");
                  console.log(
                    'Limitwunsch:' + ' ' + value.NDKILIMITWUN + "\n" +
                    'Crefonnummer: ' + value.CrefoNumber + "\n" +
                    'Name 1: ' + value.CDKINAME1 + "\n" +
                    'Name 2: ' + value.CDKINAME2 + "\n" +
                    'Straße: ' + value.CDKISTR + " " + value.houseNumber + "\n" +
                    'Plz: ' + value.CDKIPLZ + "\n" +
                    'Ort: ' + value.CDKIORT + "\n" +
                    'Land: ' + value.CLNDKZ + "\n" +
                    'zus&auml;tzl. Adressfeld 1 (max. 40 Z.): ' + value.CDKIAUSLAND1 + "\n" +
                    'zus&auml;tzl. Adressfeld 1 (max. 40 Z.): ' + value.CDKIAUSLAND2 + "\n" +
                    'Telefon-Nr.: ' + value.CDKITELEFON + "\n" +
                    'Fax-Nr.: ' + value.CDKIFAX + "\n" +
                    'E-Mail: ' + value.CDKIEMAIL + "\n" +
                    'Geschlecht: ' + value.NDKIGESCHLECHT + "\n" +
                    'USt-Ident-Nr.: ' + value.CDKIUSTIDNR + "\n" +
                    'Geburtsdatum: ' + value.DDKIGEBURT + "\n" +


                    'USt. Schl&uuml;ssel: ' + value.NSMWKEY + "\n" +
                    'Rechtsform-Nr: ' + value.NSRFKEY + "\n" +

                    'Mitglied-/Filial-/Fanchisenr.: ' + value.CDKIFREMDNR + "\n" +
                    'Verband-Nr: ' + value.CVRBNR + "\n" +
                    'Nettoziel in Tagen: ' + value.CDKIBANK1 + "\n" +
                    'Skonto 1 = x% bei xTg: ' + value.NDKISKONTO1 + "\n" +
                    '% bei: ' + value.NDKIZIEL1 + "\n" +
                    'Skonto 1 = x% bei xTg: ' + value.NDKISKONTO2 + "\n" +
                    '% bei: ' + value.NDKIZIEL2 + "\n" +
                    'Name und Ort der Hausbank: ' + value.CDKIBKTONR1 + "\n" +
                    'BIC: ' + value.CDKIBIC1 + "\n" +
                    'IBAN: ' + value.CDKIIBAN1 + "\n" +
                    'Name: ' + value.CDKINAMEA + "\n" +
                    'Telefon-Nr.: ' + value.CDKITELEFONA + "\n" +
                    'Fax-Nr.: ' + value.CDKIFAXA + "\n" +
                    'E-Mail: ' + value.CDKIEMAILA
                  );



                  //------------------------------
                  value.NMNDID = this.user.nmndid;
                  value.NFKDID = this.nfkdkdnr;
                  value.NDKIDEBITNR = Number(this.NDKTDEBITNR);
                  value.CDKISTR = value.CDKISTR + " " + value.houseNumber;
                  value.CLNDKZ = this.CLNDKZ;
                  value.CVRBNR = this.CVRBNR;
                  value.NSRFKEY = this.NSRFKEY;
                  value.NDKIGESCHLECHT = this.NDKIGESCHLECHT;

               

                  this.limitService.postAccountCreationData(value)
                    .subscribe(res => {

                      console.log(res);

                      this.httpCode = res;

                      if (this.httpCode === 500 || this.httpCode === null) {

                        this.navigateToTopIfMobileDevice();
                        this.alertService.showMessage("SQL Serverfehler!", "", MessageSeverity.warn);

                      } else {

                        this.navigateToTopIfMobileDevice();
                        this.alertService.showMessage("Limitanfrage für das Unternehmen " + value.CDKINAME1 + " genehmigt.", "", MessageSeverity.success);
                      }
                    });
                  //------------------------------
                }
              }
            });
        }
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    } catch (error) {
      console.log(error);
    }
  }


  //------------------------------------------------------------------------------------------------------------------------------- eCrefo Submit END

  //Load user data
  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewSmartSignUp) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }

    if (this.canViewECrefo && this.mitgliedsNummerVorhanden) {
      this.accountService.getUserAndRoles().subscribe(results => this.onCurrentUserDataLoadSuccessful(results[0], results[1]), error => this.onCurrentUserDataLoadFailed(error));
    }
    else {
      this.accountService.getUser().subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x))), error => this.onCurrentUserDataLoadFailed(error));
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    //this.allRoles = roles;

    this.user.nfkdkdnr = this.nfkdkdnr;

  }

  private onCurrentUserDataLoadFailed(error) {
    this.alertService.stopLoadingMessage();
    //this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,MessageSeverity.error, error);
    console.log("Load Error: Unable to retrieve user data from the server.\r\nErrors: " + error);
    this.user = new User();
  }

  get canViewSmartSignUp() {
    return this.accountService.userHasPermission(Permission.viewSmartSignUpPermission);
  }

  get canViewECrefo() {
    if (this.mitgliedsNummerVorhanden) {
       return this.accountService.userHasPermission(Permission.viewECrefoPermission);
    }
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  private navigateToTopIfMobileDevice() {
    // if mobile device navigate automatically to top of page
    if (this.isMobile === true) {
      document.getElementById('top').scrollIntoView();
    }
  }

}
